import axios from "axios";
import { API_URL } from "../Constants.js";
import { CONSULTANT } from "../Constants";
import authHeader from "./AuthHeaders.js";

class ConsultantDataService {
  retrieveAllConsultants() {
    return axios.get(`${API_URL}/consultant/all`, { headers: authHeader() });
  }

  retrievePagedConsultants(page) {
    return axios.get(`${API_URL}/consultant/pagedConsultant/${page}`, { headers: authHeader() });
  }

  retrievePagedConsultantsByCategory(categoryId, page) {
    return axios.get(
      `${API_URL}/consultant/pagedConsultantByCategory/${categoryId}/${page}`,
      { headers: authHeader() }
    );
  }

  retrievePagedConsultantsByName(name, page) {
    return axios.get(
      `${API_URL}/consultant/pagedConsultantByName/${name}/${page}`,
      { headers: authHeader() }
    );
  }

  retrievePagedConsultantsBySlotSize(slotSize, page) {
    return axios.get(
      `${API_URL}/consultant/pagedConsultantBySlotSize/${slotSize}/${page}`,
      { headers: authHeader() }
    );
  }

  retrievePagedConsultantsBySlotRate(slotRate, page) {
    return axios.get(
      `${API_URL}/consultant/pagedConsultantBySlotRate/${slotRate}/${page}`,
      { headers: authHeader() }
    );
  }

  retrieveConsultant(id) {
    return axios.get(`${API_URL}/consultant/${id}`, { headers: authHeader() });
  }

  retrieveConsultantByUser(id) {
    return axios.get(`${API_URL}/consultant/user/${id}`, { headers: authHeader() });
  }

  deleteConsultant(id) {
    return axios.delete(`${API_URL}/consultant/${id}`, { headers: authHeader() });
  }

  updateConsultant(id, Consultant) {
    return axios.put(`${API_URL}/consultant/${id}`, Consultant, {
      headers: authHeader()
    });
  }

  createConsultant(consultant) {
    return axios.post(`${API_URL}/consultant/`, consultant, {
      headers: authHeader()
    });
  }

  getConsultant(id) {
    return axios.get(`${API_URL}/consultant/${id}`, { headers: authHeader() });
  }

  getConsultantIdFromStorage() {
    return localStorage.getItem(CONSULTANT.toLowerCase());
  }

  saveIdToStorage(id) {
    localStorage.setItem(CONSULTANT.toLowerCase(), id);
  }

  getFilteredConsultants(category, slotSize, slotRangeMin, slotRangeMax, consultantName, pageInfo) {
    return axios.get(`${API_URL}/consultant`, {
      params: {
        consultantName: consultantName,
        category: category,
        ss: slotSize,
        srmin: slotRangeMin,
        srmax: slotRangeMax,
        page: pageInfo.page,
        rows: pageInfo.rows
      },
      headers: authHeader()
    });
  }

  getConsultatSlots(id) {
    console.log(id);
    return axios.get(`${API_URL}/consultant/${id}/slots`, {
      headers: authHeader()
    });
  }

  getConsultantEarningsBy(consultantId, startDate, endDate) {
    return axios.get(`${API_URL}/consultant/earnings`, {
      params: {
        consultantId: consultantId,
        startDate: startDate,
        endDate: endDate
      },
      headers: authHeader()
    });
  }

  getPagedConsultantList(data) {
    return axios.post(`${API_URL}/consultant/getPagedConsultantList`, data, { headers: authHeader() })
  }

  getPagedConsultantListPublic(data) {
    return axios.post(`${API_URL}/consultant/getPagedConsultantListPublic`, data, { headers: authHeader() })
  }

  updateConsultantStatus(data, id) {
    return axios.put(`${API_URL}/consultant/updateStatus/${id}`, data, { headers: authHeader() })
  }

}

export default new ConsultantDataService();
