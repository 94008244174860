import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import CerebroSlotDataService from "../api/CerebroSlotDataService";
import ConsultantDataService from "../api/ConsultantDataService";
import { timeslots, curentDateForPicker, Constants } from "../components/Common";
import { Tabs, Tab, Modal, Button, Form } from "react-bootstrap";
import BookingDataService from "../api/BookingDataService";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { SET_CONSULTANT } from "../redux/actionTypes";
import UserDataService from "../api/UserDataService";
import { withAlert } from "react-alert";
import TimeSlots from "./consultant-dashbord/TimeSlots";
import UpcomingAppointments from "./consultant-dashbord/UpcomingAppointments";
import MyEarnings from "./consultant-dashbord/MyEarnings";
import ReactStars from "react-rating-stars-component";
import PreviousAppointments from "./consultant-dashbord/PreviousAppointments";

class ConsultantDashboard extends Component {
  constructor() {
    super();
    this.state = {
      addAvailableDate: new Date().toDateString(),
      slotSize: 0,
      cerebroSlotId: "",
      value: new Date(),
      events: this.logs,
      noOfSlots: "",
      repeat: "",
      cerebroSlots: [],
      consultantId: "6184f261-10d6-40ff-9422-02967a7254a7",
      noOfOccurrence: "",
      time: "",
      show: false,
      errors: {},
      date: curentDateForPicker(),
      activePage: 1,
      totalItemsCount: 0,
      itemsCountPerPage: 3,
      consultant: {
        id: "",
        firstName: "",
        lastName: "",
        age: 0,
        gender: "",
        country: "",
        timeZone: "",
        currency: "",
        categoryId: "",
        categoryName: "",
        experience: 5,
        about: "",
        email: "",
        phone: "",
        bank: "",
        bankBranch: "",
        accNumber: "",
        slotSize: 0,
        slotRate: 0,
        userId: null,
      },
      availabilityModal: {
        date: curentDateForPicker(),
        time: "08:00",
        noOfSlots: 1,
        noOfOccurrence: 1,
        repeat: 0,
      },
      upcoming: [],
      previous: [],
      myEarnings: {
        start: curentDateForPicker(),
        end: curentDateForPicker(),
        response: {
          bookings: 0,
          earned: 0,
          consultantShare: 0,
          cerebrozoneShare: 0,
          earnings: [],
        },
      },
      rate: null,
      showRateModal: false,
      ratingCount: -1,
      ratingComment: "",
    };

    this.save = this.save.bind(this);
    this.validate = this.validate.bind(this);
    // this.update = this.update.bind(this);
    this.loadCerebroslot = this.loadCerebroslot.bind(this);
  }
  handleChangeTime = (event) => {
    this.setState({ value: event.target.value });
  };
  handleChange = (date) => {
    this.setState({ addAvailableDate: date });
  };
  componentDidMount() {
    this.getConsultant();
    this.loadCerebroslot(0);
    this.getUpcomingBookings();
    this.getPreviousBookings();
    this.searchEarnigs();
    this.ratingPrompt();
  }

  setInitialValuesForAvailabilityModal = () => {
    this.setState({
      availabilityModal: {
        date: curentDateForPicker(),
        time: "08:00",
        noOfSlots: 1,
        noOfOccurrence: 1,
        repeat: 0,
      },
    });
  };

  getConsultant() {
    if (this.props.consultantId) {
      ConsultantDataService.getConsultant(this.props.consultantId)
        .then((res) => {
          console.log(res);
          if (res && res.status === 200 && res.data !== "") {
            this.setState({
              consultant: res.data,
            });
            this.props.dispatch({ type: SET_CONSULTANT, payload: res.data });
            UserDataService.registerSuccessfulLogin(this.props.user);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  getUpcomingBookings() {
    if (this.props.consultantId) {
      BookingDataService.getBookingsByConsultant(this.props.consultantId)
        .then((res) => {
          if (res && res.status === 200 && res.data !== "") {
            this.setState({
              upcoming: res.data,
            });
          }
        })
        .catch((e) => { });
    }
  }

  getPreviousBookings() {
    if (this.props.consultantId) {
      BookingDataService.getPreviousBookingsByConsultant(
        this.props.consultantId
      )
        .then((res) => {
          if (res && res.status === 200 && res.data !== "") {
            this.setState({
              previous: res.data,
            });
          }
        })
        .catch((e) => { });
    }
  }

  save() {
    if (
      this.state.consultant !== null &&
      this.state.availabilityModal !== null
    ) {
      let cerebroSlot = {
        consultantId: this.state.consultant.id,
        noOfOccurrence: this.state.availabilityModal.noOfOccurrence,
        noOfSlots: this.state.availabilityModal.noOfSlots,
        repeat: this.state.availabilityModal.repeat,
        slotSize: this.state.consultant.slotSize,
        startTime: this.state.availabilityModal.time + ":00",
        startDate: this.state.availabilityModal.date,
      };
      if (this.state.cerebroSlotId === "") {
        CerebroSlotDataService.createCerebroSlot(cerebroSlot).then((res) => {
          if (
            res.status === 200 &&
            (res.data !== null || res.data !== undefined)
          ) {
            let keys = Object.keys(res.data);
            this.props.alert.info(keys[0]);
          }
          this.setInitialValuesForAvailabilityModal();
          this.loadCerebroslot(0);
        });
      } else {
        CerebroSlotDataService.updateCerebroSlot(
          this.state.cerebroSlotId,
          cerebroSlot
        ).then(
          (r) => this.validate(),
          this.setState({ show: true, cerebroSlotId: "" })
        );
      }
    } else {
      console.log("else");
    }
  }

  delete(id) {
    CerebroSlotDataService.deleteCerebroSlot(id)
      .then((res) => {
        this.loadCerebroslot(0);
      })
      .catch((e) => { });
  }

  validate() {
    let oldErrors = {};
    this.setState({ errors: oldErrors });
    let errors = {};
    if (!this.state.noOfOccurrence) {
      errors.noOfOccurrence = "Cannot leave no of occurrence empty ";
    }
    if (!this.state.noOfSlots) {
      errors.noOfSlots = "Cannot leave no of slots empty";
    }
    if (!this.state.repeat) {
      errors.repeat = "Cannot leave repeat empty";
    }
    if (!this.state.slotSize) {
      errors.slotSize = "Cannot leave slot size empty";
    }
    if (!this.state.time) {
      errors.time = "Cannot leave time empty";
    }
    if (!this.state.date) {
      errors.date = "Cannot leave date empty";
    }
    this.setState({ errors: errors });
  }
  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    this.loadCerebroslot(pageNumber);
  }

  loadCerebroslot(page) {
    if (this.props.consultantId) {
      ConsultantDataService.getConsultatSlots(this.props.consultantId)
        .then((res) => {
          console.log(res.data);
          this.setState({
            cerebroSlots: res.data,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  onChangeSlotRepeatValue(value) {
    this.setState({
      availabilityModal: {
        ...this.state.availabilityModal,
        repeat: value,
        noOfOccurrence: 1,
      },
    });
  }

  takeVideoCall(id) {
    BookingDataService.isBookingLive(id)
      .then((res) => {
        if (res !== null && res.status === 200 && res.data) {
          this.props.history.push(`/video/${id}`);
        } else {
          this.props.alert.error("This meeting is expired.");
        }
      })
      .catch((e) => { });
  }

  searchEarnigs = () => {
    ConsultantDataService.getConsultantEarningsBy(
      this.props.consultantId,
      this.state.myEarnings.start,
      this.state.myEarnings.end
    )
      .then((res) => {
        if (res && res.status === 200 && res.data !== "") {
          this.setState({
            myEarnings: {
              ...this.state.myEarnings,
              response: res.data,
            },
          });
        }
      })
      .catch((e) => { });
  };

  ratingPrompt = () => {
    BookingDataService.getLatestConsultantRatingDetails(this.props.consultantId)
      .then((res) => {
        if (res.status === 200 && res.data != null) {
          this.setState({
            rate: res.data,
            showRateModal: true,
          });
        }
      })
      .catch((e) => { });
  };

  ratingPromptForBooking(id) {
    BookingDataService.getRatingDetailsById(id)
      .then((res) => {
        if (res.status === 200 && res.data != null) {
          this.setState({
            rate: res.data,
            showRateModal: true,
          });
        }
      })
      .catch((e) => { });
  }

  closeModal = () => {
    const rate = {
      bookingRateId: this.state.rate.id,
      rate: this.state.ratingCount,
      comment: this.state.ratingComment,
    };
    BookingDataService.updateLatestConsultantRatingDetails(rate)
      .then((res) => {
        this.setState({
          showRateModal: false,
        });
      })
      .catch((e) => {
        this.setState({
          showRateModal: false,
        });
      });
  };

  rateConsultee = () => {
    const rate = {
      bookingRateId: this.state.rate.id,
      rate: this.state.ratingCount,
      comment: this.state.ratingComment,
    };
    BookingDataService.updateLatestConsultantRatingDetails(rate)
      .then((res) => {
        this.setState(
          {
            showRateModal: false,
            previous: [],
          },
          () => {
            this.getPreviousBookings();
          }
        );
      })
      .catch((e) => {
        this.setState(
          {
            showRateModal: false,
            previous: [],
          },
          () => {
            this.getPreviousBookings();
          }
        );
      });
  };

  ratingChanged = (newRating) => {
    console.log(newRating);
    this.setState({
      ratingCount: newRating,
    });
  };

  onChnageTextArea = (event) => {
    this.setState({
      ratingComment: event.target.value,
    });
  };

  onSelectTab(key) {
    console.log(key);
  }

  render() {

    const isVerified = this.state.consultant.status && this.state.consultant.status === Constants.CONSULTANT_STATUS_CONST.VERIFIED;

    return (
      <div>
        {
          isVerified &&
          <section className="section-container ml-0">
            <div className="content-wrapper">
              <div className="content-heading">
                <div>
                  Consultant Dashboard
                  <small data-localize="dashboard.WELCOME" />
                </div>
              </div>

              <Tabs
                onSelect={(selected) => this.onSelectTab(selected)}
                defaultActiveKey="upcoming-appointments"
                transition={false}
                id="noanim-tab-example"
              >
                {" "}
                <Tab
                  eventKey="upcoming-appointments"
                  title="Upcoming Appointments"
                  style={{ textColor: "green" }}
                >
                  <UpcomingAppointments
                    upcoming={this.state.upcoming}
                    call={(id) => this.takeVideoCall(id)}
                    onComplete={() => this.getUpcomingBookings()}
                  />
                </Tab>
                <Tab
                  eventKey="previous-appointments"
                  title="Previous Appointments"
                  style={{ textColor: "green" }}
                  onClick={() => {
                    console.log("Previous Appointments");
                  }}
                >
                  <PreviousAppointments
                    previous={this.state.previous}
                    ratingPromptForBooking={(id) =>
                      this.ratingPromptForBooking(id)
                    }
                  />
                </Tab>
                {/*current-slots*/}
                <Tab eventKey="current-slots" title="Time Slots">
                  <TimeSlots
                    currentSlots={this.state.cerebroSlots.filter((slot) => {
                      return !slot.isExpired;
                    })}
                    expiredSlots={this.state.cerebroSlots.filter((slot) => {
                      return slot.isExpired;
                    })}
                    onDelete={(id) => {
                      this.delete(id);
                    }}
                  />
                </Tab>
                <Tab eventKey="my-earnings" title="My Earnings">
                  <MyEarnings
                    {...this.state.myEarnings}
                    startChanged={(date) =>
                      this.setState({
                        myEarnings: {
                          ...this.state.myEarnings,
                          start: date,
                        },
                      })
                    }
                    endChanged={(date) =>
                      this.setState({
                        myEarnings: {
                          ...this.state.myEarnings,
                          end: date,
                        },
                      })
                    }
                    searchEarnigs={this.searchEarnigs}
                  />
                </Tab>
              </Tabs>
            </div>
          </section>
        }
        {
          !isVerified &&
          <div className="alert alert-warning mt-3"><h4>Your profile is in review process.</h4></div>
        }
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <div className="list-group mb-3">
                  <table className="wd-wide" style={{ emptyCells: "show" }}>
                    <tbody>
                      <tr style={{ marginBottom: "10px" }}>
                        <td>Slot Rate:</td>
                        <td style={{ textAlign: "center" }}>
                          {" "}
                          {this.state.consultant.slotRate}
                          {this.state.consultant.currency} per{" "}
                          {this.state.consultant.slotSize}min{" "}
                        </td>
                      </tr>
                      <tr style={{ height: "15px" }} />
                      <tr style={{ marginBottom: "10px" }}>
                        <td>Date</td>
                        <td>
                          {" "}
                          <input
                            className="form-control form-control-rounded"
                            placeholder={"Enter Your No of slots"}
                            value={this.state.availabilityModal.date}
                            onChange={(e) => {
                              this.setState({
                                availabilityModal: {
                                  ...this.state.availabilityModal,
                                  date: e.target.value,
                                },
                              });
                            }}
                            type="date"
                            autoComplete="off"
                            required
                            style={{ textAlign: "center", width: "83%" }}
                          />{" "}
                          <p style={{ color: "#FF0000" }}>
                            {" "}
                            {this.state.errors.date}
                          </p>
                        </td>
                        <td>Time</td>
                        <td>
                          <select
                            className="form-control form-control-rounded"
                            onChange={(e) => {
                              console.log(e.target.value);
                              this.setState({
                                availabilityModal: {
                                  ...this.state.availabilityModal,
                                  time: e.target.value,
                                },
                              });
                            }}
                            value={this.state.availabilityModal.time}
                            style={{ width: "83%" }}
                          >
                            {timeslots.map((item) => {
                              return (
                                <option key={item.value} value={item.value}>
                                  {item.value}
                                </option>
                              );
                            })}
                          </select>
                          <p style={{ color: "#FF0000" }}>
                            {" "}
                            {this.state.errors.repeat}
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "15px" }} />
                      <tr>
                        <td>No of Slots</td>
                        <td>
                          <input
                            className="form-control form-control-rounded"
                            placeholder={"Enter Your No of slots"}
                            value={this.state.availabilityModal.noOfSlots}
                            onChange={(e) => {
                              if (e.target.value > 0) {
                                this.setState({
                                  availabilityModal: {
                                    ...this.state.availabilityModal,
                                    noOfSlots: e.target.value,
                                  },
                                });
                              }
                            }}
                            type="number"
                            max="10"
                            autoComplete="off"
                            required
                            style={{ textAlign: "center", width: "83%" }}
                          />{" "}
                          <p style={{ color: "#FF0000" }}>
                            {" "}
                            {this.state.errors.noOfSlots}
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "15px" }} />
                      <tr>
                        <td>Repeat</td>
                        <td>
                          <select
                            className="form-control form-control-rounded"
                            onChange={(e) => {
                              this.onChangeSlotRepeatValue(e.target.value);
                            }}
                            value={this.state.availabilityModal.repeat}
                            style={{ width: "83%" }}
                          >
                            <option value={0}>Once</option>
                            <option value={1}>Daily</option>
                            <option value={2}>Weekly</option>
                          </select>
                          <p style={{ color: "#FF0000" }}>
                            {" "}
                            {this.state.errors.repeat}
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "15px" }} />
                      <tr>
                        <td>No of Occurrence</td>
                        <td>
                          <input
                            disabled={this.state.availabilityModal.repeat === 0}
                            className="form-control form-control-rounded"
                            placeholder={"Enter Your No of slots"}
                            value={this.state.availabilityModal.noOfOccurrence}
                            onChange={(e) => {
                              if (e.target.value > 0) {
                                this.setState({
                                  availabilityModal: {
                                    ...this.state.availabilityModal,
                                    noOfOccurrence: e.target.value,
                                  },
                                });
                              }
                            }}
                            type="number"
                            autoComplete="off"
                            required
                            style={{ textAlign: "center", width: "83%" }}
                            max="30"
                            min="0"
                            size="2"
                            maxLength="2"
                          />{" "}
                          <p style={{ color: "#FF0000" }}>
                            {" "}
                            {this.state.errors.noOfOccurrence}
                          </p>
                        </td>
                        <td>Max 30</td>
                      </tr>
                      <tr style={{ height: "15px" }} />
                    </tbody>
                  </table>
                </div>
                {/* <div className=" text-right" style={{ float: "right" }}>
                  <h4 className="font-weight-normal text-warning mb-0"> <small className="text-muted" style={{ float: "left" }}> Project Revenue </small> 45,000.00 LKR </h4>

                  <h4 className="font-weight-normal text-warning mb-0"> <small className="text-muted" style={{ float: "left" }}> Cerebrozone Share </small> 13,000.00 LKR </h4>

                  <h4 className="font-weight-normal text-warning mb-0"> <small className="text-muted" style={{ float: "left" }}> My Share </small> 13,000.00 LKR </h4>

                </div> */}
              </div>
              <div className="modal-footer border-warning">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => this.setInitialValuesForAvailabilityModal()}
                >
                  {" "}
                  Close{" "}
                </button>{" "}
                <button
                  type="button"
                  className="btn btn-warning"
                  data-dismiss="modal"
                  onClick={() => this.save()}
                >
                  {" "}
                  Save{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.showRateModal}>
          <Modal.Header>
            <Modal.Title>Rate Consultee</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.rate != null ? (
              <div className="row align-items-center">
                <div className="col-md-4 text-center">
                  <img
                    style={{
                      objectFit: "cover",
                      maxWidth: "100px",
                      maxHeight: "100px",
                    }}
                    className="img-thumbnail circle img-fluid thumb128"
                    src={
                      this.state.rate.consulteeImageURL
                        ? this.state.rate.consulteeImageURL
                        : "app/img/user/avatar.svg"
                    }
                    alt="d"
                  />
                </div>
                <div className="col-md-6">
                  <div className="d-flex">
                    <div className="text-left">
                      <h3 className="mt-0">{this.state.rate.consulteeName}</h3>
                      <p class="lead mb-2">{this.state.rate.slotDate}</p>
                      <p class="mb-2">{`${this.state.rate.slotStartTime} - ${this.state.rate.slotEndTime}`}</p>
                      <ReactStars
                        count={5}
                        onChange={this.ratingChanged}
                        size={24}
                        activeColor="#ffd700"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <Form>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Comment</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  onChange={this.onChnageTextArea}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={this.rateConsultee}
              className="btn btn-warning btn-md text-bold fa-2x"
            >
              Rate
            </Button>
            <Button
              onClick={this.closeModal}
              className="btn btn-white btn-md text-bold fa-2x"
            >
              Skip
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    consultantId: state.auth.consultantId,
    user: state.auth.user,
  };
};
export default connect(mapStateToProps)(
  withAlert()(withRouter(ConsultantDashboard))
);
