import { LOGIN_SUCCESS, LOG_OUT, SET_CONSULTANT, SET_CONSULTEE, SET_ADMIN, SIGNUP_SUCCESS, SET_LOGGED_IN_USER_DATA } from "../actionTypes";

const initialState = () => {
  if (    localStorage.getItem("user") === undefined ||    localStorage.getItem("user") === null  ) {
    return {
      loggedIn: false,
      user: null,
      type: null,
      username: null,
      consultantId: null,
      consulteeId: null,
      adminId: null,
    };
  }

  if (    localStorage.getItem("user") === null ||    localStorage.getItem("user") === "undefined"  ) {
    return {
      loggedIn: false,
      user: null,
      type: null,
      username: null,
      consultantId: null,
      consulteeId: null,
      adminId: null,
    };
  } else {    const user = JSON.parse(localStorage.getItem("user"));
    return {
      loggedIn: true,
      user: user,
      type: user.roles[0],
      username: user.username,
      consultantId: user.consultant ? user.consultant.id : null,
      consulteeId: user.consultee ? user.consultee.id : null,
      adminId: user.admin ? user.admin.id : null,
    };
  }
};

export default function (state = initialState(), action) {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      let { roles, username, consultant, consultee ,admin} = action.payload;
      return {
        ...state,
        user: action.payload,
        loggedIn: true,
        type: roles[0],
        username: username,
        consultantId: consultant ? consultant.id : null,
        consulteeId: consultee ? consultee.id : null,
        adminId: admin ? admin.id : null,
      };
    }
    case LOG_OUT:
      return {
        ...state,
        user: null,
        loggedIn: false,
        type: null,
        username: null,
        consultantId: null,
        consulteeId: null,
        adminId:null,
      };
    case SET_CONSULTEE:
      return {
        ...state,
        user: {
          ...state.user,
          consultee: action.payload,
        },
        consulteeId: action.payload.id,
      };
    case SET_CONSULTANT:
      return {
        ...state,
        user: {
          ...state.user,
          consultant: action.payload,
        },
        consultantId: action.payload.id,
      };
    case SET_ADMIN:
      return {
        ...state,
        user: {
          ...state.user,
          admin: action.payload,
        },
        adminId: action.payload.id,
      };
    case SIGNUP_SUCCESS: {
      let { roles, username } = action.payload;
      return {
        ...state,
        user: action.payload,
        loggedIn: true,
        type: roles[0],
        username: username
      };
    }
    case SET_LOGGED_IN_USER_DATA: {
      let { roles } = action.payload;
      return {
        ...state,
        type: roles,
      }
    }
    default:
      return state;
  }
}
