import axios from "axios";
import { API_URL } from "../Constants.js";
import authHeader from "./AuthHeaders.js";

class BookingDataService {
  saveBookings(bookings, paymentMethod) {
    return axios.post(`${API_URL}/booking`, bookings, { headers: authHeader(), params: { paymentMethod: paymentMethod } });
  }

  getAllUpcomingBookingsList() {
    return axios.get(`${API_URL}/booking/upcoming`, { headers: authHeader() })
  }

  getAllPreviousBookingsList() {
    return axios.get(`${API_URL}/booking/previous`, { headers: authHeader() })
  }

  getBookingsByConsultee(id) {
    return axios.get(`${API_URL}/booking/consultee/${id}`, { headers: authHeader() })
  }

  getBookingsByConsultant(id) {
    return axios.get(`${API_URL}/booking/consultant`, {
      params: {
        consultantId: id,
      },
      headers: authHeader()
    })
  }

  getPreviousBookingsByConsultee(id) {
    return axios.get(`${API_URL}/booking/consultee/${id}/previous`, { headers: authHeader() })
  }

  getPreviousBookingsByConsultant(id) {
    return axios.get(`${API_URL}/booking/consultant/${id}/previous`, { headers: authHeader() })
  }

  isBookingLive(id) {
    return axios.get(`${API_URL}/booking/validate/`, {
      params: {
        bookingId: id,
      },
      headers: authHeader()
    })
  }

  getBookingById(id) {
    return axios.get(`${API_URL}/booking/${id}`, {
      headers: authHeader()
    })
  }

  createRate(rate) {
    return axios.post(`${API_URL}/booking/rate`, rate, { headers: authHeader() });
  }

  getLatestConsultantRatingDetails(consultantId) {
    return axios.get(`${API_URL}/booking/consultant/rate/${consultantId}`, {
      headers: authHeader()
    })
  }

  getRatingDetailsById(ratingId) {
    return axios.get(`${API_URL}/booking/rate/${ratingId}`, {
      headers: authHeader()
    })
  }

  updateLatestConsultantRatingDetails(object) {
    return axios.put(`${API_URL}/booking/rate/`, object, { headers: authHeader() });
  }

  getLatestConsulteeRatingDetails(consulteeId) {
    return axios.get(`${API_URL}/booking/consultee/rate/${consulteeId}`, {
      headers: authHeader()
    })
  }

  updateLatestConsulteeRatingDetails(object) {
    return axios.put(`${API_URL}/booking/rate/`, object, { headers: authHeader() });
  }
}

export default new BookingDataService();