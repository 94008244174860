import React from 'react'
import { Button } from 'react-bootstrap';


export const PaymentGateSuccess = (props) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '60vh'
    }}>
      <div>
        <img src={'app/img/payment_success.png'} alt="Payment Gate Success" />
      </div>
      <div style={{ marginTop: '20px' }}>
        <Button onClick={() => { props.history.push("/consulteedashboard"); }} className="btn btn-warning btn-md text-bold fa-2x">Back To Dashboard</Button>
      </div>
    </div>
  )
}
