import React, { Component } from "react";
import { withAlert } from "react-alert";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { Constants } from "./Common";
import ConsultantSearchBase from "./admin-dashboard/consultant/ConsultantSearchBase";

class SearchConsultant extends Component {



  render() {
    return (
      <ConsultantSearchBase currentRoleView={Constants.ROLE_CONST.CONSULTEE} isPublic={true} />
    );
  }
}

export default connect()(withRouter(withAlert()(SearchConsultant)));
