import React, { Component } from "react";
import ReactStars from "react-rating-stars-component";
import { Button } from "react-bootstrap";


class PreviousAppointments extends Component {

  constructor(props) {
    super(props)
    console.log(props)
  }

  render() {
    return (
      <div>
        <section className="section-container ml-0">
          {/* Page content*/}
          <div className="content-wrapper">
            <div className="row">
              <div className="col-12">
                <div className=" card-default">
                  <div className="row">
                    <div className="col-12">
                      {this.props.previous.map(item => {
                        return (
                          <div className="list-group mb-3" key={item.id}>
                            <div className="list-group-item">
                              <table className="wd-wide">
                                <tbody>
                                  <tr>
                                    <td className="wd-sm d-lg-table-cell">
                                      <div className="px-2">
                                        <h4 className="mb-2">
                                          {item.cerebroslotDate}
                                        </h4>
                                        <small className="text-muted"></small>
                                      </div>
                                    </td>
                                    <td className="wd-sm d-lg-table-cell">
                                      <div className="px-2">
                                        <p className="m-0">{item.startTime}</p>
                                        <small className="text-muted">From</small>
                                      </div>
                                    </td>
                                    <td className="wd-sm d-lg-table-cell">
                                      <div className="px-2">
                                        <p className="m-0">{item.endTime}</p>
                                        <small className="text-muted">To</small>
                                      </div>
                                    </td>
                                    <td className="wd-sm d-lg-table-cell">
                                      <div className="px-2">
                                        <p className="m-0">
                                          {item.cerebroslotSize / 60} hour(s)
                                      </p>
                                        <small className="text-muted">
                                          Duration
                                      </small>
                                      </div>
                                    </td>
                                    <td className="wd-sm d-lg-table-cell">
                                      <div className="px-2">
                                        <p className="font-weight-bold pt-3 mb-0">{`${item.consulteeFirstName} ${item.consulteeLastName}`}</p>
                                        <p className="text-muted mb-0"></p>
                                      </div>
                                    </td>
                                    {item.previousBookingRate != null ?
                                      <>
                                        <td className="wd-sm d-lg-table-cell">
                                          <div className="px-2">
                                            <ReactStars
                                              edit={false}
                                              count={5}
                                              value={item.previousBookingRate.rateValue}
                                              size={24}
                                              activeColor="#ffd700"
                                            />
                                          </div>
                                        </td>
                                        <td className="wd-sm d-lg-table-cell">
                                          <div className="px-2">
                                            {!item.previousBookingRate.hasRated ?
                                              <Button onClick={() => this.props.ratingPromptForBooking(item.previousBookingRate.bookingRateId)} className="btn btn-warning btn-md text-bold fa-2x">Rate</Button> : null
                                            }
                                          </div>
                                        </td>
                                      </>
                                      :
                                      <>
                                        <td className="wd-sm d-lg-table-cell">
                                          <div className="px-2">
                                          </div>
                                        </td>
                                        <td className="wd-sm d-lg-table-cell">
                                          <div className="px-2">
                                          </div>
                                        </td>
                                      </>
                                    }
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default PreviousAppointments