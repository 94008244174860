import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import UserDataService from "../api/UserDataService";
import { LOG_OUT } from "../redux/actionTypes";

class Navbar extends Component {
  constructor(props) {
    super();
    this.state = {
      login: true,
      consultant: true,
      consultee: false,
      userName: "Cerebrozone",
      navbarVisibility: "hidden",
      role: "USER",
      isFull: false
    };
  }

  logout = () => {
    this.props.dispatch({ type: LOG_OUT });
    UserDataService.removeUser();
  };

  dashboard = () => {
    const { type } = this.props;

    if (type === "CONSULTEE") {
      this.props.history.push("/consulteedashboard");
    } else if (type === "CONSULTANT") {
      this.props.history.push("/consultantdashboard");
    } else if (type === "ADMIN") {
      this.props.history.push("/admindashboard");
    }
  };

  profile = () => {
    console.log(this.props.type);
    const { type } = this.props;

    if (type === "CONSULTEE") {
      this.props.history.push("/updateconsultee");
    } else if (type === "CONSULTANT") {
      this.props.history.push("/updateconsultant");
    } else if (type === "ADMIN") {
      this.props.history.push("/updateadmin");
    }
  };

  render() {
    return (
      <div>
        <header className="topnavbar-wrapper">
          {/* START Top Navbar*/}
          <nav className="navbar topnavbar navbar-expand-lg navbar-light p-1">
            {/* START navbar header*/}
            <div className="navbar-header">
              <NavLink className="navbar-brand" to="/">
                <div className="brand-logo p-0">
                  {" "}
                  <img
                    className="img-fluid"
                    src="app/img/logo.png"
                    alt="App Logo"
                  />{" "}
                </div>
                <div className="brand-logo-collapsed p-0">
                  {" "}
                  <img
                    className="img-fluid"
                    src="app/img/logo-single.png"
                    alt="App Logo"
                  />{" "}
                </div>
              </NavLink>
            </div>

            {
                !this.props.loggedIn && (
                  <button
                    className="navbar-toggler collapsed float-right"
                    type="button"
                    data-toggle="collapse"
                    data-target="#topnavbar"
                    aria-expanded="false"
                    style={{ verticalAlign: "middle" }}
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                )
              }

            {/* END navbar header*/}
            {/* START Nav wrapper*/}

            {/* Moved loggedIn nav item list to here to avoid collapse in mobile device */}
            {
              this.props.loggedIn && (
                <div className="ml-lg-6 order-lg-last order-0">
                  <ul className="navbar-nav flex-column flex-lg-row ml-auto">
                    <li className="nav-item dropdown dropdown-list" href="#">
                      <a
                        className="nav-link dropdown-toggle dropdown-toggle-nocaret"
                        href="#"
                        data-toggle="dropdown"
                      >
                        <div className="row">
                          <div className="col-4 align-self-right">
                            <img
                              className="img-thumbnail circle img-fluid thumb80 ml-auto"
                              src={this.props.profileImageUrl ? this.props.profileImageUrl : "app/img/user/avatar.svg"}
                              data-src="app/img/user/avatar.svg"
                              alt="d"
                              style={{
                                maxWidth: "40px",
                                maxHeight: "40px",
                                border: "0px",
                                objectFit: 'cover'
                              }}
                            />
                          </div>
                          <div className="col-8 align-self-left">
                            <div className="ms-info">
                              <p className="text-left mb-0">
                                {" "}
                                <em /> {this.props.username} <em />{" "}
                              </p>
                              <div>
                                {" "}
                                <small>{this.props.type}</small>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                      <div className="dropdown-menu dropdown-menu-right animated flipInX">
                        <div className="dropdown-item">
                          <div className="list-group">
                            <NavLink
                              className="list-group-item list-group-item-action"
                              to={
                                this.props.type === "CONSULTEE"
                                  ? "/consulteedashboard"
                                  : "/consultantdashboard"
                              }
                            >
                              <div className="media">
                                <div className="align-self-center mr-2">
                                  <em className="fas fa-chart-line fa-1x text-warning" />
                                </div>
                                <div className="media-body">
                                  <div className="nav-link">
                                    <p className="m-0">Dashboard</p>
                                  </div>
                                </div>
                              </div>
                            </NavLink>
                            <NavLink
                              className="list-group-item list-group-item-action"
                              to={
                                this.props.type === "CONSULTEE"
                                  ? "/updateconsultee"
                                  : "/updateconsultant"
                              }
                            >
                              <div className="media">
                                <div className="align-self-center mr-2">
                                  <em className="fas fa-user fa-1x text-warning" />
                                </div>
                                <div className="media-body">
                                  <div className="nav-link">
                                    <p className="m-0">Profile</p>
                                  </div>
                                </div>
                              </div>
                            </NavLink>
                            <div
                              className="list-group-item list-group-item-action"
                              style={{ cursor: "pointer" }}
                              onClick={this.logout}
                            >
                              <div className="media">
                                <div className="align-self-center mr-2">
                                  <em className="fas fa-sign-out-alt fa-1x text-warning" />
                                </div>
                                <div className="media-body">
                                  <div className="nav-link">
                                    <p className="m-0">Logout</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>)}

            <div className="navbar-collapse collapse" id="topnavbar" style={{}}>
              {/* START Right Navbar*/}
              {this.props.loggedIn ? null : (
                <ul className="navbar-nav flex-column flex-lg-row ml-auto">
                  <li className="nav-item" style={{ visibility: true }}>
                    <NavLink className="nav-link" to="/">
                      {" "}
                      <strong className="font-weight-bold">Home</strong>{" "}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      to="/services"
                      style={{ visibility: true }}
                    >
                      {" "}
                      <strong className="font-weight-bold">
                        Services
                      </strong>{" "}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      to="/aboutus"
                      style={{ visibility: true }}
                    >
                      {" "}
                      <strong className="font-weight-bold">
                        About Us
                      </strong>{" "}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      to="/contactus"
                      style={{ visibility: true }}
                    >
                      {" "}
                      <strong className="font-weight-bold">
                        Contact Us
                      </strong>{" "}
                    </NavLink>
                  </li>
                </ul>
              )}
              {this.props.loggedIn ? (
                //Moved loggedIn nav item list to up to avoid collapse in mobile device
                <></>
              ) : (
                  <ul className="navbar-nav flex-row">
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/login">
                        {" "}
                        <em className="icon-login mr-1" />{" "}
                        <strong className="font-weight-bold">LOGIN</strong>{" "}
                      </NavLink>
                    </li>
                  </ul>
                )}

              {/* <ul className="navbar-nav flex-row">
                <li className="nav-item">
                  <If condition={this.state.login === false}>
                    <Then>
                      <NavLink className="nav-link" to="/login"> <em className="icon-login mr-1" />{" "} <strong className="font-weight-bold">LOGIN</strong> </NavLink>
                    </Then>
                    <ElseIf condition={this.state.login === true}>
                      <If condition={this.state.consultant === true}>
                        <Then>
                          <li className="nav-item dropdown dropdown-list" href="#">
                            <a className="nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" data-toggle="dropdown" >
                              <div className="row">
                                <div className="col-md-4 align-self-right">
                                  <img className="img-thumbnail circle img-fluid thumb80 ml-auto" src="app/img/user/06.jpg" data-src="app/img/user/06.jpg" alt="d" style={{ maxWidth: "40px", maxHeight: "40px", border: "0px" }} />
                                </div>
                                <div className="col-md-8 align-self-left">
                                  <div className="ms-info">
                                    <p className="text-left mb-0"> <em /> {this.state.userName} <em /> </p>
                                    <div> <small>{this.state.role}</small> </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right animated flipInX">
                              <div className="dropdown-item">
                                <div className="list-group">
                                  <div className="list-group-item list-group-item-action">
                                    <div className="media">
                                      <div className="align-self-start mr-2"> <em className="fas fa-user fa-2x text-info" /> </div>
                                      <div className="media-body">
                                        <NavLink className="nav-link" to="/consultantform" >
                                          <p className="m-0">My Profile</p>
                                          <p className="m-0 text-muted text-sm"> check your profile </p>
                                        </NavLink>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="list-group-item list-group-item-action">
                                    <div className="media">
                                      <div className="align-self-start mr-2"> <em className="fas fa-thumbs-up fa-2x text-warning" /> </div>
                                      <div className="media-body">
                                        <NavLink className="nav-link" to="/consultantdashboard" >
                                          <p className="m-0"> My Availability </p>
                                          <p className="m-0 text-muted text-sm"> check your Availability </p>
                                        </NavLink>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="list-group-item list-group-item-action">
                                    <div className="media">
                                      <div className="align-self-start mr-2">
                                        <em className="fas fa-tasks fa-2x text-success" />
                                      </div>
                                      <div className="media-body">
                                        <NavLink className="nav-link" to="/mybookings" >
                                          <p className="m-0">My Bookings</p>
                                          <p className="m-0 text-muted text-sm"> 11 bookings available </p>
                                        </NavLink>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </Then>
                      </If>
                      <If condition={this.state.consultee === true}>
                        <Then>
                          <li className="nav-item dropdown dropdown-list">
                            <a className="nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" data-toggle="dropdown" >
                              <div className="row">
                                <div className="col-md-4 align-self-right">
                                  <img className="img-thumbnail circle img-fluid thumb80 ml-auto" src="app/img/user/06.jpg" alt="d" data-src="app/img/user/06.jpg" style={{ maxWidth: "40px", maxHeight: "40px", border: "0px" }} />
                                </div>
                                <div className="col-md-8 align-self-left">
                                  <div className="ms-info">
                                    <p className="text-left mb-0"> <em /> {this.state.userName} <em /> </p>
                                    <div> <small>{this.state.role}</small> </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right animated flipInX">
                              <div className="dropdown-item">
                                <div className="list-group">
                                  <div className="list-group-item list-group-item-action">
                                    <div className="media">
                                      <div className="align-self-start mr-2"> <em className="fas fa-user fa-2x text-info" /> </div>
                                      <div className="media-body">
                                        <NavLink className="nav-link" to="/consulteeform" >
                                          <p className="m-0">My Profile</p>
                                          <p className="m-0 text-muted text-sm"> check your profile </p>
                                        </NavLink>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="list-group-item list-group-item-action">
                                    <div className="media">
                                      <div className="align-self-start mr-2"> <em className="fas fa-tasks fa-2x text-success" /> </div>
                                      <div className="media-body">
                                        <NavLink className="nav-link" to="/myappoinments" >
                                          <p className="m-0"> My Appointments </p>
                                          <p className="m-0 text-muted text-sm"> 5 appointments available </p>
                                        </NavLink>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </Then>
                      </If>
                    </ElseIf>
                  </If>
                </li>
              </ul> */}
            </div>
            {/* END Right Navbar*/}
            {/* START Search form*/}
            <form className="navbar-form" role="search" action="search.html">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Type and hit enter ..."
                />
                <div
                  className="fas fa-times navbar-form-close"
                  data-search-dismiss
                />{" "}
              </div>
              <button className="d-none" type="submit">
                {" "}
                Submit{" "}
              </button>
            </form>
            {/* END Search form*/}
          </nav>
          {/* END Top Navbar*/}
        </header>
      </div>
    );
  }
}
const mapStateToProps = state => {
  console.log('NavBar mapStateToProps', state);
  let imageUrl = '';
  if (state.auth.user && state.auth.user.consultant) {
    imageUrl = state.auth.user.consultant.imageUrl
  }

  if (state.auth.user && state.auth.user.consultee) {
    imageUrl = state.auth.user.consultee.imageUrl
  }

  return {
    loggedIn: state.auth.loggedIn,
    username: state.auth.username,
    type: state.auth.type,
    profileImageUrl: imageUrl
  };
};

export default connect(mapStateToProps)(withRouter(Navbar));
