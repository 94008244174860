import React, { Component } from "react";
import {
  Tab,
  Tabs,
  Form,
  Modal,
  Button
} from "react-bootstrap";
import { withRouter } from "react-router-dom";
import BookingDataService from "../api/BookingDataService";
import { API_URL } from "../Constants";
import InvoiceDataService from "../api/InvoiceDataService";
import { connect } from "react-redux";
import { withAlert } from "react-alert";
import ConsulteeDataService from "../api/ConsulteeDataService";
import { SET_CONSULTEE } from "../redux/actionTypes";
import UserDataService from "../api/UserDataService";
import ReactStars from "react-rating-stars-component";
import MyInvoice from "./consultee-dashborad/MyInvoice";
import UpcomingBookings from "./consultee-dashborad/UpcomingBookings";
import PreviousAppointments from "./consultee-dashborad/PreviousAppointments";

class ConsulteeDashboard extends Component {
  state = {
    upcoming: [],
    previous: [],
    invoices: {
      pending: [],
      success: []
    },
    rate: null,
    showRateModal: false,
    ratingCount: -1,
    ratingComment: ''
  };

  componentDidMount() {
    this.getConsultee();
    this.getUpcomingAppointments();
    this.getPreviousAppointments();
    this.getInvoices();
    this.ratingPrompt();
  }

  getConsultee() {
    if (this.props.consulteeId) {
      ConsulteeDataService.retrieveConsultee(this.props.consulteeId)
        .then(res => {
          console.log(res);
          if (res && res.status === 200 && res.data !== "") {
            this.props.dispatch({ type: SET_CONSULTEE, payload: res.data });
            UserDataService.registerSuccessfulLogin(this.props.user);
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  }

  getUpcomingAppointments() {
    BookingDataService.getBookingsByConsultee(this.props.consulteeId)
      .then(res => {
        if (res && res.status === 200) {
          this.setState({
            upcoming: res.data
          });
        }
      })
      .catch(e => { });
  }

  getPreviousAppointments() {
    BookingDataService.getPreviousBookingsByConsultee(this.props.consulteeId)
      .then(res => {
        if (res && res.status === 200) {
          this.setState({
            previous: res.data
          });
        }
      })
      .catch(e => { });
  }

  getInvoices() {
    InvoiceDataService.getInvoicesByConsultee(this.props.consulteeId)
      .then(res => {
        if (res && res.status === 200) {
          const pending = res.data.filter(item => {
            return (
              item.status === "PENDING_PAYMENT" ||
              item.status === "PENDING_APPROVAL" ||
              item.status === "FAILED_PAYMENT"
            );
          });
          const success = res.data.filter(item => {
            return item.status === "SUCCESS_PAYMENT";
          });
          this.setState({
            invoices: {
              pending: pending,
              success: success
            }
          });
          console.log(this.state.invoices);
        }
      })
      .catch(e => { });
  }

  takeVideoCall = id => {
    BookingDataService.isBookingLive(id)
      .then(res => {
        if (res !== null && res.status === 200 && res.data) {
          this.props.history.push(`/video/${id}`);
        } else {
          this.props.alert.error("This meeting is expired.")
        }
      })
      .catch(e => {

      })
  };

  pay(id) {
    InvoiceDataService.payFromInvoiceId(id)
      .then(res => {
        if (res && res.status === 200 && res.data) {
          // if (res.data.hasOwnProperty("returnUrl")) {
          //   const obj = {
          //     ...res.data,
          //     returnUrl: `${API_URL}${res.data.returnUrl}`
          //   };
          //   this.props.history.push({
          //     pathname: "/paycrop",
          //     paymentRequest: obj
          //   });
          // }
          console.log(res.data)
          if (res.data.returnUrl === '/payment-gate') {
            const obj = {
              ...res.data,
              returnUrl: `${API_URL}${res.data.returnUrl}`
            }
            this.props.history.push({
              pathname: '/paycorp',
              paymentRequest: obj,
            })
            // The returnUrl is either '/payment-gate' or '/slipupload'
            // Add your code here
          }else if (res.data.returnUrl === '/slip-upload') {
            const obj = {...res.data}
            this.props.history.push({
              pathname: '/slipupload',
              bookingDetails: obj
            })
          }
        }
      })
      .catch(e => { });
  }

  cancel(id) {
    InvoiceDataService.cancelBookingByInvoiceId(id)
      .then(res => {
        if (res && res.status === 202) {
          this.getInvoices();
        }
      })
      .catch(e => { });
  }

  ratingPrompt = () => {
    BookingDataService.getLatestConsulteeRatingDetails(this.props.consulteeId)
      .then(res => {
        if (res.status === 200 && res.data != null) {
          this.setState({
            rate: res.data,
            showRateModal: true
          })
        }
      })
      .catch(e => {

      })
  }

  ratingPromptForBooking(id) {
    BookingDataService.getRatingDetailsById(id)
      .then(res => {
        if (res.status === 200 && res.data != null) {
          this.setState({
            rate: res.data,
            showRateModal: true
          })
        }
      })
      .catch(e => {

      })
  }

  closeModal = () => {
    const rate = { bookingRateId: this.state.rate.id, rate: this.state.ratingCount, comment: this.state.ratingComment }
    BookingDataService.updateLatestConsulteeRatingDetails(rate)
      .then(res => {
        this.setState({
          showRateModal: false
        });
      })
      .catch(e => {
        this.setState({
          showRateModal: false
        });
      })

  }

  rateConsultant = () => {
    const rate = { bookingRateId: this.state.rate.id, rate: this.state.ratingCount, comment: this.state.ratingComment }
    BookingDataService.updateLatestConsulteeRatingDetails(rate)
      .then(res => {
        this.setState({
          showRateModal: false,
          previous: []
        }, () => {
          this.getPreviousAppointments()
        });
      })
      .catch(e => {
        this.setState({
          showRateModal: false,
          previous: []
        }, () => {
          this.getPreviousAppointments()
        });
      })
  }

  ratingChanged = (newRating) => {
    console.log(newRating)
    this.setState({
      ratingCount: newRating
    })
  }

  onChnageTextArea = (event) => {
    this.setState({
      ratingComment: event.target.value
    })
  }

  onSelectTab(key) {
    console.log(key)
  }

  render() {
    return (
      <div>
        <section className="section-container ml-0">
          <div className="content-wrapper">
            <div className="content-heading">
              <div>
                Consultee Dashboard
                <small data-localize="dashboard.WELCOME" />
              </div>
            </div>
            <div className="float-right">
              <button
                onClick={() => {
                  this.props.history.push("/searchconsultant/");
                }}
                type="button"
                className="btn btn-warning btn-lg text-bold fa-2x"
              >
                {" "}
                Book a Cerebroslot <em className="fas fa-plus fa-lg ml-1" />{" "}
              </button>
            </div>
            <br />
            <br />
            <Tabs
              onSelect={(selected) => this.onSelectTab(selected)}
              defaultActiveKey="upcoming-appointments"
              transition={false}
              id="noanim-tab-example"
            >
              <Tab
                eventKey="upcoming-appointments"
                title="Upcoming Bookings"
                style={{ textColor: "green" }}
              >
                <UpcomingBookings
                  bookings={this.state.upcoming}
                  call={(id) => this.takeVideoCall(id)}
                  onComplete={() => this.getUpcomingAppointments()}
                />
              </Tab>
              <Tab eventKey="previous-appointments" title="Previous Bookings" style={{ textColor: 'green' }}>
                <PreviousAppointments
                  bookings={this.state.previous}
                  ratingPromptForBooking={(id) => this.ratingPromptForBooking(id)}
                />
              </Tab>
              <Tab
                eventKey="my-invoice"
                title="My Invoice"
                style={{ textColor: "green" }}
              >
                <MyInvoice
                  invoices={this.state.invoices}
                  pay={id => this.pay(id)}
                  cancel={id => this.cancel(id)}
                />
              </Tab>
            </Tabs>
          </div>
        </section>
        <Modal show={this.state.showRateModal}>
          <Modal.Header>
            <Modal.Title>Rate Consultant</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              this.state.rate != null ? <div className="row align-items-center">
                <div className="col-md-4 text-center">
                  <img style={{ objectFit: 'cover', maxWidth: '100px', maxHeight: '100px' }} className="img-thumbnail circle img-fluid thumb128" src={this.state.rate.consultantImageURL ? this.state.rate.consultantImageURL : "app/img/user/avatar.svg"} alt="d" />
                </div>
                <div className="col-md-6">
                  <div className="d-flex">
                    <div className="text-left">
                      <h3 className="mt-0">{this.state.rate.consultantName}</h3>
                      <p className="lead mb-2">{this.state.rate.slotDate}</p>
                      <p className="mb-2">{`${this.state.rate.slotStartTime} - ${this.state.rate.slotEndTime}`}</p>
                      <ReactStars
                        count={5}
                        onChange={this.ratingChanged}
                        size={24}
                        activeColor="#ffd700"
                      />
                    </div>
                  </div>
                </div>
              </div> : null
            }

            <Form>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Comment</Form.Label>
                <Form.Control as="textarea" rows={3} onChange={this.onChnageTextArea} />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.rateConsultant} className="btn btn-warning btn-md text-bold fa-2x">Rate</Button>
            <Button onClick={this.closeModal} className="btn btn-white btn-md text-bold fa-2x">Skip</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    consulteeId: state.auth.consulteeId,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(withRouter(withAlert()(ConsulteeDashboard)));