import axios from "axios";
import { API_URL } from "../Constants.js";
import authHeader from "./AuthHeaders.js";

class PaymentDataService {
  updatePayment(data) {
    return axios.put(`${API_URL}/payments`, data, { headers: authHeader() });
  }

  initiatePaymentGate(invoiceId) {
    return axios.post(`${API_URL}/payments/initiate`, {}, { headers: authHeader(), params: { invoiceId: invoiceId } });
  }
}

export default new PaymentDataService();