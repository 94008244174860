import React from "react";
import { Route, HashRouter, Switch, Redirect } from "react-router-dom";
import Navbar from "./components/Navbar.js";
import Footer from "./components/Footer.js";
import Home from "./components/Home.js";
import AboutUs from "./components/AboutUs.js";
import Services from "./components/Services.js";
import ContactUs from "./components/ContactUs.js";
import PrivacyPolicy from "./components/PrivacyPolicy.js";
import RefundPolicy from "./components/RefundPolicy.js";
import TermsConditions from "./components/TermsConditions.js";
import Login from "./components/Login.js";
import Profile from "./components/Profile.js";
import ConsultantForm from "./components/ConsultantForm.js";
import ConsulteeForm from "./components/ConsulteeForm.js";
import MyAppoinments from "./components/MyAppoinments.js";
import SearchConsultant from "./components/SearchConsultant.js";
import SignupConsultee from "./components/SignupConsultee.js";
import SignupConsultant from "./components/SignupConsultant.js";
import ConsultantDashboard from "./components/ConsultantDashboard.js";
import MyBookings from "./components/MyBookings.js";
import Video from "./components/Video.js";
import ConsulteeDashboard from "./components/ConsulteeDashboard.js";
import AdminDashboard from "./components/AdminDashboard.js";
import PaycropiFrame from "./components/PaycropiFrame.js";
import { connect } from "react-redux";
import RestPassword from "./components/RestPassword.js";
import UpdatePassword from "./components/UpdatePassword.js";
import LoadingScreen from "react-loading-screen";
import SlipUpload from "./components/SlipUpload.js";
import PaymentGateInitiate from "./components/PaymentGateInitiate.js";
import { PaymentGateSuccess } from "./components/PaymentGateSuccess.js";
import { PaymentGateFailed } from "./components/PaymentGateFailed.js";
import { AuthContext } from "./AuthContext.js";

function App(props) {
  return (
    <div className="App">
      <LoadingScreen
        loading={props.loading}
        bgColor="rgba(241,241,241,0.5)"
        spinnerColor="#ff902b"
        textColor="#676767"
        logoSrc="./app/favicon.png"
        children={null}
      >
        <></>
      </LoadingScreen>
      <AuthContext>
        <HashRouter>
          <Navbar {...props} />
          {props.loggedIn ? (
            <>
              {props.type === "CONSULTANT" ? (
                <AuthRoutesForConsultant {...props} />
              ) : props.type === "CONSULTEE" ? (
                <AuthRoutesForConsultee {...props} />
              ) : props.type === "ADMIN" ? (
                <AuthRoutesForAdmin {...props} />
              ) : null}
            </>
          ) : (
            <NotAuthRoutes />
          )}
          <Footer />
        </HashRouter>
      </AuthContext>
    </div>
  );
}
const mapStateToProps = state => {
  return {
    loggedIn: state.auth.loggedIn,
    type: state.auth.type,
    consultee: state.auth.user !== null ? state.auth.user.consultee : null,
    consultant: state.auth.user !== null ? state.auth.user.consultant : null,
    loading: state.app.loading,
  };
};
export default connect(mapStateToProps)(App);

function AuthRoutesForConsultant(props) {
  return (
    <>
      <Switch>
        <Route path="/consultantform" component={ConsultantForm} />
        <Route path="/updateconsultant" component={ConsultantForm} />
        <Route path="/myappoinments" component={MyAppoinments} />
        <Route
          path="/consultantdashboard"
          render={r => {
            return props.consultant === null ? (
              <ConsultantForm />
            ) : (
                <ConsultantDashboard />
              );
          }}
        />
        <Route exact path="/video/:id" component={Video} />
        <Redirect
          to={
            props.consultant === null ? "consultantform" : "consultantdashboard"
          }
        />
      </Switch>
    </>
  );
}

function AuthRoutesForConsultee(props) {
  return (
    <>
      <Switch>
        <Route exact path="/profile/:id" component={Profile} />
        <Route path="/consulteeform" component={ConsulteeForm} />
        <Route path="/updateconsultee" component={ConsulteeForm} />
        <Route path="/searchconsultant/*" component={SearchConsultant} />
        <Route path="/mybookings" component={MyBookings} />
        <Route
          path="/consulteedashboard"
          render={r => {
            return props.consultee === null ? (<ConsulteeForm />) : (<ConsulteeDashboard />);
          }}
        />
        <Route exact path="/video/:id" component={Video} />
        <Route exact path="/payment-gate" component={PaymentGateInitiate} />
        <Route exact path="/payment-gate/success" component={PaymentGateSuccess} />
        <Route exact path="/payment-gate/failed" component={PaymentGateFailed} />
        <Route exact path="/slipupload" component={SlipUpload} />
        <Redirect to={props.consultee === null ? "consulteeform" : "consulteedashboard"} />
      </Switch>
    </>
  );
}

function AuthRoutesForAdmin(props) {
  return (
    <>
      <Switch>
        <Route exact path="/profile/:id" component={Profile} />
        <Route path="/consulteeform" component={ConsulteeForm} />
        <Route path="/admindashboard"
          render={r => {
            return props.admin === null ? (<ConsulteeForm />) : (<AdminDashboard />);
          }}
        />
        <Redirect to={props.admin === null ? "consulteeform" : "admindashboard"} />
      </Switch>
    </>
  );
}

function NotAuthRoutes() {
  return (
    <>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/aboutus" component={AboutUs} />
        <Route path="/services" component={Services} />
        <Route path="/contactus" component={ContactUs} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/refund-policy" component={RefundPolicy} />
        <Route path="/terms-conditions" component={TermsConditions} />
        <Route path="/login" component={Login} />
        <Route exact path="/profile/:id" component={Profile} />
        <Route path="/searchconsultant/*" component={SearchConsultant} />
        <Route path="/signupconsultee" component={SignupConsultee} />
        <Route path="/signupconsultant" component={SignupConsultant} />
        <Route path="/resetpassword" component={RestPassword} />
        <Route path="/updatepassword/:token" component={UpdatePassword} />
        <Redirect to="/" />
      </Switch>
    </>
  );
}
