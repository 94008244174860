import React, { Component } from 'react'
import { withAlert } from 'react-alert'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import ConsultantCategoryDataService from '../../../api/ConsultantCategoryDataService'
import ConsultantDataService from '../../../api/ConsultantDataService'
import { HIDE_LOADING, SHOW_LOADING } from '../../../redux/actionTypes'
import { Constants } from '../../Common'
import ConsultantResult from './ConsultantResult'
import ConsultantReviewModel from './ConsultantReviewModel'
import ConsultantSearch from './ConsultantSearch'

class ConsultantSearchBase extends Component {

    DEFAULT_SEARCH_VALUES = {
        firstName: '',
        lastName: '',
        email: '',
        slotSize: '',
        slotRate: '',
        categoryId: '',
        status: 'VERIFIED',
    }

    STATUS_LIST = [
        { value: "VERIFIED", name: "Verified" },
        { value: "PENDING_APPROVAL", name: "Pending Approval" }
    ]


    state = {
        pageInfo: Constants.DEFAULT_PAGE_INFO,
        searchData: this.DEFAULT_SEARCH_VALUES,
        showModal: false,
        selectedConsultant: null,
        pagedConsultantData: {},
        showModal: false,
        categoryList: []
    }

    componentDidMount() {
        this.getConsultantCategoryList();
        this.onSearch();
    }

    getConsultantCategoryList = () => {
        ConsultantCategoryDataService.retrieveAllConsultantCategory()
            .then(res => {
                if (res && res.status === 200) {
                    this.setState({
                        ...this.state,
                        categoryList: res.data
                    })
                }
            }).catch(e => {
                this.props.alert.error("Failed to get category list. Please try again.");
            });
    }


    onSearch = () => {
        this.props.dispatch({ type: SHOW_LOADING });
        const searchData = {
            ...this.state.pageInfo,
            ...this.state.searchData
        }
        const isPublic = this.props.isPublic;
        if(isPublic) {
            ConsultantDataService.getPagedConsultantListPublic(searchData)
            .then(res => {
                if (res && res.status === 200) {
                    this.setState({
                        ...this.state,
                        pagedConsultantData: res.data
                    });
                }
                this.props.dispatch({ type: HIDE_LOADING });
            }).catch(e => {
                this.props.dispatch({ type: HIDE_LOADING });
                this.props.alert.error("Failed to get consultant list. Please try again.");
            });
        } else {
            ConsultantDataService.getPagedConsultantList(searchData)
            .then(res => {
                if (res && res.status === 200) {
                    this.setState({
                        ...this.state,
                        pagedConsultantData: res.data
                    });
                }
                this.props.dispatch({ type: HIDE_LOADING });
            }).catch(e => {
                this.props.dispatch({ type: HIDE_LOADING });
                this.props.alert.error("Failed to get consultant list. Please try again.");
            });       

        }
    }

    onSearchClick = () => {
        this.setState({
            ...this.state,
            pageInfo: Constants.DEFAULT_PAGE_INFO
        }, () => this.onSearch())
    }


    setSearchData = (name, value) => {
        this.setState({
            ...this.state,
            searchData: {
                ...this.state.searchData,
                [name]: value
            }
        })
    }

    resetSearchData = () => {
        this.setState({
            ...this.state,
            pageInfo: Constants.DEFAULT_PAGE_INFO,
            searchData: this.DEFAULT_SEARCH_VALUES
        })
    }

    onPageInfoChange = (pageInfo) => {
        this.setState({
            ...this.state,
            pageInfo: pageInfo
        }, () => this.onSearch())
    }

    onItemClick = (item) => {
        this.setState({
            ...this.state,
            selectedConsultant: item,
            showModal: true
        })
    }

    onCloseClick = () => {
        this.setState({
            ...this.state,
            selectedConsultant: null,
            showModal: false
        })
    }

    onUpdateClick = (status) => {
        this.props.dispatch({ type: SHOW_LOADING });
        const data = {
            status: status,
        }
        ConsultantDataService.updateConsultantStatus(data, this.state.selectedConsultant.id)
            .then(res => {
                if (res && res.status === 200) {
                    this.onCloseClick()
                    this.onSearch();
                    this.props.alert.success("Consultant updated successfully.");
                }
                this.props.dispatch({ type: HIDE_LOADING });
            }).catch(e => {
                this.props.dispatch({ type: HIDE_LOADING });
                this.props.alert.error("Failed to update consultant status.");
            });
    }

    render() {
        const { showModal, pagedConsultantData, searchData, selectedConsultant, categoryList } = this.state;
        const invoiceList = this.state.pagedConsultantData.pageData ? this.state.pagedConsultantData.pageData : [];
        const currentRoleView = this.props.currentRoleView ? this.props.currentRoleView : Constants.ROLE_CONST.CONSULTEE;

        return (
            <>
                <ConsultantSearch
                    searchData={searchData}
                    setSearchData={this.setSearchData}
                    resetSearchData={this.resetSearchData}
                    onSearch={this.onSearchClick}
                    statusList={this.STATUS_LIST}
                    categoryList={categoryList}
                    currentRoleView={currentRoleView}
                />
                <ConsultantResult
                    dataList={invoiceList}
                    statusList={this.STATUS_LIST}
                    pagedData={pagedConsultantData}
                    onPageInfoChange={this.onPageInfoChange}
                    onItemClick={this.onItemClick}
                    currentRoleView={currentRoleView}
                />
                {
                    showModal &&
                    <ConsultantReviewModel
                        show={showModal}
                        onCloseClick={this.onCloseClick}
                        item={selectedConsultant}
                        onUpdateClick={this.onUpdateClick}
                        statusList={this.STATUS_LIST}
                    />
                }
            </>
        )
    }
}


export default connect()(withRouter(withAlert()(ConsultantSearchBase)));

