import React from 'react'

function TermsConditions() {
  return (
    <>
      {/* top navbar*/}
      {/* Main section*/}
      <section className="section-container ml-0">
        {/* Page content*/}
        <div className="content-wrapper">
          <div className="row">
            <div className="col-12">
              <div className=" card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-1 offset-md-1 text-left align-self-left"></div>
                    <div className="col-md-12 align-self-left">
                      <h1 className="text-center mb-3" >Terms & Conditions </h1>
                      <hr className="border-warning my-5" />
                      <p className="text-left mb-3 lead  float-left">
                        1. Acceptance of Terms<br />
                            By accessing and using the Cerebrozone.com website (the "Site"), you agree to comply with
                            these Terms and Conditions ("Terms"). If you do not agree to these Terms, you may not access or
                            use the Site.<br /><br />

                        2. Registration<br />
                            - To use certain features of the Site, consultants ("Consultants") and consultantees
                            ("Consultantees") must register for an account.<br />
                            - You must provide accurate and complete information and keep your account information
                            updated.<br />
                            - You are responsible for maintaining the confidentiality of your account and password.<br />
                            - You agree to notify us immediately of any unauthorized use of your account.<br /><br />
                            
                        3. Services<br />
                            - Consultantees can browse consultants' profiles, schedule appointments, and book time slots for
                            consultations.<br />
                            - Consultations are conducted via a video call function provided on the Site.<br /><br />

                        4. Payments and Commissions<br />
                            - Consultantees agree to pay the consultant's fees as listed on the Site at the time of booking.<br />
                            - The Site charges a 30% commission on the payment made to the Consultant for the
                            consultation services.<br />
                            - Payment processing will be handled by the Site's secure payment system.<br /><br />

                        5. Cancellation and Refunds<br />
                            - The Site's cancellation and refund policy will be clearly stated, including any deadlines for
                            canceling appointments and conditions under which refunds will be given.<br /><br />
                            
                        6. Conduct<br />
                            - Users agree to conduct themselves professionally and responsibly during all interactions on the
                            Site.<br />
                            - Any form of harassment, abuse, or inappropriate behavior may result in account suspension or
                            termination.<br /><br />

                        7. Intellectual Property<br />
                            - All content on the Site, including text, graphics, logos, and software, is the property of the Site
                            or its content suppliers and protected by intellectual property laws.<br /><br />

                        8. Disclaimer of Warranties<br />
                            - The Site is provided "as is" without warranty of any kind, express or implied.<br /><br />

                        9. Limitation of Liability<br />
                            - The Site will not be liable for any damages arising from the use of the Site or the services
                            provided.<br /><br />

                        10. Governing Law<br />
                            - These Terms are governed by the laws of the jurisdiction in which the Site operates.<br /><br />

                        11. Changes to Terms<br />
                            - The Site reserves the right to change these terms at any time without prior notice.<br /><br />

                        12. Contact Information<br />
                            - For any questions or concerns regarding these Terms, please contact us through the provided
                        contact channels on the Site.<br /><br />
                        
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Page footer*/}
    </>
  )
}

export default TermsConditions