import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import ConsultDataService from "../api/ConsultantDataService";
import CerebroSlotDataService from "../api/CerebroSlotDataService"
import Pagination from "react-js-pagination";
import { Constants, curentDateForPicker } from '../components/Common'
import { API_URL } from '../Constants'
import BookingDataService from "../api/BookingDataService";
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import PaymentDataService from "../api/PaymentDataService";


class Profile extends Component {
  constructor() {
    super();
    this.state = {
      profile: {},
      consultantList: [],
      datedCerebroslotList: [],
      date: curentDateForPicker(),
      allCerebroSlotList: [],
      activePage: 1,
      totalItemsCount: 0,
      itemsCountPerPage: 3,
      selectedBookings: []
    };
    this.refresh = this.refresh.bind(this);
  }

  componentDidMount() { this.refresh(); }

  componentDidUpdate() { }

  refresh() {
    ConsultDataService.getConsultant(this.props.match.params.id)
      .then((res) => {
        if (res && res.status === 200) {
          this.setState({ profile: res.data });
        }
      })
      .catch(e => {

      })
    this.getCelebroSlots(this.state.date);
  }

  getCelebroSlots(date = null) {
    let formattedDate = null;
    if (date != null) {
      formattedDate = date
      this.setState({ date: formattedDate })
    }
    CerebroSlotDataService.getCelebroSlotsBy(this.props.match.params.id, formattedDate)
      .then(res => {
        if (res && res.status === 200) {
          this.setState({
            datedCerebroslotList: res.data,
            selectedBookings: []
          });
        }
      })
      .catch(e => {

      })
  }

  selectDeselectBooking(item, selected) {
    if (selected) {
      const bookings = this.state.selectedBookings
      bookings.push(item)
      this.setState({
        selectedBookings: bookings
      }, () => {
        console.log(this.state.selectedBookings)
      })
    } else {
      const bookings = this.state.selectedBookings
      const index = bookings.findIndex((i) => {
        return i.id === item.id
      })
      if (index > -1) {
        bookings.splice(index, 1);
      }
      this.setState({
        selectedBookings: bookings
      }, () => {
        console.log(this.state.selectedBookings)
      })
    }
  }

  bookSlots(paymentMethod) {
    if (this.props.consulteeId === null) {
      this.props.alert.error('Please signin for book a consultant')
      return
    }
    if (this.state.selectedBookings.length === 0) {
      this.props.alert.error('Please select at least one cerebroslot')
      return
    }
    if (this.state.selectedBookings.length > 0) {
      const bookings = this.state.selectedBookings.map(item => {
        return {
          bookingDate: curentDateForPicker(),
          consulteeId: this.props.consulteeId,
          cerebroslotId: item.id,
          consultantId: item.consultantId
        }
      })
      BookingDataService.saveBookings(bookings, paymentMethod)
        .then(res => {
          if (res && res.status === 200 && res.data) {
            this.getCelebroSlots(this.state.date)

            if (res.data.hasOwnProperty('returnUrl')) {
              if (res.data.returnUrl === '/payment-gate') {
                PaymentDataService.initiatePaymentGate(res.data.invoiceId)
                  .then(res => {
                    this.props.history.push({
                      pathname: '/payment-gate',
                      paymentGateRequest: res.data
                    });
                  })
              } else if (res.data.returnUrl === '/slip-upload') {
                const obj = { ...res.data }
                this.props.history.push({
                  pathname: '/slipupload',
                  bookingDetails: obj
                })
              }
            } else {
              this.props.history.push({
                pathname: '/error'
              })
            }
          }
        })
        .catch(e => {

        })
    }
  }

  render() {
    return (
      <div>
        <section className="section-container ml-0">
          {/* Page content*/}
          <div className="content-wrapper">
            <div className="content-heading"> <div> Profile <small data-localize="dashboard.WELCOME" /> </div>
            </div>
            <div className="row">
              <div className="col-12">
                <ProfileDetail profile={this.state.profile} />
                <CerebroSlots {...this.props}
                  date={this.state.date}
                  onDateChange={(value) => this.getCelebroSlots(value)}
                  slots={this.state.datedCerebroslotList}
                  onCheck={(item, selected) => this.selectDeselectBooking(item, selected)} />

              </div>
            </div>
          </div>
        </section>
        {/* Page footer*/}

        {/* Modal */}
        <BookingModal bookings={this.state.selectedBookings} bookSlots={(paymentMethod) => this.bookSlots(paymentMethod)} />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    consulteeId: state.auth.consulteeId,
    hasLoggedOut: !state.auth.loggedIn
  };
}
export default connect(mapStateToProps)(withRouter(withAlert()(Profile)));

function ProfileDetail(props) {
  return (
    <div className="card">
      <div className="card-body">
        <div className="row align-items-center">
          <div className="col-md-2 text-center offset-md-1">
            <img style={{ objectFit: 'cover' }} className="img-thumbnail circle img-fluid thumb128" src={props.profile.imageUrl ? props.profile.imageUrl : "app/img/user/avatar.svg"} alt="d" />
          </div>
          <div className="col-md-8">
            {/* <div className="float-right">
              <a className="btn" > <em className="fas fa-2x icon-heart text-danger" /> </a>
            </div> */}
            <div className="d-flex">
              <div className="text-left">
                <h3 className="mt-0">{props.profile.firstName ?? ''}&nbsp;{props.profile.lastName ?? ''}</h3>
                <p className="lead mb-2">{props.profile.categoryName ?? ''}</p>
                <p className="mb-2"> <em className="fas fa-language fa-fw fa-lg text-warning" /> {props.profile.experience ?? 0}&nbsp;Years of Experience </p>
                {/* <div className="w-100 mb-2">
                  <span className="badge badge-dark">HTML</span>
                  <span className="badge badge-dark">CSS</span>
                  <span className="badge badge-dark">JQuary</span>
                </div> */}
                <p className="text-muted"> {props.profile.about ?? ''} </p>
                <ul className="list-unstyled mb-0">
                  <li className="mb-1">
                    <em className="fa fa-money-bill fa-fw text-warning" />
                    <span className="font-weight-bold"> {" "} {(props.profile.slotRate ?? 0).toLocaleString()}LKR{" "} </span> / {props.profile.slotSize ?? 0}min
                    <em className="fas fa-map-marker-alt fa-fw text-warning" />{" "}
                    {props.profile.country ?? ''}
                  </li>
                  <li className="mb-1"></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function CerebroSlots(props) {

  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h4 className="mb-0">Search Availability</h4>
            <hr className="border-warning mb-3 mt-2" />
            <div className="form-group row my-3">
              <label className="col-md-2 col-form-label mb-2">
                Booking date
              </label>
              <div className="col-sm-3">
                <div
                  className="input-group date"
                  id="datetimepicker1">
                  <input className="form-control" value={props.date} type="date" onChange={(e) => props.onDateChange(e.target.value)} />
                  <button
                    onClick={() => {
                      props.onDateChange(props.date)
                    }}
                    type="button"
                    className="btn btn-warning btn-md text-bold fa-2x"
                    style={{ marginLeft: '15px' }}> Search </button>
                </div>
              </div>
              {/* <div className="w-100 mx-2 text-right">
                
              </div> */}
              {/*                                        <button class="btn btn-primary btn-sm">Search <em class="fas fa-search fa-lg"></em></button>*/}
            </div>
            {props.slots.map((item) => {
              return (
                <div className="list-group mb-3" key={item.id}>
                  <a className="list-group-item">
                    <table className="wd-wide">
                      <tbody>
                        <tr>
                          {/* <td className="wd-xs">
                      <div className="px-2">
                        <img className="img-fluid rounded thumb64" src="app/img/dummy.png" alt="d" />
                      </div>
                    </td> */}
                          <td>
                            <div className="px-2">
                              <h4 className="mb-2">{item.startDate}</h4>
                            </div>
                          </td>
                          <td className="wd-sm d-lg-table-cell">
                            <div className="px-2">
                              <p className="m-0">{item.startTime}</p>
                              <small className="text-muted">From</small>
                            </div>
                          </td>
                          <td className="wd-sm d-lg-table-cell">
                            <div className="px-2">
                              <p className="m-0">{item.endTime}</p>
                              <small className="text-muted">To</small>
                            </div>
                          </td>
                          {/* <td className="wd-xs d-lg-table-cell">
                      <div className="px-2">
                        <p className="m-0 text-muted">
                          <em className="icon-eye mr-2 fa-lg" />
                                        26
                                      </p>
                      </div>
                    </td> */}
                          <td className="wd-xs d-lg-table-cell">
                            <div className="px-2">
                              <p className="m-0 text-muted">
                                {/* <em className="fas fa-dollar-sign mr-2 fa-lg" /> */}
                                {item.slotPrice.toLocaleString()} LKR
                              </p>
                            </div>
                          </td>
                          <td className="wd-sm text-center d-lg-table-cell">
                            <div className="px-2">

                              <span className={`badge badge-${item.status === 'AVAILABLE' ? 'success' : 'warning'} animated pulse infinite`}>
                                {item.status}
                              </span>
                            </div>
                          </td>
                          <td className="wd-xs text-center d-lg-table-cell">
                            {item.status === 'AVAILABLE' ?
                              <div className="px-2">
                                <input
                                  className="styled-checkbox"
                                  id={item.id}
                                  type="checkbox"
                                  defaultChecked={false}
                                  onChange={(e) => { props.onCheck(item, e.target.checked) }}
                                />
                                <label htmlFor={item.id} />
                              </div>
                              : null}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </a>
                </div>

              )
            })}
            <div className="w-100 mx-2 text-right">
              <button
                onClick={() => {
                  props.history.push('/consulteedashboard')
                }}
                type="button"
                className="btn btn-warning btn-lg text-bold fa-2x"
                style={{ marginRight: '15px' }}>
                Dashboard
              </button>
              <button
                type="button"
                className="btn btn-warning btn-lg text-bold fa-2x"
                data-toggle="modal"
                data-target="#exampleModal">
                Book my Cerebrozone Time
                <em className="fas fa-plus fa-lg ml-1" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function BookingModal(props) {

  function calculateFullAmount() {
    let amount = 0
    props.bookings.forEach(element => {
      amount = amount + element.slotPrice
    });
    return amount
  }

  return (<div
    className="modal fade"
    id="exampleModal"
    tabIndex={-1}
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div
      className="modal-dialog modal-lg modal-dialog-centered"
      role="document">
      <div className="modal-content">
        <div className="modal-body">
          {props.bookings.map((item) => {
            return (
              <div className="list-group mb-3" key={item.id}>
                <a className="list-group-item" >
                  <table className="wd-wide">
                    <tbody>
                      <tr>
                        <td>
                          <div className="px-2">
                            <h4 className="mb-2">{item.startDate}</h4>
                          </div>
                        </td>
                        <td className="wd-sm d-lg-table-cell">
                          <div className="px-2">
                            <p className="m-0">{item.startTime}</p>
                            <small className="text-muted">From</small>
                          </div>
                        </td>
                        <td className="wd-sm d-lg-table-cell">
                          <div className="px-2">
                            <p className="m-0">{item.endTime}</p>
                            <small className="text-muted">To</small>
                          </div>
                        </td>
                        {/* <td className="wd-xs d-lg-table-cell">
                          <div className="px-2">
                            <p className="m-0 text-muted">
                              <em className="icon-eye mr-2 fa-lg" />
                              {item.experience}
                            </p>
                          </div>
                        </td> */}
                        <td className="wd-xs d-lg-table-cell">
                          <div className="px-2">
                            <p className="m-0 text-muted">
                              {/* <em className="fas fa-dollar-sign mr-2 fa-lg" /> */}
                              {`${item.slotPrice.toLocaleString()} `}LKR
                            </p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </a>
              </div>

            );
          })}
          <div className=" text-right">
            <h3 className="font-weight-normal text-warning mb-0">
              <small className="text-muted">Estimated Cost </small>
              {calculateFullAmount().toLocaleString()} LKR
            </h3>
          </div>
        </div>
        <div className="modal-footer border-warning">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal">
            Close
          </button>
          <button
            onClick={() => props.bookSlots(Constants.PAYMENT_METHOD_CONST.SLIP)}
            type="button"
            className="btn btn-warning"
            data-dismiss="modal">
            Confirm Booking with Slip Upload
          </button>
          <button
            onClick={() => props.bookSlots(Constants.PAYMENT_METHOD_CONST.ONLINE)}
            type="button"
            className="btn btn-success"
            data-dismiss="modal">
            Confirm Booking with Card Payment
          </button>
        </div>
      </div>
    </div>
  </div>);
}

function SimilarConsultant() {
  return (
    <div className="row">
      <div className="col-md-10 offset-md-1">
        <h4 className="mb-0">Similar Consultants</h4>
        <hr className="border-warning mb-3 mt-2" />
        <OwlCarousel loop items={3} margin={8} autoplay={true} nav className="owl-theme" navText={["<i class='fa fa-chevron-left' style=\"font-size:24px\"/>", "<i class='fa fa-chevron-right' style=\"font-size:24px\"/>",]} animateOut={true} animateIn={true}>
          <div className="item">
            <div className="card ">
              <div className="card-body">
                <div className="row">
                  <div className="col-6 text-center">
                    <img
                      className="circle thumb96"
                      src="app/img/user/03.jpg"
                      alt="d"
                    />
                  </div>
                  <div className="col-6">
                    <h3 className="m-0">Peggy Peters</h3>
                    <p className="text-muted">Software Engineer</p>
                    <ul className="list-unstyled mb-0">
                      <li className="mb-1">
                        <em className="fa fa-money-bill fa-fw" />
                        <span className="text-warning">
                          {" "}
                          3500LKR{" "}
                        </span>
                        / 60min
                      </li>
                      <li className="mb-1">
                        <em className="fas fa-map-marker-alt fa-fw" />{" "}
                        ASD, Qwerty
                      </li>
                    </ul>
                  </div>
                  <div className="col-12">
                    <hr className="border-warning my-2" />
                  </div>
                  <div className="col-12">
                    <span className="badge badge-dark">HTML</span>
                    <span className="badge badge-dark">CSS</span>
                    <span className="badge badge-dark">JQuary</span>
                  </div>
                </div>
              </div>
              <NavLink className="text-white" to="/profile">
                <div className="card-body text-center bg-warning">
                  <div className="row">
                    <div className="col-12">
                      <b> View Profile</b>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
          </div>
        </OwlCarousel>
      </div>
    </div>
  );
}

function AlsoAvailable() {
  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h4 className="mb-0">Also Available in</h4>
            <hr className="border-warning mb-3 mt-2" />
            <div className="row">
              {this.state.allCerebroSlotList.map(cerebroSlot => (
                <div className="list-group mb-3 col  pl-3">
                  {/*eslint-disable-next-line*/}
                  <a className="list-group-item" >
                    <div>
                      <div className="px-2">
                        <h4 className="mb-2">{cerebroSlot.startDate}</h4>
                        <small className="text-muted">
                          Vestibulum ante ipsum primis in faucibus
                          orci
                        </small>
                      </div>
                    </div>
                    <div className="wd-sm d-lg-table-cell">
                      <div className="px-2">
                        <p className="m-0">{cerebroSlot.startTime}</p>
                        <small className="text-muted">From</small>
                      </div>
                    </div>
                    <div className="wd-sm d-lg-table-cell">
                      <div className="px-2">
                        <p className="m-0">{cerebroSlot.endTime}</p>
                        <small className="text-muted">To</small>
                      </div>
                    </div>
                    <div className="wd-xs d-lg-table-cell">
                      <div className="px-2">
                        <p className="m-0 text-muted">
                          <em className="icon-eye mr-2 fa-lg" />
                          26
                        </p>
                      </div>
                    </div>
                    <div className="wd-xs d-lg-table-cell">
                      <div className="px-2">
                        <p className="m-0 text-muted">
                          <em className="fas fa-money-bill mr-2 fa-lg" />
                          3500
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
            <div>
              <Pagination innerClass="pagination float-right" activeClass="text-bold" prevPageText="<" nextPageText=">" itemClass="page-item" linkClass="page-link" activePage={this.state.activePage}
                itemsCountPerPage={this.state.itemsCountPerPage} totalItemsCount={this.state.totalItemsCount}
                pageRangeDisplayed={5} onChange={() => { }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}




























function Recommendations() {
  return (
    {/* <div className="row">
                      <div className="col-md-12">
                        <div className="card-body">
                          <div className="mt-3">
                            <OwlCarousel
                              loop
                              items={3}
                              margin={8}
                              autoplay={true}
                              nav
                              className="owl-theme"
                              navText={[
                                "<i class='fa fa-chevron-left' style=\"font-size:24px;\"/>",
                                "<i class='fa fa-chevron-right' style=\"font-size:24px\"/>",
                              ]}
                              animateOut={true}
                              animateIn={true}>
                              <div className="item">
                                <div className="row">
                                  <div className="col-md-4 align-self-center">
                                    <img
                                      className="img-thumbnail circle img-fluid thumb80 ml-auto"
                                      src="app/img/user/06.jpg"
                                      data-src="app/img/user/06.jpg"
                                      width="100%"
                                      height="auto"
                                      alt="d"
                                    />
                                  </div>
                                  <div className="col-md-8 align-self-center">
                                    <div className="ms-info">
                                      <p className="text-left mb-0">
                                        <em className="fa fa-quote-left" />
                                        &nbsp; &nbsp; Be successful. Build your
                                        websites with AMP. Find partners. And
                                        the presented AMP testimonials template
                                        will assist you. &nbsp;&nbsp;
                                        <em className="fa fa-quote-right" />
                                      </p>
                                      <div className="blockquote-footer">
                                        Jhon Doe
                                      </div>
                                      <div className="rate">
                                        <input
                                          type="radio"
                                          id="star51"
                                          name="rate"
                                          defaultValue={5}
                                        />
                                        <label htmlFor="star51" title="text">
                                          5 stars
                                        </label>
                                        <input
                                          type="radio"
                                          id="star41"
                                          name="rate"
                                          defaultValue={4}
                                        />
                                        <label htmlFor="star41" title="text">
                                          4 stars
                                        </label>
                                        <input
                                          type="radio"
                                          id="star31"
                                          name="rate"
                                          defaultValue={3}
                                        />
                                        <label htmlFor="star31" title="text">
                                          3 stars
                                        </label>
                                        <input
                                          type="radio"
                                          id="star21"
                                          name="rate"
                                          defaultChecked
                                          defaultValue={2}
                                        />
                                        <label htmlFor="star21" title="text">
                                          2 stars
                                        </label>
                                        <input
                                          type="radio"
                                          id="star11"
                                          name="rate"
                                          defaultValue={1}
                                        />
                                        <label htmlFor="star11" title="text">
                                          1 star
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="item">
                                <div className="row">
                                  <div className="col-md-4 align-self-center">
                                    <img
                                      className="img-thumbnail circle img-fluid thumb80 ml-auto"
                                      src="app/img/user/06.jpg"
                                      data-src="app/img/user/06.jpg"
                                      width="100%"
                                      height="auto"
                                      alt="d"
                                    />
                                  </div>
                                  <div className="col-md-8 align-self-center">
                                    <div className="ms-info">
                                      <p className="text-left mb-0">
                                        <em className="fa fa-quote-left" />
                                        &nbsp; &nbsp; Be successful. Build your
                                        websites with AMP. Find partners. And
                                        the presented AMP testimonials template
                                        will assist you. &nbsp;&nbsp;
                                        <em className="fa fa-quote-right" />
                                      </p>
                                      <div className="blockquote-footer">
                                        Jhon Doe
                                      </div>
                                      <div className="rate">
                                        <input
                                          type="radio"
                                          id="star52"
                                          name="rate"
                                          defaultValue={5}
                                        />
                                        <label htmlFor="star52" title="text">
                                          5 stars
                                        </label>
                                        <input
                                          type="radio"
                                          id="star42"
                                          name="rate"
                                          defaultChecked
                                          defaultValue={4}
                                        />
                                        <label htmlFor="star42" title="text">
                                          4 stars
                                        </label>
                                        <input
                                          type="radio"
                                          id="star32"
                                          name="rate"
                                          defaultValue={3}
                                        />
                                        <label htmlFor="star32" title="text">
                                          3 stars
                                        </label>
                                        <input
                                          type="radio"
                                          id="star22"
                                          name="rate"
                                          defaultValue={2}
                                        />
                                        <label htmlFor="star22" title="text">
                                          2 stars
                                        </label>
                                        <input
                                          type="radio"
                                          id="star12"
                                          name="rate"
                                          defaultValue={1}
                                        />
                                        <label htmlFor="star12" title="text">
                                          1 star
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="item">
                                <div className="row">
                                  <div className="col-md-4 align-self-center">
                                    <img
                                      className="img-thumbnail circle img-fluid thumb80 ml-auto"
                                      src="app/img/user/06.jpg"
                                      data-src="app/img/user/06.jpg"
                                      width="100%"
                                      height="auto"
                                      alt="d"
                                    />
                                  </div>
                                  <div className="col-md-8 align-self-center">
                                    <div className="ms-info">
                                      <p className="text-left mb-0">
                                        <em className="fa fa-quote-left" />
                                        &nbsp; &nbsp; Be successful. Build your
                                        websites with AMP. Find partners. And
                                        the presented AMP testimonials template
                                        will assist you. &nbsp;&nbsp;
                                        <em className="fa fa-quote-right" />
                                      </p>
                                      <div className="blockquote-footer">
                                        Jhon Doe
                                      </div>
                                      <div className="rate">
                                        <input
                                          type="radio"
                                          id="star53"
                                          name="rate"
                                          defaultValue={5}
                                        />
                                        <label htmlFor="star53" title="text">
                                          5 stars
                                        </label>
                                        <input
                                          type="radio"
                                          id="star43"
                                          name="rate"
                                          defaultValue={4}
                                        />
                                        <label htmlFor="star43" title="text">
                                          4 stars
                                        </label>
                                        <input
                                          type="radio"
                                          id="star33"
                                          name="rate"
                                          defaultChecked
                                          defaultValue={3}
                                        />
                                        <label htmlFor="star33" title="text">
                                          3 stars
                                        </label>
                                        <input
                                          type="radio"
                                          id="star23"
                                          name="rate"
                                          defaultValue={2}
                                        />
                                        <label htmlFor="star23" title="text">
                                          2 stars
                                        </label>
                                        <input
                                          type="radio"
                                          id="star13"
                                          name="rate"
                                          defaultValue={1}
                                        />
                                        <label htmlFor="star13" title="text">
                                          1 star
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="item">
                                <div className="row">
                                  <div className="col-md-4 align-self-center">
                                    <img
                                      className="img-thumbnail circle img-fluid thumb80 ml-auto"
                                      src="app/img/user/06.jpg"
                                      data-src="app/img/user/06.jpg"
                                      width="100%"
                                      height="auto"
                                      alt="d"
                                    />
                                  </div>
                                  <div className="col-md-8 align-self-center">
                                    <div className="ms-info">
                                      <p className="text-left mb-0">
                                        <em className="fa fa-quote-left" />
                                        &nbsp; &nbsp; Be successful. Build your
                                        websites with AMP. Find partners. And
                                        the presented AMP testimonials template
                                        will assist you. &nbsp;&nbsp;
                                        <em className="fa fa-quote-right" />
                                      </p>
                                      <div className="blockquote-footer">
                                        Jhon Doe
                                      </div>
                                      <div className="rate">
                                        <input
                                          type="radio"
                                          id="star54"
                                          name="rate"
                                          defaultValue={5}
                                        />
                                        <label htmlFor="star54" title="text">
                                          5 stars
                                        </label>
                                        <input
                                          type="radio"
                                          id="star44"
                                          name="rate"
                                          defaultValue={4}
                                        />
                                        <label htmlFor="star44" title="text">
                                          4 stars
                                        </label>
                                        <input
                                          type="radio"
                                          id="star34"
                                          name="rate"
                                          defaultChecked
                                          defaultValue={3}
                                        />
                                        <label htmlFor="star34" title="text">
                                          3 stars
                                        </label>
                                        <input
                                          type="radio"
                                          id="star24"
                                          name="rate"
                                          defaultValue={2}
                                        />
                                        <label htmlFor="star24" title="text">
                                          2 stars
                                        </label>
                                        <input
                                          type="radio"
                                          id="star14"
                                          name="rate"
                                          defaultValue={1}
                                        />
                                        <label htmlFor="star14" title="text">
                                          1 star
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </OwlCarousel>
                          </div>
                          <div
                            className="ms-staff-info text-center"
                            id="staff-info1"
                          />
                        </div>
                      </div>
                    </div> */}
  )
}