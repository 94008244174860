import React, { useState } from 'react'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { Constants } from '../../Common';


const PhoneNoInput = (props) => {

    const onChange = (value, data) => {
        props.onChange(value, data)
    }

    const getValue = () => {
        if (props.value) {
            return props.value;
        } else {
            return "+" + (props.dialCode ? props.dialCode : Constants.DEFAULT_COUNTRY_FOR_PHONE_NO.dialCode) + props.phoneNo;
        }
    }

    return (
        <div className='phone-no-input-wrapper' style={{ width: "100%" }}>
            <PhoneInput
                countryCodeEditable={props.countryCodeEditable ? props.countryCodeEditable : false}
                country={props.defaultCountry}
                enableSearch={props.enableSearch ? props.enableSearch : true}
                value={getValue()}
                onChange={(value, data, event, formattedValue) => {
                    onChange(value, data)
                }}
                autoFormat={props.autoFormat ? props.autoFormat : false}
            // masks={{ lk: '(...) ..-..-..' }}
            />
        </div>
    )
}

export default PhoneNoInput