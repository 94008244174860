import React, { Component } from "react";
import { NavLink, withRouter } from 'react-router-dom';
import UserDataService from "../api/UserDataService";
import { connect } from 'react-redux'
import { withAlert } from 'react-alert';

class UpdatePassword extends Component {
  constructor(props) {
    super()

  }

  state = {
    password: "",
    reTypePassword: ""
  }

  fieldOnChange(key, value) {
    this.setState({
      [key]: value
    })
  }

  updatePassword = () => {
    if (this.props.match.params.token === null) {
      this.props.alert.error("Token invalid.")
      return
    }
    if ((this.state.password.length === 0) && (this.state.reTypePassword.length === 0)) {
      this.props.alert.error("Password mismatch.")
      return
    }
    if (this.state.password !== this.state.reTypePassword) {
      this.props.alert.error("Password mismatch.")
      return
    }
    UserDataService.updatePassword({ password: this.state.password, token: this.props.match.params.token })
      .then(res => {
        if (res && res.status === 200) {
          this.props.alert.success(res.data.message, {
            onClose: () => {
              this.props.history.push('/login')
            }
          })
        }
      })
      .catch(e => {

      })
  }

  render() {
    return (
      <div className="wrapper">
        {/* Main section*/}
        <section className="section-container ml-0">
          {/* Page content*/}
          <div className="content-wrapper">
            <div className="content-heading">
              <div>
                Reset Password
                <small data-localize="dashboard.WELCOME" />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className=" card-default">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">

                      </div>
                      <div className="col-md-4 align-self-center">

                        <form className="mb-3" id="loginForm" noValidate>
                          <div className="form-group">
                            <label className="ml-3">Password</label>
                            <div className="input-group with-focus">
                              <input
                                className="form-control form-control-rounded border-right-0"
                                id="password"
                                type="password"
                                placeholder="Password"
                                autoComplete="off"
                                required
                                value={this.state.email}
                                onChange={(e) => this.fieldOnChange("password", e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="ml-3">Re Type Password</label>
                            <div className="input-group with-focus">
                              <input
                                className="form-control form-control-rounded border-right-0"
                                id="reTypePassword"
                                type="password"
                                placeholder="Password"
                                autoComplete="off"
                                required
                                value={this.state.email}
                                onChange={(e) => this.fieldOnChange("reTypePassword", e.target.value)}
                              />
                            </div>
                          </div>
                          <button
                            className="btn btn-block btn-primary mt-3 btn-oval"
                            type="button"
                            onClick={this.updatePassword}
                          >
                            RESET
                          </button>
                        </form>
                      </div>
                      <div className="col-md-4">

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Page footer*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
}

export default connect(mapStateToProps)(withRouter(withAlert()(UpdatePassword)));
