import React, { Component } from "react";
import { NavLink } from "react-router-dom";
class MyBookings extends Component {
  render() {
    return (
      <div>
        <section className="section-container ml-0">
          {/* Page content*/}
          <div className="content-wrapper">
            <div className="content-heading">
              <div>
                {" "}
                My Bookings
                <small data-localize="dashboard.WELCOME" />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className=" card-default">
                  <div className="row">
                    <div className="col-12">
                      <h4 className="mb-0 font-weight-light" style={{ height: "40px" }}> Due Bookings </h4>
                      <div className="list-group mb-3">
                        <div className="list-group-item">
                          <table className="wd-wide">
                            <tbody>
                              <tr>
                                <td>
                                  <div className="px-2">
                                    <h4 className="mb-2">23-08-2019</h4>
                                    <small className="text-muted"> Vestibulum ante ipsum </small>
                                  </div>
                                </td>
                                <td className="wd-sm d-lg-table-cell">
                                  <div className="px-2">
                                    <p className="m-0">8.00 am</p>
                                    <small className="text-muted">From</small>
                                  </div>
                                </td>
                                <td className="wd-sm d-lg-table-cell">
                                  <div className="px-2">
                                    <p className="m-0">12.00 pm</p>
                                    <small className="text-muted">To</small>
                                  </div>
                                </td>
                                <td className="wd-sm d-lg-table-cell">
                                  <div className="px-2">
                                    <p className="m-0">12.00 pm</p>
                                    <small className="text-muted"> Duration &amp; Cost </small>
                                  </div>
                                </td>
                                <td className="wd-lg">
                                  <div className="px-2">
                                    <img className="img-fluid rounded thumb64 float-left mr-2" src="app/img/dummy.png" alt="" />
                                    <p className="font-weight-bold pt-3 mb-0"> Pruthuvi Ellepola </p>
                                    <p className="text-muted mb-0">Engineer</p>
                                  </div>
                                </td>
                                <td className="wd-lg">
                                  <div className="px-2">
                                    <p className="font-weight-bold mb-0"> <span className="text-warning font-weight-bold text-md"> 0{" "} </span> Days <span className="text-warning font-weight-bold text-md"> 0{" "} </span> Hrs </p>
                                    <small className="text-muted mb-0"> Cerebro Time Starts Within </small>
                                  </div>
                                </td>
                                <td className="wd-xs text-center d-lg-table-cell">
                                  <div className="px-2">
                                    <NavLink className="nav-link" to="/video">
                                      <button className="btn btn-success btn-lg btn-circle animated pulse infinite"> <em className="fa fa-phone fa-lg" /> </button>
                                    </NavLink>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="list-group mb-3">
                        <div className="list-group-item bg-gray-lighter">
                          <table className="wd-wide">
                            <tbody>
                              <tr>
                                <td>
                                  <div className="px-2">
                                    <h4 className="mb-2">23-08-2019</h4>
                                    <small className="text-muted"> Vestibulum ante ipsum </small>
                                  </div>
                                </td>
                                <td className="wd-sm d-lg-table-cell">
                                  <div className="px-2">
                                    <p className="m-0">8.00 am</p>
                                    <small className="text-muted">From</small>
                                  </div>
                                </td>
                                <td className="wd-sm d-lg-table-cell">
                                  <div className="px-2">
                                    <p className="m-0">12.00 pm</p>
                                    <small className="text-muted">To</small>
                                  </div>
                                </td>
                                <td className="wd-sm d-lg-table-cell">
                                  <div className="px-2">
                                    <p className="m-0">12.00 pm</p>
                                    <small className="text-muted"> Duration &amp; Cost </small>
                                  </div>
                                </td>
                                <td className="wd-lg">
                                  <div className="px-2">
                                    <img className="img-fluid rounded thumb64 float-left mr-2" src="app/img/dummy.png" alt="" />
                                    <p className="font-weight-bold pt-3 mb-0"> Pruthuvi Ellepola </p>
                                    <p className="text-muted mb-0">Engineer</p>
                                  </div>
                                </td>
                                <td className="wd-lg">
                                  <div className="px-2">
                                    <p className="font-weight-bold mb-0">
                                      <span className="text-warning font-weight-bold text-md"> 2{" "} </span> Days
                                      <span className="text-warning font-weight-bold text-md"> 0{" "} </span> Hrs
                                    </p>
                                    <small className="text-muted mb-0"> Cerebro Time Starts Within </small>
                                  </div>
                                </td>
                                <td className="wd-xs text-center d-lg-table-cell">
                                  <div className="px-2">
                                    <button className="btn btn-success btn-lg btn-circle disabled"> <em className="fa fa-phone fa-lg" /> </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="list-group mb-3">
                        <div className="list-group-item bg-gray-lighter">
                          <table className="wd-wide">
                            <tbody>
                              <tr>
                                <td>
                                  <div className="px-2">
                                    <h4 className="mb-2">23-08-2019</h4>
                                    <small className="text-muted"> Vestibulum ante ipsum </small>
                                  </div>
                                </td>
                                <td className="wd-sm d-lg-table-cell">
                                  <div className="px-2">
                                    <p className="m-0">8.00 am</p>
                                    <small className="text-muted">From</small>
                                  </div>
                                </td>
                                <td className="wd-sm d-lg-table-cell">
                                  <div className="px-2">
                                    <p className="m-0">12.00 pm</p>
                                    <small className="text-muted">To</small>
                                  </div>
                                </td>
                                <td className="wd-sm d-lg-table-cell">
                                  <div className="px-2">
                                    <p className="m-0">12.00 pm</p>
                                    <small className="text-muted"> Duration &amp; Cost </small>
                                  </div>
                                </td>
                                <td className="wd-lg">
                                  <div className="px-2">
                                    <img className="img-fluid rounded thumb64 float-left mr-2" src="app/img/dummy.png" alt="" />
                                    <p className="font-weight-bold pt-3 mb-0"> Pruthuvi Ellepola </p>
                                    <p className="text-muted mb-0">Engineer</p>
                                  </div>
                                </td>
                                <td className="wd-lg">
                                  <div className="px-2">
                                    <p className="font-weight-bold mb-0"> <span className="text-warning font-weight-bold text-md"> 3{" "} </span> Days <span className="text-warning font-weight-bold text-md"> 0{" "} </span> Hrs </p>
                                    <small className="text-muted mb-0"> Cerebro Time Starts Within </small>
                                  </div>
                                </td>
                                <td className="wd-xs text-center d-lg-table-cell">
                                  <div className="px-2">
                                    <button className="btn btn-success btn-lg btn-circle disabled"> <em className="fa fa-phone fa-lg" /> </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className=" card-default">
                  <div className="row">
                    <div className="col-12">
                      <h4 className="mb-0 font-weight-light" style={{ height: "40px" }}> Previous Bookings </h4>
                      <div className="list-group mb-3">
                        <div className="list-group-item bg-gray-lighter">
                          <table className="wd-wide">
                            <tbody>
                              <tr>
                                <td>
                                  <div className="px-2">
                                    <h4 className="mb-2">23-08-2019</h4>
                                    <small className="text-muted"> Vestibulum ante ipsum </small>
                                  </div>
                                </td>
                                <td className="wd-sm d-lg-table-cell">
                                  <div className="px-2">
                                    <p className="m-0">8.00 am</p>
                                    <small className="text-muted">From</small>
                                  </div>
                                </td>
                                <td className="wd-sm d-lg-table-cell">
                                  <div className="px-2">
                                    <p className="m-0">12.00 pm</p>
                                    <small className="text-muted">To</small>
                                  </div>
                                </td>
                                <td className="wd-sm d-lg-table-cell">
                                  <div className="px-2">
                                    <p className="m-0">12.00 pm</p>
                                    <small className="text-muted"> Duration &amp; Cost </small>
                                  </div>
                                </td>
                                <td className="wd-lg">
                                  <div className="px-2">
                                    <img className="img-fluid rounded thumb64 float-left mr-2" src="app/img/dummy.png" alt="" />
                                    <p className="font-weight-bold pt-3 mb-0"> Pruthuvi Ellepola </p>
                                    <p className="text-muted mb-0">Engineer</p>
                                  </div>
                                </td>
                                <td className="wd-lg">
                                  <div className="px-2">
                                    <div className="rate">
                                      <input type="radio" id="star51" name="rate" defaultValue={5} />
                                      <label htmlFor="star51" title="text"> 5 stars </label>
                                      <input type="radio" id="star41" name="rate" defaultValue={4} />
                                      <label htmlFor="star41" title="text"> 4 stars </label>
                                      <input type="radio" id="star31" name="rate" defaultValue={3} />
                                      <label htmlFor="star31" title="text"> 3 stars </label>
                                      <input type="radio" id="star21" name="rate" defaultChecked defaultValue={2} />
                                      <label htmlFor="star21" title="text"> 2 stars </label>
                                      <input type="radio" id="star11" name="rate" defaultValue={1} />
                                      <label htmlFor="star11" title="text"> 1 star </label>
                                      <br />
                                      <p style={{ float: "center" }} className="text-muted mb-0"> Received Rates </p>
                                    </div>
                                  </div>
                                </td>
                                <td className="wd-xs text-center d-lg-table-cell"> <div className="px-2"></div> </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="list-group mb-3">
                        <div className="list-group-item bg-gray-lighter">
                          <table className="wd-wide">
                            <tbody>
                              <tr>
                                <td>
                                  <div className="px-2">
                                    <h4 className="mb-2">23-08-2019</h4>
                                    <small className="text-muted">
                                      Vestibulum ante ipsum
                                    </small>
                                  </div>
                                </td>
                                <td className="wd-sm d-lg-table-cell">
                                  <div className="px-2">
                                    <p className="m-0">8.00 am</p>
                                    <small className="text-muted">From</small>
                                  </div>
                                </td>
                                <td className="wd-sm d-lg-table-cell">
                                  <div className="px-2">
                                    <p className="m-0">12.00 pm</p>
                                    <small className="text-muted">To</small>
                                  </div>
                                </td>
                                <td className="wd-sm d-lg-table-cell">
                                  <div className="px-2">
                                    <p className="m-0">12.00 pm</p>
                                    <small className="text-muted">
                                      Duration &amp; Cost
                                    </small>
                                  </div>
                                </td>
                                <td className="wd-lg">
                                  <div className="px-2">
                                    <img
                                      className="img-fluid rounded thumb64 float-left mr-2"
                                      src="app/img/dummy.png"
                                      alt=""
                                    />
                                    <p className="font-weight-bold pt-3 mb-0">
                                      Pruthuvi Ellepola
                                    </p>
                                    <p className="text-muted mb-0">Engineer</p>
                                  </div>
                                </td>
                                <td className="wd-lg">
                                  <div className="px-2">
                                    <div className="rate">
                                      <input
                                        type="radio"
                                        id="star51"
                                        name="rate"
                                        defaultValue={5}
                                      />
                                      <label htmlFor="star51" title="text">
                                        5 stars
                                      </label>
                                      <input
                                        type="radio"
                                        id="star41"
                                        name="rate"
                                        defaultValue={4}
                                      />
                                      <label htmlFor="star41" title="text">
                                        4 stars
                                      </label>
                                      <input
                                        type="radio"
                                        id="star31"
                                        name="rate"
                                        defaultValue={3}
                                      />
                                      <label htmlFor="star31" title="text">
                                        3 stars
                                      </label>
                                      <input
                                        type="radio"
                                        id="star21"
                                        name="rate"
                                        defaultChecked
                                        defaultValue={2}
                                      />
                                      <label htmlFor="star21" title="text">
                                        2 stars
                                      </label>
                                      <input
                                        type="radio"
                                        id="star11"
                                        name="rate"
                                        defaultValue={1}
                                      />
                                      <label htmlFor="star11" title="text">
                                        1 star
                                      </label>
                                      <br />
                                      <p
                                        style={{ float: "center" }}
                                        className="text-muted mb-0">
                                        Received Rates
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td className="wd-xs text-center d-lg-table-cell">
                                  <div className="px-2"></div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default MyBookings;
