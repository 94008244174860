import React, { Component } from 'react';
import { Pagination as ReactPagination } from "react-bootstrap";
import { ChevronDoubleLeft, ChevronDoubleRight, ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import { Constants } from '../../Common';

class Pagination extends Component {


    state = {
        paginationArray: [],
        rows: Constants.DEFAULT_PAGE_INFO.rows
    }

    componentDidMount() {
        this.generatePaginationArray();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.totalNoOfPages && this.props.currentPageNo
            && (prevProps.currentPageNo != this.props.currentPageNo
                || prevProps.totalNoOfPages != this.props.totalNoOfPages)) {
            this.generatePaginationArray();
        }

        if (prevProps.totalNoOfPages !== 0 && this.props.totalNoOfPages === 0) {
            this.generatePaginationArray();
        }
    }

    generatePaginationArray = () => {
        let pagination = [];
        let currentPageNo = this.props.currentPageNo ? this.props.currentPageNo : 1;
        let totalNoOfPages = this.props.totalNoOfPages ? this.props.totalNoOfPages : 1;

        if (currentPageNo - 2 >= 1) {
            pagination.push(currentPageNo - 2);

        }

        if (currentPageNo - 1 >= 1) {
            pagination.push(currentPageNo - 1);
        }

        pagination.push(currentPageNo);

        if (currentPageNo + 1 <= totalNoOfPages) {
            pagination.push(currentPageNo + 1);
        }

        if (currentPageNo + 2 <= totalNoOfPages) {
            pagination.push(currentPageNo + 2);
        }

        this.setState({
            paginationArray: pagination
        });
    };


    onPaginationChange = (e) => {
        this.props.onPaginationChange({
            rows: this.state.rows,
            page: e
        });
    };


    render() {

        let currentPageNo = this.props.currentPageNo ? this.props.currentPageNo : 1;
        let totalNoOfPages = this.props.totalNoOfPages ? this.props.totalNoOfPages : 1;
        const { paginationArray } = this.state;

        return (
            <div className="cer-pagination">
                <ReactPagination
                    className={this.props.classNameForWrapper ? this.props.classNameForWrapper : ''}
                >
                    <ReactPagination.Item
                        key={"pg-first"}
                        onClick={() => this.onPaginationChange(1)}
                        disabled={currentPageNo === 1}
                    >
                        <ChevronDoubleLeft />
                    </ReactPagination.Item>
                    <ReactPagination.Item
                        key={"pg-previous"}
                        onClick={() => this.onPaginationChange(currentPageNo - 1)}
                        disabled={currentPageNo === 1}
                    >
                        <ChevronLeft />
                    </ReactPagination.Item>

                    {
                        this.state.paginationArray.map((item) => {
                            return (
                                <ReactPagination.Item
                                    key={item}
                                    active={item === currentPageNo}
                                    className='pagination-item'
                                    onClick={() => this.onPaginationChange(item)}
                                >
                                    {item}
                                </ReactPagination.Item>
                            )
                        })

                    }

                    <ReactPagination.Item
                        key={"pg-next"}
                        onClick={() => this.onPaginationChange(currentPageNo + 1)}
                        disabled={currentPageNo === totalNoOfPages}
                    >
                        <ChevronRight />
                    </ReactPagination.Item>
                    <ReactPagination.Item
                        key={"pg-last"}
                        onClick={() => this.onPaginationChange(totalNoOfPages)}
                        disabled={currentPageNo === totalNoOfPages}
                    >
                        <ChevronDoubleRight />
                    </ReactPagination.Item>

                </ReactPagination>
                <br />
            </div>
        )
    }
}

export default Pagination