import axios from "axios";
import { API_URL } from "../Constants.js";
import authHeader from "./AuthHeaders.js";

class InvoiceDataService {

    getInvoicesByConsultee(id) {
        return axios.get(`${API_URL}/invoice/consultee/${id}`, { headers: authHeader() })
    }

    payFromInvoiceId(id) {
        return axios.get(`${API_URL}/invoice/pay/${id}`, { headers: authHeader() })
    }

    cancelBookingByInvoiceId(id) {
        return axios.delete(`${API_URL}/invoice/${id}`, { headers: authHeader() })
    }

    getInvoiceList(params) {
        return axios.get(`${API_URL}/invoice/invoiceList`, { headers: authHeader(), params: params })
    }

    getPagedInvoiceList(data) {
        return axios.post(`${API_URL}/invoice/getPagedInvoiceList`, data, { headers: authHeader() })
    }
}

export default new InvoiceDataService();