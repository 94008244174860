import React, { Component } from "react";
import { withAlert } from "react-alert";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BookingDataService from "../api/BookingDataService";
import ConsultantDataService from "../api/ConsultantDataService";
import ConsulteeDataService from "../api/ConsulteeDataService";
import BookingsList from "./admin-dashboard/BookingsList";
import ConsultantList from "./admin-dashboard/ConsultantList";
import ConsulteeList from "./admin-dashboard/ConsulteeList";
import InvoiceSearchBase from "./admin-dashboard/Invoice/InvoiceSearchBase";
import ConsultantSearchBase from "./admin-dashboard/consultant/ConsultantSearchBase";
import { Constants } from "./Common";

class AdminDashboard extends Component {
  state = {
    consultants: [],
    consultees: [],
    upcomingBookingsList: [],
    previousBookingsList: [],
  };

  componentDidMount() {
    this.getAllConsultees();
    this.getUpcomingBookingsList();
    this.getPreviousBookingsList();
  }


  getAllConsultees() {
    ConsulteeDataService.retrieveAllConsultees()
      .then(res => {
        if (res && res.status === 200) {
          this.setState({
            consultees: res.data
          });
        }
      })
      .catch(e => { });
  }

  getUpcomingBookingsList() {
    BookingDataService.getAllUpcomingBookingsList()
      .then(res => {
        if (res && res.status === 200) {
          this.setState({
            upcomingBookingsList: res.data
          });
        }
      })
      .catch(e => { });
  }

  getPreviousBookingsList() {
    BookingDataService.getAllPreviousBookingsList()
      .then(res => {
        if (res && res.status === 200) {
          this.setState({
            previousBookingsList: res.data
          });
        }
      })
      .catch(e => { });
  }

  onSelectTab(key) {
    console.log(key)
  }

  render() {
    return (
      <div>
        <section className="section-container ml-0">
          <div className="content-wrapper">
            <div className="content-heading">
              <div>
                Admin Dashboard WIP...
                <small data-localize="dashboard.WELCOME" />
              </div>
            </div>
            {/* <div className="float-right">
              <button
                onClick={() => {
                  this.props.history.push("/searchconsultant/");
                }}
                type="button"
                className="btn btn-warning btn-lg text-bold fa-2x"
              >
                {" "}
                Book a Cerebroslot <em className="fas fa-plus fa-lg ml-1" />{" "}
              </button>
            </div> */}
            <br />
            <br />
            <Tabs onSelect={(selected) => this.onSelectTab(selected)} defaultActiveKey="consultant-list" transition={false} id="noanim-tab-example" >
              <Tab eventKey="consultant-list" title="Consultant List" style={{ textColor: "green" }}>
                <ConsultantSearchBase currentRoleView={Constants.ROLE_CONST.ADMIN} />
              </Tab>
              <Tab eventKey="consultee-list" title="Consultee List" style={{ textColor: "green" }}>
                <ConsulteeList consulteeList={this.state.consultees} />
              </Tab>
              <Tab eventKey="upcoming-bookings" title="Upcoming Bookings" style={{ textColor: "green" }}>
                <BookingsList bookingsList={this.state.upcomingBookingsList} />
              </Tab>
              <Tab eventKey="previous-bookings" title="Previous Bookings" style={{ textColor: "green" }}>
                <BookingsList bookingsList={this.state.previousBookingsList} />
              </Tab>
              <Tab eventKey="invoices" title="Invoices" style={{ textColor: "green" }}>
                <InvoiceSearchBase bookingsList={this.state.previousBookingsList} />
              </Tab>
            </Tabs>
          </div>
        </section>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    adminId: state.auth.adminId,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(withRouter(withAlert()(AdminDashboard)));