import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ConsultantCategoryDataService from '../api/ConsultantCategoryDataService';
import ConsultantSearch from './home/consultant-search/ConsultantSearch';

export class Home extends Component {

  state = {
    categories: []
  }

  componentDidMount() {
    ConsultantCategoryDataService.retrieveAllConsultantCategory()
      .then(res => {
        if (res && res.status === 200) {
          this.setState({ categories: res.data })
        }
      })
  }

  render() {
    return (
      <div>

        {/* Main section*/}
        <section className="section-container ml-0">
          {/* Page content*/}
          <div className="content-wrapper">
            <div className="row" style={{ height: '25rem' }}>
              <div className="col-12 text-center">
                <div className="parallax main-banner">
                  <div className="water main-banner3" />
                  <div className="water main-banner6" />
                  <div className="water main-banner5" />
                  <div className="water main-banner2" />
                  <div className="water main-banner1" />
                  <div className="water main-banner4" />
                </div>
                <div className="main-btn-group">
                  <NavLink className='text-gray-dark category' to='/signupconsultant'>
                    <div className="main-banner-button btn1 js-tilt">
                      <h3 className="main-button-heading-text">I want to be a Consultant </h3>
                      <span className="main-button-description-text">Our skill, your growth</span>
                    </div>
                  </NavLink>
                  <NavLink className='text-gray-dark category' to='/signupconsultee'>
                    <div className="main-banner-button btn2 js-tilt animated pulse">
                      <h3 className="main-button-heading-text">I want to be a Consultee </h3>
                      <span className="main-button-description-text">Your growth, Our happiness</span>
                    </div>
                  </NavLink>
                </div>
                <div className="clearfix" />
              </div>
            </div>
            <div className="row bg-white py-5 unwrap">
              {/* <div className="col-12">
                <div className="card-body bb mb-5 text-center">
                  <h2>Three Easy Steps</h2>
                </div>
              </div> */}
              <div className="col-md-3 offset-md-1 text-center align-self-center">
                <img className="thumb128" src="app/img/three-steps/booking.svg" alt="d" />
                <h4>Search List</h4>
                <p className="lead">Find any service within minutes. Search Faster.</p>
              </div>
              <div className="align-self-center d-none d-md-block d-lg-block d-xl-block">
                <em className="fas fa-arrow-right fa-4x w-100 mb-3" />
              </div>
              <div className="d-md-none w-100 text-center">
                <em className="fas fa-arrow-down fa-4x w-100 mb-3" />
              </div>
              <div className="col-md-3 text-center align-self-center">
                <img className="thumb128" src="app/img/three-steps/search_wctu.svg" alt="d" />
                <h4>Get An Appointment</h4>
                <p className="lead">
                  Fix appointment at your convenience.</p>
              </div>
              <div className="align-self-center d-none d-md-block d-lg-block d-xl-block">
                <em className="fas fa-arrow-right fa-4x w-100 mb-3" />
              </div>
              <div className="d-md-none w-100 text-center">
                <em className="fas fa-arrow-down fa-4x w-100 mb-3" />
              </div>
              <div className="col-md-3 text-center align-self-center">
                <img className="thumb128" src="app/img/three-steps/video_call.svg" alt="d" />
                <h4>Get Connected</h4>
                <p className="lead">
                  Talk to your specialist real-time and get solutions</p>
              </div>
            </div>
            <div className="row bg-white py-5 mt-2 unwrap" id="search-consultant">
              <div className="col-md-10 offset-md-1">
                <div className="row">
                  {/* <div className="col-12">
                    <div className="card-body bb mb-5 text-center">
                      <h2>Pick you consultant here</h2>
                    </div>
                  </div> */}
                  <div className="col-md-12">
                    <div className="input-group with-focus">
                      <ConsultantSearch />
                    </div>
                    <br />
                  </div>
                  {
                    this.state.categories.map(item => {
                      return (
                        <div key={item.id} className="col-xl-2 col-lg-3 col-sm-4">
                          <NavLink
                            className='text-gray-dark category'
                            to={{
                              pathname: `/searchconsultant/${item.id}`
                            }}>
                            <div className="card bg-light category">
                              <div className="card-body  text-center">
                                <div className="text-lg m-0">
                                  <img className="thumb128" src={`app/img/category/${item.thumbnail}.svg`} alt="d" />
                                </div>
                                <p>{item.name}</p>
                              </div>
                            </div>
                          </NavLink>
                        </div>
                      )
                    })
                  }
                  {/*repeat this*/}
                  {/*                        <div class="col-xl-2 col-lg-3 col-md-4">*/}
                  {/*                            &lt;!&ndash; START card&ndash;&gt;*/}
                  {/*                            <div class="card bg-light category">*/}
                  {/*                                <div class="card-body text-center">*/}
                  {/*                                    <div class="text-lg m-0">*/}
                  {/*                                        <img class="thumb128" src="app/img/category/dietician.svg" alt="d">*/}
                  {/*                                    </div>*/}
                  {/*                                    <p>Dietician</p>*/}
                  {/*                                </div>*/}
                  {/*                                &lt;!&ndash; END card&ndash;&gt;*/}
                  {/*                            </div>*/}
                  {/*                        </div>*/}
                  {/*repeat end*/}
                </div>
              </div>
            </div>
            <div className="row my-5">
              <div className="col-12">
              </div>
              <div className="col-lg-4 offset-lg-1 align-self-center">
                <img className="w-100" src="app/img/stop.svg" alt="d" />
              </div>
              <div className="col-lg-6">
                <div className="card-body bb">
                  <h2 className="text-center">We Are Your One-Stop Real-Time Online</h2>
                </div>
                <div className="card-body text-center">
                  <p className="lead">Service Consultant</p>
                  <p>We provide you the most convenient real-time online consultations. Get your consultants
                  booked
              faster and easier from anywhere anytime and get that solution up front.</p>
                  <p>If you are that excellent professional consultant, join our team of specialist by simply
                  logging
                  in
                  and find your client base at a nick of time. Let us be that one-futuristic service
                  advocator
              bringing you virtually close.</p>
                  <p className="lead">Safe Information</p>
                  <p>We value your privacy, your data is secured at all times and is granted never to be
                  shared
                  with
                  a
              third party.</p>
                  <p className="lead">Safe Payment</p>
                  <p>100% payment protection guaranteed for service you purchase. All transactions are
                  conducted
                  on
                  Sampath Payment gateway platform. Whether a Client uses debit/credit card, or other form
                  of
              payment,</p>
                  <p>
                    we handle everything, and ensure security of your payment.</p>
                  <p className="lead">Secure Communication</p>
                  <p>You can safely communicate with <span className="text-warning">CEREBROZONE</span> thorough
              our
              secure messaging system consultant.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Page footer*/}

      </div>

    )
  }
}

export default Home