import React, { Component } from 'react'
import { withAlert } from 'react-alert'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom/cjs/react-router-dom'
import InvoiceDataService from '../../../api/InvoiceDataService'
import PaymentDataService from '../../../api/PaymentDataService'
import { HIDE_LOADING, SHOW_LOADING } from '../../../redux/actionTypes'
import InvoiceResult from './InvoiceResult'
import InvoiceReviewModel from './InvoiceReviewModel'
import InvoiceSearch from './InvoiceSearch'
import { Constants } from '../../Common'

class InvoiceSearchBase extends Component {

    DEFAULT_SEARCH_VALUES = {
        startDate: '',
        endDate: '',
        status: 'PENDING_APPROVAL',
        withBookingDetails: true
    }


    STATUS_LIST = [
        { value: "PENDING_APPROVAL", name: "PENDING_APPROVAL" },
        { value: "SUCCESS_PAYMENT", name: "APPROVED" },
        { value: "FAILED_PAYMENT", name: "REJECTED" },
    ]


    state = {
        pageInfo: Constants.DEFAULT_PAGE_INFO,
        invoiceList: [],
        searchData: this.DEFAULT_SEARCH_VALUES,
        showModal: false,
        selectedInvoice: null,
        adminRemark: '',
        pagedInvoiceData: {}
    }


    setSearchData = (name, value) => {
        this.setState({
            ...this.state,
            searchData: {
                ...this.state.searchData,
                [name]: value
            }
        })
    }

    resetSearchData = () => {
        this.setState({
            ...this.state,
            pageInfo: Constants.DEFAULT_PAGE_INFO,
            searchData: this.DEFAULT_SEARCH_VALUES
        })
    }

    componentDidMount() {
        this.onSearch();
    }

    onSearch = () => {
        this.props.dispatch({ type: SHOW_LOADING });
        const searchData = {
            ...this.state.pageInfo,
            ...this.state.searchData
        }
        InvoiceDataService.getPagedInvoiceList(searchData)
            .then(res => {
                if (res && res.status === 200) {
                    this.setState({
                        ...this.state,
                        pagedInvoiceData: res.data
                    });
                }
                this.props.dispatch({ type: HIDE_LOADING });
            }).catch(e => {
                this.props.dispatch({ type: HIDE_LOADING });
                this.props.alert.error("Failed to get invoice list. Please try again.");
            });
    }

    onSearchClick = () => {
        this.setState({
            ...this.state,
            pageInfo: Constants.DEFAULT_PAGE_INFO
        }, () => this.onSearch())
    }

    onInvoiceItemClick = (invoice) => {
        this.setState({
            ...this.state,
            selectedInvoice: invoice,
            adminRemark: invoice.adminRemark ? invoice.adminRemark : '',
            showModal: true
        })
    }

    onCloseClick = () => {
        this.setState({
            ...this.state,
            selectedInvoice: null,
            adminRemark: '',
            showModal: false
        })
    }

    onUpdateClick = (status) => {
        this.props.dispatch({ type: SHOW_LOADING });
        const data = {
            invoiceId: this.state.selectedInvoice.invoiceId,
            invoiceStatus: status,
            adminRemark: this.state.adminRemark
        }
        PaymentDataService.updatePayment(data)
            .then(res => {
                if (res && res.status === 200) {
                    this.onCloseClick()
                    this.onSearch();
                    this.props.alert.success("Invoice updated successfully.");
                }
                this.props.dispatch({ type: HIDE_LOADING });
            }).catch(e => {
                this.props.dispatch({ type: HIDE_LOADING });
                this.props.alert.error("Failed to update invoice status.");
            });
    }

    onUpdateValue = (field, value) => {
        this.setState({
            ...this.state,
            [field]: value
        })
    }

    onPageInfoChange = (pageInfo) => {
        this.setState({
            ...this.state,
            pageInfo: pageInfo
        }, () => this.onSearch())
    }


    render() {

        const { showModal, selectedInvoice, adminRemark, pagedInvoiceData,searchData } = this.state;
        const invoiceList = this.state.pagedInvoiceData.pageData ? this.state.pagedInvoiceData.pageData : [];

        return (
            <>

                <InvoiceSearch
                    searchData={searchData}
                    setSearchData={this.setSearchData}
                    resetSearchData={this.resetSearchData}
                    onSearch={this.onSearchClick}
                    statusList={this.STATUS_LIST}
                />
                <InvoiceResult
                    invoiceList={invoiceList}
                    onItemClick={this.onInvoiceItemClick}
                    statusList={this.STATUS_LIST}
                    pagedInvoiceData={pagedInvoiceData}
                    onPageInfoChange={this.onPageInfoChange}
                />
                {
                    showModal &&
                    <InvoiceReviewModel
                        show={showModal}
                        onCloseClick={this.onCloseClick}
                        invoice={selectedInvoice}
                        onUpdateClick={this.onUpdateClick}
                        onUpdateValue={this.onUpdateValue}
                        adminRemark={adminRemark}
                        statusList={this.STATUS_LIST}
                    />
                }
            </>
        )
    }
}

export default connect()(withRouter(withAlert()(InvoiceSearchBase)));


