import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./redux/store";
import { transitions, positions, Provider as AlertProvider } from "react-alert";

const options = {
  timeout: 4000,
  position: positions.TOP_RIGHT,
  transition: transitions.FADE,
  offset: "50px",
  containerStyle: {
    zIndex: 10000000
  }
};

const AlertTemplate = ({ style, options, message, close }) => (
  <div
    style={{
      backgroundColor:
        options.type === "success"
          ? "#27c24c"
          : options.type === "error"
          ? "#f05050"
          : "#ff902b",
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 10,
      paddingBottom: 10,
      color: "white",
      width: 300,
      fontFamily: "tahoma",
      fontSize: 15,
      borderRadius: 10,
      marginRight: 10,
      marginTop: 10,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    }}
  >
    <p>{message}</p>
  </div>
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AlertProvider template={AlertTemplate} {...options}>
        <App />
      </AlertProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
