import React, { Component } from 'react';
import { NavLink } from "react-router-dom";


class MyAppoinments extends Component {

    availabilityCheck() {
        return (<div className="list-group mb-3">
            <div className="list-group-item">
                <table className="wd-wide">
                    <tbody>
                        <tr>
                            <td>
                                <div className="p-4 text-center" >
                                    {/*<NavLink className='nav-link' to='/searchconsultant' style={{cursor:"pointer"}}>*/}
                                    <h3 className="mb-0 font-weight-light">Currently You
                                    Dont Have Any Appointment.
                                                    {/* <a href='/searchconsultant' style={{ color: "#FF0000" }}> Create your slot now.</a> */}
                                    </h3>
                                    {/*</NavLink>*/}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>);
    }


    render() {
        return (
            <div>
                <section className="section-container ml-0">
                    {/* Page content*/}
                    <div className="content-wrapper">
                        <div className="content-heading">
                            <div> My Appointment Dashboard
                                <small data-localize="dashboard.WELCOME" />
                            </div>
                        </div>
                        {/* {this.availabilityCheck()} */}
                        {/* {this.dueAppointments()} */}
                        {/* {this.previousAppointments()} */}
                    </div>
                </section>
            </div>
        );
    }
}

export default MyAppoinments;