import React from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { Constants } from '../../Common';
import DetailField from '../../common/container/DetailField';
import TDField from '../../common/container/TDField';
import { getNumberWithUnitsForGridView } from '../../../utils/GridUtil';

function InvoiceReviewModel(props) {

    const trStyle = { height: "20px" };
    const P_STYLE = { wordWrap: "break-word" };

    const { show, invoice, adminRemark, statusList } = props;
    const { onCloseClick, onUpdateClick, onUpdateValue } = props;

    const hasBookingData = invoice && invoice.bookingList && invoice.bookingList.length > 0;

    const statusMap = statusList.reduce((map, item) => {
        map[item.value] = item.name;
        return map;
    }, {});

    const downloadImage = async () => {
        try {
            const url = invoice.imageUrl;
            const response = await fetch(url);
            const blob = await response.blob();
            const objectUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = objectUrl;
            link.download = `${invoice.invoiceId}_payment_slip.jpg`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (e) {
            console.error(e);
        }
    };

    const isEditable = invoice.invoiceStatus === Constants.INVOICE_STATUS_CONST.PENDING_APPROVAL;

    return (
        <Modal
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton onHide={props.onCloseClick}>
                <h4>View Invoice</h4>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    {
                        invoice.imageUrl &&
                        <Row className='mb-1'>
                            <Col md={12}>
                                <Button onClick={downloadImage} className='mb-1'>Download</Button>
                                <div style={{ overflow: 'auto', maxHeight: '250px' }}>
                                    <img src={invoice.imageUrl} height={"250px"} />
                                </div>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col md={6}>
                            <div className=" card-default">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="list-group mb-3">
                                            <div className="list-group-item" style={{ width: "100%", height: "180px", overflowY: "auto", overflowX: "hidden" }}>
                                                <DetailField name={"Consultant Name"} value={invoice.consultantName} />
                                                <DetailField name={"Consultee Name"} value={invoice.consulteeName} />
                                                <DetailField name={"Invoice Date"} value={invoice.invoiceDate} />
                                                <DetailField name={"Payment Method"} value={invoice.paymentMethod} />
                                                <DetailField name={"Total Amount"} value={getNumberWithUnitsForGridView(invoice.totalAmount, Constants.CURRENCY_CONST.LKR)} />
                                                <DetailField name={"Cerebrozone Amount"} value={getNumberWithUnitsForGridView(invoice.cerebrozoneAmount, Constants.CURRENCY_CONST.LKR)} />
                                                <DetailField name={"Consultant Remark"} value={invoice.consultantRemark} />
                                                <DetailField name={"Status"} value={Constants.INVOICE_STATUS[statusMap[invoice.invoiceStatus]]} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} >
                            <div className=" card-default" >
                                <div className="row">
                                    <div className="col-12">
                                        <div className="list-group mb-3">
                                            <div className="list-group-item" style={{ width: "100%", height: "180px", overflowY: "auto" }}>
                                                {hasBookingData &&
                                                    <table className="wd-wide">
                                                        <thead>
                                                            <tr style={{ backgroundColor: "#e9e9e9" }}>
                                                                <th>#</th>
                                                                <th>Booking Date</th>
                                                                <th>Start Time</th>
                                                                <th>End Time</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                invoice.bookingList.map((booking, index) => {
                                                                    return (
                                                                        <tr style={trStyle} key={index} className='clickable-row'>
                                                                            <TDField value={index + 1} />
                                                                            <TDField value={booking.cerebroslotDate} />
                                                                            <TDField value={booking.startTime} />
                                                                            <TDField value={booking.endTime} />
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <textarea style={{ width: '100%' }} placeholder=' Add approval comment here' value={adminRemark}
                                onChange={(e) => onUpdateValue("adminRemark", e.target.value)} disabled={!isEditable} />
                        </Col>
                    </Row>
                </Container>

            </Modal.Body>
            <Modal.Footer>
                {
                    isEditable &&
                    <>
                        <Button onClick={() => onUpdateClick(Constants.INVOICE_STATUS_CONST.APPROVED)} variant='success'>Approve</Button>
                        <Button onClick={() => onUpdateClick(Constants.INVOICE_STATUS_CONST.REJECTED)} variant='danger'>Reject</Button>
                    </>
                }
                <Button onClick={onCloseClick} variant='white'>Close</Button>
            </Modal.Footer>
        </Modal>

    )
}

export default InvoiceReviewModel