import React, { Component } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead';

class ConsultantSearch extends Component {

    state = {
        selected: '',
        allOptionList: [
            "Amila Subasinghe", 
            "Danika Sumanadasa", 
            "Saman Somasiri"
        ],
        options: [],
        isLoading: false
    }



    onItemClick = (item) => {
        console.log(item);
    }

    onInputChange = (input) => {
        let filteredOptions = [];
        if (input) {
            filteredOptions = this.state.allOptionList.filter((item) => item.toLowerCase().indexOf(input.toLowerCase()) > -1);
            console.log(filteredOptions)
        }

        this.setState({ ...this.state, isLoading: true, options: [] }, () => {

            setTimeout(() => {
                if (input && filteredOptions.length !== 0) {
                    this.setState({ ...this.state, options: filteredOptions, isLoading: false })
                } else {
                    this.setState({ ...this.state, options: [], isLoading: false })
                }
            }, 2000)
        })

    }



    render() {

        let wrapperStyle = {
            position: "relative",
            display: "inline-block",
        }

        return (
            <div className='w-100' style={wrapperStyle}>
                <Typeahead
                    id="basic-typeahead-single"
                    className='consultant-search-input-wrapper'
                    labelKey="name"
                    onChange={this.onItemClick}
                    onInputChange={this.onInputChange}
                    options={this.state.options}
                    placeholder="Type consultant name here..."
                    selected={this.state.selected}
                />
                {this.state.isLoading && (<span class="text-filed-spinner"></span>)}

            </div>
        );
    }
}

export default ConsultantSearch