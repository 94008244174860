import axios from "axios";
import { API_URL } from "../Constants.js";
import { CONSULTEE } from '../Constants'
import authHeader from "./AuthHeaders.js";

class ConsulteeDataService {
  retrieveAllConsultees() {
    return axios.get(`${API_URL}/consultee`, { headers: authHeader() });
  }

  retrieveConsultee(id) {
    return axios.get(`${API_URL}/consultee/${id}`, { headers: authHeader() });
  }

  deleteConsultee(id) {
    return axios.delete(`${API_URL}/consultee/${id}`, { headers: authHeader() });
  }

  updateConsultee(id, Consultee) {
    return axios.put(`${API_URL}/consultee/${id}`, Consultee, { headers: authHeader() });
  }

  createConsultee(Consultee) {
    return axios.post(`${API_URL}/consultee/`, Consultee, { headers: authHeader() });
  }

  getConsultantIdFromStorage() {
    return localStorage.getItem(CONSULTEE.toLowerCase());
  }

  saveIdToStorage(id) {
    localStorage.setItem(CONSULTEE.toLowerCase(), id);
  }

  uploadPaymentData(params) {
    return axios.post(`${API_URL}/upload/slip`, params, { headers: authHeader() });
  }
}

export default new ConsulteeDataService();
