import React, { Component } from "react";
import ConsulteeDataService from "../api/ConsulteeDataService";
import { withRouter } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import UserDataService from "../api/UserDataService";
import { Constants, timezones } from "./Common";
import {
  HIDE_LOADING,
  SET_CONSULTEE,
  SHOW_LOADING
} from "../redux/actionTypes";
import { connect } from "react-redux";
import { withAlert } from "react-alert";
import axios from "axios";
import { API_URL } from "../Constants";
import { InputField } from "./common/input/InputField";
import DatePicker from "./common/datepicker/DatePicker";
import PhoneNoInput from "./common/input/PhoneNoInput";

class ConsulteeForm extends Component {
  constructor() {
    super();
    this.state = {
      firstName: "",
      lastName: "",
      displayName: "",
      birthday: "",
      gender: "",
      country: "Sri Lanka",
      timeZone: "5.5",
      currency: "LKR",
      email: "",
      dialCode:"",
      phone: "",
      succuss: false,
      errors: {},
      show: false,
      selectedFile: null,
      imageName: ""
    };

    this.save = this.save.bind(this);
    this.validate = this.validate.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  componentDidMount() {
    if (
      this.props.consulteeId !== null &&
      this.props.consulteeId !== undefined
    ) {
      ConsulteeDataService.retrieveConsultee(this.props.consulteeId).then(
        res => {
          if (res && res.status === 200 && res.data) {
            const data = res.data;
            this.props.dispatch({ type: SET_CONSULTEE, payload: res.data });
            this.setState({
              firstName: data.firstName,
              lastName: data.lastName,
              displayName: data.displayName,
              birthday: data.birthday ? data.birthday : "",
              gender: data.gender,
              country: data.country,
              timeZone: data.timeZone,
              // email: data.email,
              dialCode: data.dialCode ? data.dialCode : Constants.DEFAULT_COUNTRY_FOR_PHONE_NO.dialCode,
              phone: data.phone ? data.phone : "",
              imageName: data.imageName
            });
          }
        }
      );
    }
  }

  onFileChange = event => {
    this.setState({
      selectedFile: event.target.files[0]
    });
  }

  uploadFile(callback, context) {
    if (this.state.selectedFile) {
      console.log(this.state.selectedFile)
      let formData = new FormData();
      formData.append('image', this.state.selectedFile, this.state.selectedFile.name);

      axios.post(
        `${API_URL}/upload/image`,
        formData,
      )
        .then(res => {
          callback(res.data, context)
        })
        .catch(err => {
          console.log(err);
        })
    } else {
      callback(null, context)
    }
  }

  save() {
    if (
      this.state.firstName !== "" &&
      this.state.lastName !== "" &&
      this.state.birthday !== "" &&
      this.state.gender !== "" &&
      // this.state.email !== "" &&
      this.state.phone !== ""
    ) {
      let Consultee = {
        userId: UserDataService.getLoggedInUserId(),
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        displayName: this.state.displayName ? this.state.displayName : (this.state.firstName + " " + this.state.lastName),
        birthday: this.state.birthday,
        gender: this.state.gender,
        country: this.state.country,
        timeZone: this.state.timeZone,
        currency: this.state.currency,
        // email: this.state.email,
        dialCode: this.state.dialCode,
        phone: this.state.phone
      };
      this.setState({ ...this.state, errors: {} })
      this.props.dispatch({ type: SHOW_LOADING });

      this.uploadFile(function (image, context) {
        if (context.props.consulteeId !== null) {
          if (image) {
            Consultee['imageName'] = image
          } else {
            Consultee['imageName'] = context.state.imageName
          }
          ConsulteeDataService.updateConsultee(context.props.consulteeId, Consultee)
            .then(res => {
              context.props.dispatch({ type: HIDE_LOADING });
              if (res && res.status === 200 && res.data !== "") {
                context.props.dispatch({ type: SET_CONSULTEE, payload: res.data });
                UserDataService.registerSuccessfulLogin(context.props.user);
                context.props.alert.success("Update successful.");
              }
            })
            .catch(e => {
              context.props.dispatch({ type: HIDE_LOADING });
            });
        } else {
          if (image) {
            Consultee['imageName'] = image
          } else {
            Consultee['imageName'] = ''
          }
          ConsulteeDataService.createConsultee(Consultee)
            .then(res => {
              context.props.dispatch({ type: HIDE_LOADING });
              context.validate();
              if (res && res.status === 200 && res.data !== "") {
                context.props.dispatch({ type: SET_CONSULTEE, payload: res.data });
                UserDataService.registerSuccessfulLogin(context.props.user);
                context.props.history.push("/consulteedashboard");
              }
            })
            .catch(e => {
              context.props.dispatch({ type: HIDE_LOADING });
              console.log(e);
            });
        }
      }, this)
    } else {
      this.validate();
    }
  }

  validate() {
    let oldErrors = {};

    this.setState({ errors: oldErrors });

    let errors = {};

    if (!this.state.firstName) {
      errors.firstName = "Cannot leave First Name empty ";
    }
    if (!this.state.lastName) {
      errors.lastName = "Cannot leave Last Name empty";
    }
    if (!this.state.birthday) {
      errors.birthday = "Cannot leave birthday empty";
    }
    if (!this.state.gender) {
      errors.gender = "Please select your gender";
    }

    // if (!this.state.email) {
    //   errors.email = "Cannot leave E-mail empty";
    // }
    console.log(this.state.phone)
    if (!this.state.phone) {
      console.log(this.state.phone)
      errors.phone = "Cannot leave Phone empty";
    }
    console.log(this.state.phone)


    this.setState({ errors: errors });
  }

  cancel = () => {
    this.props.history.push("/consulteedashboard");
  };

  render() {
    return (
      <div>
        <section className="section-container ml-0">
          {/* Page content*/}
          <div className="content-wrapper">
            <div className="content-heading">
              <div>
                Consultee Profile
                <small data-localize="dashboard.WELCOME" />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className=" card" style={{ overflow: "unset" }}>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-10 offset-md-1 align-self-center">
                        <h3 className="text-center mb-3">Consultee Profile</h3>
                        <p className="text-center mb-3">
                          Enter Your Details to Sign Up
                        </p>
                        <Alert
                          show={this.state.show}
                          variant="success"
                          onClose={() => this.setState({ show: false })}
                          dismissible
                        >
                          <p style={{ aligntText: "center", width: "100%" }}>
                            Your Profile Updated Successfully!!!
                          </p>
                        </Alert>
                        <form className="mb-3" id="loginForm" noValidate>
                          <div className="row">
                            <div className="col-md-4 col-lg-3">
                              <div className="form-group">
                                <label className="ml-3">First Name</label>
                                <div className="input-group with-focus">
                                  <InputField
                                    value={this.state.firstName}
                                    pattern={Constants.INPUT_PATTERN.LETTERS_AND_SPACES_ONLY}
                                    onChange={e =>
                                      this.setState({
                                        ...this.state,
                                        firstName: e.target.value,
                                      })
                                    }
                                    placeholder={"Enter First Name"}
                                    className={"form-control form-control-rounded"}
                                    autoComplete={"off"}
                                    required={true}
                                  />
                                </div>
                                <p style={{ color: "#FF0000" }}>
                                  {this.state.errors.firstName}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                              <div className="form-group">
                                <label className="ml-3">Last Name</label>
                                <div className="input-group with-focus">
                                  <InputField
                                    value={this.state.lastName}
                                    pattern={Constants.INPUT_PATTERN.LETTERS_AND_SPACES_ONLY}
                                    onChange={e =>
                                      this.setState({
                                        ...this.state,
                                        lastName: e.target.value
                                      })
                                    }
                                    placeholder={"Enter Last Name"}
                                    className={"form-control form-control-rounded"}
                                    autoComplete={"off"}
                                    required={true}
                                  />
                                </div>
                                <p style={{ color: "#FF0000" }}>
                                  {this.state.errors.lastName}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                              <div className="form-group">
                                <label className="ml-3">Display Name</label>
                                <div className="input-group with-focus">
                                  <InputField
                                    value={this.state.displayName}
                                    pattern={Constants.INPUT_PATTERN.LETTERS_AND_SPACES_ONLY}
                                    onChange={e =>
                                      this.setState({
                                        ...this.state,
                                        displayName: e.target.value
                                      })
                                    }
                                    className={"form-control form-control-rounded"}
                                    autoComplete={"off"}
                                    required={true}
                                    placeholder={this.state.firstName+" "+this.state.lastName}
                                  />
                                </div>
                                <p style={{ color: "#FF0000" }}>
                                  {this.state.errors.displayName}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                              <div className="form-group">
                                <label className="ml-3">Birthday</label>
                                <div className="input-group with-focus">
                                  <DatePicker
                                    value={this.state.birthday}
                                    onChange={e =>
                                      this.setState({ 
                                        ...this.state,
                                        birthday: e.target.value 
                                      })
                                    }
                                    className={"form-control form-control-rounded"}
                                    autoComplete={"off"}
                                    required={true}
                                  />
                                </div>
                                <p style={{ color: "#FF0000" }}>
                                  {this.state.errors.birthday}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                              <div className="form-group">
                                <label className="ml-3">Gender</label>
                                <div className="input-group with-focus">
                                  <div className="form-check form-check-inline ml-3">
                                    <input
                                      onChange={() =>
                                        this.setState({ gender: "Male" })
                                      }
                                      className="form-check-input"
                                      id="inlineRadio1"
                                      type="radio"
                                      name="inlineRadioOptions"
                                      defaultValue="option1"
                                      checked={this.state.gender === "Male"}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="inlineRadio1"
                                    >
                                      Male
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      onChange={() =>
                                        this.setState({ gender: "Female" })
                                      }
                                      className="form-check-input"
                                      id="inlineRadio2"
                                      type="radio"
                                      name="inlineRadioOptions"
                                      defaultValue="option1"
                                      checked={this.state.gender === "Female"}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="inlineRadio1"
                                    >
                                      Female
                                    </label>
                                  </div>
                                </div>
                                <p style={{ color: "#FF0000" }}>
                                  {this.state.errors.gender}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                              <div className="form-group">
                                <label className="ml-3">Country</label>
                                <div className="input-group with-focus">
                                  <select
                                    className="form-control form-control-rounded"
                                    onChange={e =>
                                      this.setState({ country: e.target.value })
                                    }
                                    defaultValue={this.state.country}
                                  >
                                    <option value={"Sri Lanka"}>
                                      Sri Lanka
                                    </option>
                                    <option value={"India"}>India</option>
                                    <option value={"United States"}>
                                      United States
                                    </option>
                                    <option value={"United Kingdom"}>
                                      United Kingdom
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                              <div className="form-group">
                                <label className="ml-3">Time Zone</label>
                                <div className="input-group with-focus">
                                  <select
                                    onChange={e =>
                                      this.setState({
                                        timeZone: e.target.value
                                      })
                                    }
                                    defaultValue={this.state.timeZone}
                                    className="form-control form-control-rounded"
                                  >
                                    {timezones.reverse().map(item => {
                                      return (
                                        <option
                                          key={item.text}
                                          value={item.offset}
                                        >
                                          {item.text}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                              <div className="form-group">
                                <label className="ml-3">Currency</label>
                                <div className="input-group with-focus">
                                  <select
                                    className="form-control form-control-rounded"
                                    onChange={e =>
                                      this.setState({
                                        currency: e.target.value
                                      })
                                    }
                                    defaultValue={this.state.currency}
                                  >
                                    <option value={"LKR"}>LKR</option>
                                    {/* <option value={"USD"}>USD</option>
                                    <option value={"EUR"}>EUR</option> */}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                              <div className="form-group">
                                <label className="ml-3">Email</label>
                                <div className="input-group with-focus">
                                  <input
                                    value={this.props.email}
                                    className="form-control form-control-rounded"
                                    type="email"
                                    autoComplete="off"
                                    // required
                                    disabled
                                  />
                                </div>
                                <p style={{ color: "#FF0000" }}>
                                  {this.state.errors.email}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                              <div className="form-group">
                                <label className="ml-3">Phone No</label>
                                <div className="input-group with-focus">
                                  <PhoneNoInput
                                    dialCode={this.state.dialCode}
                                    phoneNo={this.state.phone}
                                    onChange={(value, data) => {
                                      this.setState({
                                        ...this.state,
                                        dialCode: data.dialCode,
                                        phone: value ? value.slice(data.dialCode.length) : ''
                                      });
                                    }}
                                  />
                                </div>
                                <p style={{ color: "#FF0000" }}>
                                  {this.state.errors.phone}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                              <div className="form-group">
                                <label className="ml-3">Image</label>
                                <div className="input-group with-focus">
                                  <input
                                    type="file"
                                    accept="image/png, image/jpeg"
                                    onChange={this.onFileChange}
                                    className="form-control form-control-rounded file-select-button"
                                  />
                                </div>
                                <p style={{ color: "#FF0000" }}>
                                  {this.state.errors.image}
                                </p>
                              </div>
                            </div>
                            <div className=" col-md-12"></div>
                            <div className=" col-md-12">

                              <button
                                className="btn float-right btn-success mr-2 btn-oval"
                                type="button"
                                onClick={() => this.save()}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.auth.user,
    consulteeId: state.auth.consulteeId,
    email: state.auth.user !== null ? state.auth.user.email : ""
  };
};

export default connect(mapStateToProps)(withRouter(withAlert()(ConsulteeForm)));
