import React from "react";
import Countdown from "react-countdown";

export default function BookingsList(props) {

  let rowNumber = 1; // Initialize the row number

  return (
    <div>
      <section className="section-container ml-0">
        {/* Page content*/}
        <div className="content-wrapper">
          <div className="row">
            <div className="col-12">
              <div className=" card-default">
                <div className="row">
                  <div className="col-12">
                    <div className="list-group mb-3">
                      <div className="list-group-item">
                        <table className="wd-wide">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Date</th>
                              <th>Start Time</th>
                              <th>End Time</th>
                              <th>Duration</th>
                              <th>Consultant Name</th>
                              <th>Consultee Name</th>
                              <th>Cerebro Time Starts Within</th>
                              <th>Cerebro Time Ends Within</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.bookingsList.map((item) => {
                              return (
                                <tr key={item.id}>
                                  <td>
                                    <div className="px-2">
                                      <p className="m-0">{rowNumber++}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="px-2">
                                      <p className="m-0">
                                        {item.cerebroslotDate}
                                      </p>
                                    </div>
                                  </td>
                                  <td className="wd-sm d-lg-table-cell">
                                    <div className="px-2">
                                      <small className="text-muted">From</small>
                                      <p className="m-0">{item.startTime}</p>
                                    </div>
                                  </td>
                                  <td className="wd-sm d-lg-table-cell">
                                    <div className="px-2">
                                      <small className="text-muted">To</small>
                                      <p className="m-0">{item.endTime}</p>
                                    </div>
                                  </td>
                                  <td className="wd-sm d-lg-table-cell">
                                    <div className="px-2">
                                      <p className="m-0">
                                        {item.cerebroslotSize / 60} hour(s)
                                      </p>
                                    </div>
                                  </td>
                                  <td className="wd-lg d-lg-table-cell">
                                    <div className="px-2">
                                      {/* <img
                                        className="img-fluid rounded thumb64 float-left mr-2"
                                        src="app/img/dummy.png" alt="" /> */}
                                      <p className=" pt-3 mb-0">{`${item.consultantFirstName} ${item.consultantLastName}`}</p>
                                    </div>
                                  </td>
                                  <td className="wd-lg d-lg-table-cell">
                                    <div className="px-2">
                                      {/* <img
                                        className="img-fluid rounded thumb64 float-left mr-2"
                                        src="app/img/dummy.png" alt="" /> */}
                                      <p className=" pt-3 mb-0">{`${item.consulteeFirstName} ${item.consulteeLastName}`}</p>
                                      <p className="text-muted mb-0"></p>
                                    </div>
                                  </td>
                                  <td className="wd-md">
                                    <div className="px-2">
                                      <Countdown
                                        date={new Date(item.startCountDownTime)}
                                        renderer={(renderer) => {
                                          // console.log(renderer)
                                          return (
                                            <p className=" mb-0">
                                              <span className="text-warning  "> {renderer.days}{" "}</span> Days 
                                              <span className="text-warning  "> {`${renderer.hours}`.padStart(2,"0")}:{`${renderer.minutes}`.padStart(2,"0")}:{`${renderer.seconds}`.padStart(2,"0")}</span> Hrs 
                                            </p>
                                          );
                                        }}
                                      />
                                    </div>
                                  </td>
                                  <td className="wd-md">
                                    <div className="px-2">
                                      <Countdown
                                        date={new Date(item.endCountDownTime)}
                                        renderer={(renderer) => {
                                          if (renderer.completed) {
                                            props.onComplete();
                                          }
                                          return (
                                            <p className=" mb-0">
                                              <span className="text-warning "> {renderer.days}{" "}</span> Days 
                                              <span className="text-warning "> {`${renderer.hours}`.padStart(2,"0")}:{`${renderer.minutes}`.padStart(2,"0")}:{`${renderer.seconds}`.padStart(2,"0")}</span>  Hrs
                                            </p>
                                          );
                                        }}
                                      />

                                    </div>
                                  </td>
                                  {/* <td className="wd-xs text-center d-lg-table-cell">
                                    <div className="px-2">
                                      <button
                                        onClick={() => props.call(item.id)}
                                        className="btn btn-success btn-lg btn-circle animated pulse infinite">
                                        <em className="fa fa-phone fa-lg" />
                                      </button>
                                    </div>
                                  </td> */}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
