import React from 'react'
import TDField from '../../common/container/TDField'
import { getStringForGridView, getStringWithUnitsForGridView } from '../../../utils/GridUtil'

export default function ConsultantResultTableView(props) {

    const { dataList, onItemClick } = props

    return (
        <table className="wd-wide table table-striped">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Age</th>
                    <th>Gender</th>
                    <th>Country</th>
                    <th>Category</th>
                    <th>Slot Size</th>
                    <th>Slot Rate</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {
                    dataList.map((item, index) => {
                        return (
                            <tr style={{ height: "40px" }} key={index} className='clickable-row' onClick={() => onItemClick(item)}>
                                <TDField value={index + 1} />
                                <TDField value={item.firstName + " " + item.lastName} />
                                <TDField value={item.email} />
                                <TDField value={getStringForGridView(item.age)} />
                                <TDField value={item.gender} />
                                <TDField value={item.country} />
                                <TDField value={item.categoryName} />
                                <TDField value={getStringWithUnitsForGridView(item.slotSize, "Mins")} />
                                <TDField value={getStringWithUnitsForGridView(item.slotRate, "LKR")} />
                                <TDField value={item.status} />
                                {/* <td>
                                    <div className="px-2">
                                        <Button
                                            variant="info"
                                            size="lg"
                                            className="m-0 float-left"
                                            onClick={() => onItemClick(item)}
                                        >
                                            View
                                        </Button>
                                    </div>
                                </td> */}
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )
}
