import React from 'react'
import { Col, Row } from 'react-bootstrap';

export default function DetailField(props) {

    const P_STYLE = { wordWrap: "break-word" };
    const DEFAULT_NAME_WIDTH_PROPORTION = 6;
    const DEFAULT_VALUE_WIDTH_PROPORTION = 6;

    return (
        <Row>
            <Col md={props.nameWidthProportion ? props.nameWidthProportion : DEFAULT_NAME_WIDTH_PROPORTION}>
                <div className="px-1">
                    <p className="m-0">
                        <b>{props.name}</b>
                    </p>
                </div>
            </Col>
            <Col md={props.valueWidthProportion ? props.valueWidthProportion : DEFAULT_VALUE_WIDTH_PROPORTION}>
                <div className="px-1">
                    <p className="m-0" style={P_STYLE}>
                        {props.value}
                    </p>
                </div>
            </Col>
        </Row>
    )
}
