import React from 'react'

function RefundPolicy() {
    return (
        <>
            {/* top navbar*/}
            {/* Main section*/}
            <section className="section-container ml-0">
                {/* Page content*/}
                <div className="content-wrapper">
                    <div className="row">
                        <div className="col-12">
                            <div className=" card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-1 offset-md-1 text-left align-self-left"></div>
                                        <div className="col-md-12 align-self-left">
                                            <h1 className="text-center mb-3" >Refund Policy </h1>
                                            <hr className="border-warning my-5" />
                                            <p className="text-left mb-3 lead  float-left">
                                                We at Cerebrozone.com strive to provide a satisfactory experience for both our consultants and
                                                consultantees. This refund policy outlines the circumstances under which refunds may be issued
                                                for services provided through our platform.<br /><br />

                                                1. Cancellation by Consultantee<br />
                                                - If a consultantee wishes to cancel a booked consultation and is eligible for a refund, the
                                                following conditions apply:<br />
                                                    - <strong>Full Refund</strong>: If the consultation is canceled more than 24 hours before the scheduled time
                                                        slot, the consultantee will receive a full refund, excluding any non-refundable fees.<br />
                                                    - <strong>Partial Refund</strong>: If the consultation is canceled within 24 hours of the scheduled time slot, a
                                                        partial refund may be provided at the discretion of Cerebrozone.com, taking into consideration the
                                                        circumstances of the cancellation.<br /><br />

                                                2. Cancellation by Consultant<br />
                                                - In the event that a consultant cancels a scheduled consultation, the consultantee will receive a
                                                full refund, and the consultant will be subject to penalties as outlined in the consultant agreement.<br /><br />

                                                3. Unsatisfactory Consultation<br />
                                                    - If a consultantee is unsatisfied with the quality of the consultation provided by the consultant,
                                                    Cerebrozone.com may, at its own discretion, issue a partial or full refund after reviewing the
                                                    circumstances and any evidence provided by the consultantee.<br /><br />

                                                4. Technical Issues<br />
                                                    - In the event of technical issues on the part of Cerebrozone.com that prevent the successful
                                                    completion of a consultation, the consultantee will be eligible for a full refund.<br /><br />

                                                5. Refund Process<br />
                                                    - Requests for refunds must be submitted in writing through the contact channels provided on the
                                                    Cerebrozone.com website.<br />
                                                    - All refund requests will be reviewed within a reasonable time frame, and decisions will be
                                                    communicated to the requesting party.<br />
                                                    - Refunds will be issued using the original method of payment whenever possible.<br /><br />

                                                6. Non-Refundable Fees<br />
                                                - Certain fees, such as the commission charged by Cerebrozone.com, may be non-refundable in
                                                any circumstance.<br /><br />

                                                7. Contact Information<br />
                                                - For any questions or concerns regarding the refund policy, please contact us through the
                                                provided contact channels on the Cerebrozone.com website.<br /><br />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Page footer*/}
        </>
    )
}

export default RefundPolicy