import React from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from '../../common/datepicker/DatePicker'
import { Constants } from '../../Common';

export default function InvoiceSearch(props) {

    const { searchData, statusList } = props;
    const { setSearchData, resetSearchData, onSearch } = props;


    const onChangeInput = (name, value) => {
        setSearchData(name, value)
    }

    return (
        <div>
            <section className="section-container ml-0">
                {/* Page content*/}
                <div className="content-wrapper">
                    <div className="row">
                        <div className="col-12">
                            <div className=" card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <label className="col-md-12 col-form-label"> {" "} Date From {" "} </label>
                                                    <DatePicker
                                                        value={searchData.startDate}
                                                        onChange={e =>
                                                            onChangeInput("startDate", e.target.value)
                                                        }
                                                        className={"form-control form-control-rounded"}
                                                        autoComplete={"off"}
                                                        required={true}
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <label className="col-md-12 col-form-label"> {" "} Date To {" "} </label>
                                                    <DatePicker
                                                        value={searchData.endDate}
                                                        onChange={e =>
                                                            onChangeInput("endDate", e.target.value)
                                                        }
                                                        className={"form-control form-control-rounded"}
                                                        autoComplete={"off"}
                                                        required={true}
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <label className=" col-form-label"> {" "} Status {" "} </label>
                                                    <select className="form-control form-control-rounded"
                                                        value={searchData.status}
                                                        onChange={(e) => {
                                                            onChangeInput("status", e.target.value)
                                                        }}>
                                                        {
                                                            statusList.map((item, index) =>
                                                                <option key={index} value={item.value}>{Constants.INVOICE_STATUS[item.name]}</option>
                                                            )
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Button
                                                variant="secondary"
                                                size="lg"
                                                onClick={() => resetSearchData()}
                                                className="m-2 float-right"
                                            >
                                                Reset
                                            </Button>
                                            <Button
                                                variant="success"
                                                size="lg"
                                                onClick={() => onSearch()}
                                                className="m-2 float-right"
                                            >
                                                Search
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
