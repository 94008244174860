import React, { Component } from "react";
import UserDataService from "../api/UserDataService";
import { connect } from 'react-redux'
import { HIDE_LOADING, SHOW_LOADING, SIGNUP_SUCCESS } from "../redux/actionTypes";
import { withAlert } from 'react-alert';

class SignupConsultant extends Component {

  constructor() {
    super();
    this.state = {
      userName: "",
      password: "",
      passwordTally: "",
      email: "",
      show: false,
      errors: {}
    };
    this.save = this.save.bind(this);
    this.validate = this.validate.bind(this);
  }

  componentDidMount() {
    localStorage.clear();
  }

  save() {
    this.setState({ errors: {} });
    if (
      this.state.userName !== "" &&
      this.state.password !== "" &&
      this.state.passwordTally !== "" &&
      this.state.password === this.state.passwordTally &&
      this.state.email !== ""
    ) {
      let User = {
        userName: this.state.userName,
        password: this.state.password,
        email: this.state.email,
        roles: ["CONSULTANT"]
      };
      this.props.dispatch({ type: SHOW_LOADING })
      UserDataService.createUser(User)
        .then(res => {
          this.props.dispatch({ type: HIDE_LOADING })
          if ((res) && (res.status === 200) && res.data !== "") {
            UserDataService.registerSuccessfulLogin(res.data)
            this.props.dispatch({ type: SIGNUP_SUCCESS, payload: res.data })
          }
        })
        .catch(e => {
          this.props.dispatch({ type: HIDE_LOADING })
          if ((e.response) && (e.response.status === 400 || e.response.status === 403) && e.response.data !== "") {
            this.props.alert.error(e.response.data.message)
          }
        })
    } else { this.validate(); }
  }

  validate() {
    let oldErrors = {};
    this.setState({ errors: oldErrors });
    let errors = {};
    if (!this.state.userName) { errors.userName = "Cannot leave User Name empty "; }
    if (!this.state.password) { errors.password = "Cannot leave Password empty"; }
    if (!this.state.passwordTally) { errors.passwordTally = "Cannot leave this field empty"; }
    if (this.state.passwordTally !== "") { if (this.state.password === this.state.passwordTally) { errors.passwordTally = "Passwords do not match"; } }
    if (!this.state.email) { errors.email = "Cannot leave Email empty"; }
    this.setState({ errors: errors });
  }

  render() {
    return (
      <div>
        <section className="section-container ml-0">
          {/* Page content*/}
          <div className="content-wrapper">
            <div className="content-heading"> <div> Sign Up <small data-localize="dashboard.WELCOME" /> </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className=" card-default">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-8 text-center border-right" style={{ height: "70vh" }} >
                        <div className="parallax">
                          <div className="water consultee-banner2" />
                          <div className="water consultee-banner1" />
                          <div className="water consultee-banner3" />
                        </div>
                        <div className="clearfix" />
                      </div>
                      <div className="col-md-4 align-self-center">
                        <h3 className="text-center mb-3"> Sign Up as a Consultant </h3>
                        <p className="text-center mb-3"> Enter Your Details to Sign Up </p>
                        {/* <Alert show={this.state.show} variant="success" onClose={() => this.setState({ show: false })} dismissible >
                          <p style={{ aligntText: "center", width: "100%" }}> Your Signup Successfully!!! </p>
                        </Alert> */}
                        <form className="mb-3" id="loginForm" noValidate>
                          <div className="form-group">
                            <label className="ml-3">Name</label>
                            <div className="input-group with-focus">
                              <input value={this.state.userName} onChange={e => this.setState({ userName: e.target.value })}
                                className="form-control form-control-rounded border-right-0" id="name" type="email" placeholder="Enter Name"
                                autoComplete="off" required />
                            </div>
                            <p style={{ color: "#FF0000" }}> {this.state.errors.userName} </p>
                          </div>
                          <div className="form-group">
                            <label className="ml-3">Email</label>
                            <div className="input-group with-focus">
                              <input value={this.state.email} onChange={e => this.setState({ email: e.target.value })}
                                className="form-control form-control-rounded border-right-0" id="email" type="email" placeholder="Enter email"
                                autoComplete="off" required />
                            </div>
                            <p style={{ color: "#FF0000" }}> {this.state.errors.email} </p>
                          </div>
                          <div className="form-group">
                            <label className="ml-3">Password</label>
                            <div className="input-group with-focus">
                              <input value={this.state.password} onChange={e => this.setState({ password: e.target.value })}
                                className="form-control form-control-rounded border-right-0" id="password" type="password" placeholder="Password" required />
                            </div>
                            <p style={{ color: "#FF0000" }}> {this.state.errors.password} </p>
                          </div>
                          <div className="form-group">
                            <label className="ml-3">Re-enter Password</label>
                            <div className="input-group with-focus">
                              <input value={this.state.passwordTally} onChange={e => this.setState({ passwordTally: e.target.value })}
                                className="form-control form-control-rounded border-right-0" id="reenter-password" type="password" placeholder="Password" required />
                            </div>
                            <p style={{ color: "#FF0000" }}> {this.state.errors.passwordTally} </p>
                          </div>
                          <div className="clearfix" />
                          <div className="text-center w-100">
                            <button className="btn  btn-primary mt-3 btn-oval" onClick={() => this.save()} type="button" > Sign Up </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default connect()(withAlert()(SignupConsultant));
