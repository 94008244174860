export const getStringForGridView = (value) => {
    return value ? value : '-';
};

export const getStringWithUnitsForGridView = (value, unit) => {
    return value ? value + " " + unit : '-';
};


export const getNumberForGridView = (value) => {
    return value ? value : 0;
};

export const getNumberWithUnitsForGridView = (value, unit) => {
    return value ? value + " " + unit : 0 + " " + unit;
};

export const getConcatValueWithIgnoringNull = (...args) => {
    return args.filter(value => value !== null).join('');
};