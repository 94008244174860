import React from 'react'

export default function TDField(props) {
    return (
        <td>
            <div className="px-2">
                <p className="m-0">{props.value}</p>
            </div>
        </td>
    )
}
