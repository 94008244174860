import React from 'react'

export default function TimeSlots(props) {
  return (
    <div className="row">
      <div className="col-6">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">

                <div className="row">
                  <div className="col-md-8 center">
                    <h4 className="mb-0">Current Time Slots</h4>
                  </div>
                  <div className="col-md-4 ">
                    {/* Button trigger modal */}
                    <button
                      type="button"
                      className="btn btn-warning btn-md text-bold fa-2x"
                      data-toggle="modal"
                      data-target="#exampleModal"
                    >
                      {" "}
                    Add Availability <em className="fas fa-plus fa-lg ml-1" />{" "}
                    </button>
                  </div>
                </div>
                <hr className="border-warning mb-3 mt-2" />

                {props.currentSlots.map(cerebroSlot => (
                  <div className="list-group mb-3" key={cerebroSlot.id}>
                    <table className="wd-wide">
                      <tbody>
                        <tr>
                          {/* <td className="wd-xs"><div className="px-2"><img className="img-fluid rounded thumb64" src="app/img/dummy.png" alt="" />  </div></td> */}
                          <td>
                            {" "}
                            <div className="px-2">
                              {" "}
                              <h4 className="mb-2">
                                {" "}
                                {cerebroSlot.startDate}{" "}
                              </h4>{" "}
                            </div>{" "}
                          </td>
                          {/* <small className="text-muted"> Vestibulum ante ipsum primis in</small> */}
                          <td className="wd-sm d-lg-table-cell">
                            {" "}
                            <div className="px-2">
                              <p className="m-0">{cerebroSlot.startTime}</p>
                              <small className="text-muted"> From</small>
                            </div>{" "}
                          </td>
                          <td className="wd-sm d-lg-table-cell">
                            {" "}
                            <div className="px-2">
                              <p className="m-0">{cerebroSlot.endTime}</p>
                              <small className="text-muted">To</small>
                            </div>{" "}
                          </td>
                          {/* <td className="wd-xs d-lg-table-cell"> <div className="px-2"><p className="m-0 text-muted"> <em className="icon-eye mr-2 fa-lg" /> 26 </p> </div> </td> */}
                          {/* <td className="wd-xs d-lg-table-cell"> <div className="px-2"> <p className="m-0 text-muted"> <em className="fas fa-dollar-sign mr-2 fa-lg" /> 3500 </p> </div> </td> */}
                          <td className="wd-sm text-center d-lg-table-cell">
                            <div className="px-2">
                              {cerebroSlot.status === "AVAILABLE" ? (
                                <span className="badge badge-success animated pulse infinite">
                                  {" "}
                                  Available{" "}
                                </span>
                              ) : (
                                  <span className="badge badge-warning animated pulse infinite">
                                    {" "}
                                  Booked{" "}
                                  </span>
                                )}
                            </div>
                          </td>
                          {/* <td className="wd-xs text-center d-lg-table-cell"> <div className="px-2"> <input className="styled-checkbox" id="styled-checkbox-1" type="checkbox" defaultValue="value1" /> <label htmlFor="styled-checkbox-1" /> </div> </td> */}
                          <td className="wd-xs text-center d-lg-table-cell">
                            {/* data-toggle="modal" data-target="#exampleModal"  */}
                            {cerebroSlot.status === "AVAILABLE" ? (
                              <button
                                type="button"
                                className="btn btn-danger btn-xs text-bold fa-2x"
                                onClick={() => props.onDelete(cerebroSlot.id)}
                              >
                                {" "}
                                Delete{" "}
                                <em className="fas fa-trash fa-lg ml-1" />
                              </button>
                            ) : null}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ))}
                {/* <div className="wd-wide">
                  <Pagination innerClass="pagination float-right" activeClass="text-bold" prevPageText="<" nextPageText=">" itemClass="page-item" linkClass="page-link" activePage={this.state.activePage}
                    itemsCountPerPage={this.state.itemsCountPerPage} totalItemsCount={this.state.totalItemsCount}
                    pageRangeDisplayed={5} />
                </div> */}

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-6">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <h4 className="mb-0">Expired Time Slots</h4>
                <hr className="border-warning mb-3 mt-2" />
                {props.expiredSlots.map(cerebroSlot => (
                  <div className="list-group mb-3" key={cerebroSlot.id}>
                    <table className="wd-wide">
                      <tbody>
                        <tr>
                          {/* <td className="wd-xs"><div className="px-2"><img className="img-fluid rounded thumb64" src="app/img/dummy.png" alt="" />  </div></td> */}
                          <td>
                            {" "}
                            <div className="px-2">
                              {" "}
                              <h4 className="mb-2">
                                {" "}
                                {cerebroSlot.startDate}{" "}
                              </h4>{" "}
                            </div>{" "}
                          </td>
                          {/* <small className="text-muted"> Vestibulum ante ipsum primis in</small> */}
                          <td className="wd-sm d-lg-table-cell">
                            {" "}
                            <div className="px-2">
                              <p className="m-0">{cerebroSlot.startTime}</p>
                              <small className="text-muted"> From</small>
                            </div>{" "}
                          </td>
                          <td className="wd-sm d-lg-table-cell">
                            {" "}
                            <div className="px-2">
                              <p className="m-0">{cerebroSlot.endTime}</p>
                              <small className="text-muted">To</small>
                            </div>{" "}
                          </td>
                          {/* <td className="wd-xs d-lg-table-cell"> <div className="px-2"><p className="m-0 text-muted"> <em className="icon-eye mr-2 fa-lg" /> 26 </p> </div> </td> */}
                          {/* <td className="wd-xs d-lg-table-cell"> <div className="px-2"> <p className="m-0 text-muted"> <em className="fas fa-dollar-sign mr-2 fa-lg" /> 3500 </p> </div> </td> */}
                          <td className="wd-sm text-center d-lg-table-cell">
                            <div className="px-2">
                              <span className="badge badge-danger animated pulse infinite">
                                {" "}
                                  Expired{" "}
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}