import React from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { getConcatValueWithIgnoringNull, getStringForGridView, getStringWithUnitsForGridView } from '../../../utils/GridUtil';
import { Constants } from '../../Common';
import DetailField from '../../common/container/DetailField';

function ConsultantReviewModel(props) {

    const { show, item, statusList } = props;
    const { onCloseClick, onUpdateClick, onUpdateValue } = props;

    const isPending = item.status && item.status === Constants.CONSULTANT_STATUS_CONST.PENDING_APPROVAL;

    console.log(item.imageUrl)

    return (
        <Modal
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton onHide={props.onCloseClick}>
                <h4>View Consultant</h4>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col md={7}>
                            <div className=" card-default">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="list-group mb-3">
                                            <div className="list-group-item" style={{ width: "100%", height: "250px", overflowY: "auto", overflowX: "hidden" }}>
                                                <DetailField name={"Consultant Name"} value={item.firstName + ' ' + item.lastName} />
                                                <DetailField name={"Email"} value={item.email} />
                                                <DetailField name={"Phone No"} value={getConcatValueWithIgnoringNull((item.dialCode ? "+" + item.dialCode : null), item.phone)} />
                                                <DetailField name={"Gender"} value={item.gender} />
                                                <DetailField name={"Age"} value={getStringForGridView(item.age)} />
                                                <DetailField name={"Country"} value={item.country} />
                                                <DetailField name={"Category"} value={item.categoryName} />
                                                <DetailField name={"Experience"} value={getStringForGridView(item.experience)} />
                                                <DetailField name={"Description"} value={getStringForGridView(item.about)} />
                                                <DetailField name={"Slot Size"} value={getStringWithUnitsForGridView(item.slotSize, "Mins")} />
                                                <DetailField name={"Slot Rate"} value={getStringWithUnitsForGridView(item.slotRate, "LKR")} />
                                                <DetailField name={"Status"} value={item.status} />
                                            </div>
                                            <div className="list-group-item mt-2" style={{ width: "100%", maxHeight: "100px", overflowY: "auto", overflowX: "hidden" }}>
                                                <DetailField name={"Bank Name"} value={item.bank} />
                                                <DetailField name={"Bank Branch"} value={item.bankBranch} />
                                                <DetailField name={"Account No"} value={item.accNumber} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={5} >
                            <div className=" card-default" >
                                <div className="row">
                                    <div className="col-12">
                                        <div className="list-group mb-3">
                                            <div className="list-group-item" style={{ width: "100%", height: "332px", overflowY: "auto" }}>
                                                <img
                                                    src={item.imageUrl ? item.imageUrl : "app/img/user/avatar.svg"}
                                                    width={"100%"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </Modal.Body>
            <Modal.Footer>
                {
                    isPending &&
                    <>
                        <Button onClick={() => onUpdateClick(Constants.CONSULTANT_STATUS_CONST.VERIFIED)} variant='success'>Approve</Button>
                    </>
                }
                <Button onClick={onCloseClick} variant='white'>Close</Button>
            </Modal.Footer>
        </Modal>

    )
}

export default ConsultantReviewModel