import axios from "axios";
import { useHistory } from "react-router-dom";
import UserDataService from "./api/UserDataService";

axios.interceptors.request.use(req => {
  req.headers["Content-Type"] = "application/json";
  // myHeaders.append("Content-Type", "application/json");
  // myHeaders.append('Access-Control-Allow-Origin', 'http://localhost');
  // myHeaders.append('Access-Control-Allow-Credentials', 'false');
  // myHeaders.append('Access-Control-Allow-Methods', 'POST, GET, PUT, OPTIONS, DELETE');
  // myHeaders.append('Access-Control-Allow-Headers', '*');
  // myHeaders.append('Access-Control-Max-Age', '4800');
  return req;
});

axios.interceptors.response.use(res => {
  return res;
}, redirectToLogin);

function redirectToLogin(error) {
  if (error.message === 'Request failed with status code 401') {
    localStorage.removeItem('user')
    window.location.reload();
  }
  return Promise.reject(error);
}

export default axios;
