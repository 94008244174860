import React, { Component } from 'react';


export class AboutUs extends Component {
    render() {
        return (
            <div>
                {/* top navbar*/}
                {/* Main section*/}
                <section className="section-container ml-0">
                    {/* Page content*/}
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-12">
                                <div className=" card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-5 offset-md-1 text-center align-self-center">
                                                <div className="card-body">
                                                    <img src="app/img/pages/aboutUs.svg" alt="" className="img-fluid " />
                                                </div>
                                                <div className="clearfix" />
                                            </div>
                                            <div className="col-md-5 align-self-center">
                                                <h1 className="text-center mb-3">Who We Are</h1>
                                                <hr className="border-warning my-5" />
                                                <p className="text-center mb-3 lead  float-left"><strong className="text-warning">We are
                                                    your</strong> most convenient consulting company.
                                                    Here at
                                                    cerebrozone we provide you any real time on demand live specialist consultation
                                                    sessions.
                                                </p>
                                                <p className="text-center mb-3 lead">
                                                    Seated at your PC or laptop get connected with us. Speak to your consultant
                                                    online and find your solution. CEREBROZONE is your one trusted advocate in
                                                    finding you the real solutions through real expertise.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Page footer*/}

            </div>

        )

    }
}

export default AboutUs