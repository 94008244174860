import React, { Component } from 'react';

class Services extends Component {
    render() {
        return (
            <div className="wrapper">


                {/* Main section*/}
                <section className="section-container ml-0">
                    {/* Page content*/}
                    <div className="content-wrapper">
                        {/*            <div class="content-heading">*/}
                        {/*                <div>Login*/}
                        {/*                    <small data-localize="dashboard.WELCOME"></small>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        <div className="row">
                            <div className="col-12">
                                <div className=" card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-5 offset-md-1 text-center align-self-center">
                                                <div className="card-body">
                                                    <img src="app/img/pages/aboutUs.svg" alt="" className="img-fluid " />
                                                </div>
                                                <div className="clearfix" />
                                            </div>
                                            <div className="col-md-5 offset-md-1 align-self-center text-center ">
                                                <h1 className="text-center mb-0"><em className="icon-anchor fa-3x" /> <br />Our Services
                                                </h1>
                                                <h4 className>Get connected with leading specialists,
                                                    meant to provide solutions</h4>
                                                <hr className="border-warning" />
                                                <p className="text-center lead mb-5">
                                                    Established as a consultation facility benefactor, our goal is to link
                                                    service
                                                    necessitating client and a service oriented consultant to fix out your
                                                    complications in any possible manner. Pick and choose from our various
                                                    amenities
                                                    and place your booking to get started.
                                                </p>
                                            </div>
                                            <div className="col-12">
                                                <hr className="border-warning my-5" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-2 col-lg-3 col-md-4">
                                                {/* START card*/}
                                                <div className="card bg-light category">
                                                    <div className="card-body  text-center">
                                                        <div className="text-lg m-0">
                                                            <img className="thumb128" src="app/img/category/stock.svg" alt="d" />
                                                        </div>
                                                        <p>Stock Broker</p>
                                                    </div>
                                                    {/* END card*/}
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-lg-3 col-md-4">
                                                {/* START card*/}
                                                <div className="card bg-light category">
                                                    <div className="card-body text-center">
                                                        <div className="text-lg m-0">
                                                            <img className="thumb128" src="app/img/category/engineer.svg" alt="d" />
                                                        </div>
                                                        <p>Engineer</p>
                                                    </div>
                                                    {/* END card*/}
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-lg-3 col-md-4">
                                                {/* START card*/}
                                                <div className="card bg-light category">
                                                    <div className="card-body text-center">
                                                        <div className="text-lg m-0">
                                                            <img className="thumb128" src="app/img/category/programmer.svg" alt="d" />
                                                        </div>
                                                        <p>Programmer</p>
                                                    </div>
                                                    {/* END card*/}
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-lg-3 col-md-4">
                                                {/* START card*/}
                                                <div className="card bg-light category">
                                                    <div className="card-body text-center">
                                                        <div className="text-lg m-0">
                                                            <img className="thumb128" src="app/img/category/architect.svg" alt="d" />
                                                        </div>
                                                        <p>Architect</p>
                                                    </div>
                                                    {/* END card*/}
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-lg-3 col-md-4">
                                                {/* START card*/}
                                                <div className="card bg-light category">
                                                    <div className="card-body text-center">
                                                        <div className="text-lg m-0">
                                                            <img className="thumb128" src="app/img/category/interior.svg" alt="d" />
                                                        </div>
                                                        <p>Interior Designer</p>
                                                    </div>
                                                    {/* END card*/}
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-lg-3 col-md-4">
                                                {/* START card*/}
                                                <div className="card bg-light category">
                                                    <div className="card-body text-center">
                                                        <div className="text-lg m-0">
                                                            <img className="thumb128" src="app/img/category/lowyer.svg" alt="d" />
                                                        </div>
                                                        <p>Lawyer</p>
                                                    </div>
                                                    {/* END card*/}
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-lg-3 col-md-4">
                                                {/* START card*/}
                                                <div className="card bg-light category">
                                                    <div className="card-body text-center">
                                                        <div className="text-lg m-0">
                                                            <img className="thumb128" src="app/img/category/beautician.svg" alt="d" />
                                                        </div>
                                                        <p>Beautician</p>
                                                    </div>
                                                    {/* END card*/}
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-lg-3 col-md-4">
                                                {/* START card*/}
                                                <div className="card bg-light category">
                                                    <div className="card-body text-center">
                                                        <div className="text-lg m-0">
                                                            <img className="thumb128" src="app/img/category/doctor.svg" alt="d" />
                                                        </div>
                                                        <p>Doctor</p>
                                                    </div>
                                                    {/* END card*/}
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-lg-3 col-md-4">
                                                {/* START card*/}
                                                <div className="card bg-light category">
                                                    <div className="card-body text-center">
                                                        <div className="text-lg m-0">
                                                            <img className="thumb128" src="app/img/category/nurse.svg" alt="d" />
                                                        </div>
                                                        <p>Nursing &amp; Mid Wifery</p>
                                                    </div>
                                                    {/* END card*/}
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-lg-3 col-md-4">
                                                {/* START card*/}
                                                <div className="card bg-light category">
                                                    <div className="card-body text-center">
                                                        <div className="text-lg m-0">
                                                            <img className="thumb128" src="app/img/category/astrology.svg" alt="d" />
                                                        </div>
                                                        <p>Astrology</p>
                                                    </div>
                                                    {/* END card*/}
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-lg-3 col-md-4">
                                                {/* START card*/}
                                                <div className="card bg-light category">
                                                    <div className="card-body text-center">
                                                        <div className="text-lg m-0">
                                                            <img className="thumb128" src="app/img/category/tuor.svg" alt="d" />
                                                        </div>
                                                        <p>Tutor</p>
                                                    </div>
                                                    {/* END card*/}
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-lg-3 col-md-4">
                                                {/* START card*/}
                                                <div className="card bg-light category">
                                                    <div className="card-body text-center">
                                                        <div className="text-lg m-0">
                                                            <img className="thumb128" src="app/img/category/counselor.svg" alt="d" />
                                                        </div>
                                                        <p>Counselor</p>
                                                    </div>
                                                    {/* END card*/}
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-lg-3 col-md-4">
                                                {/* START card*/}
                                                <div className="card bg-light category">
                                                    <div className="card-body text-center">
                                                        <div className="text-lg m-0">
                                                            <img className="thumb128" src="app/img/category/guide.svg" alt="d" />
                                                        </div>
                                                        <p>Tour guide</p>
                                                    </div>
                                                    {/* END card*/}
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-lg-3 col-md-4">
                                                {/* START card*/}
                                                <div className="card bg-light category">
                                                    <div className="card-body text-center">
                                                        <div className="text-lg m-0">
                                                            <img className="thumb128" src="app/img/category/business.svg" alt="d" />
                                                        </div>
                                                        <p>Business</p>
                                                    </div>
                                                    {/* END card*/}
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-lg-3 col-md-4">
                                                {/* START card*/}
                                                <div className="card bg-light category">
                                                    <div className="card-body text-center">
                                                        <div className="text-lg m-0">
                                                            <img className="thumb128" src="app/img/category/acountent.svg" alt="d" />
                                                        </div>
                                                        <p>Accountant</p>
                                                    </div>
                                                    {/* END card*/}
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-lg-3 col-md-4">
                                                {/* START card*/}
                                                <div className="card bg-light category">
                                                    <div className="card-body text-center">
                                                        <div className="text-lg m-0">
                                                            <img className="thumb128" src="app/img/category/invest.svg" alt="d" />
                                                        </div>
                                                        <p>Investment specialist</p>
                                                    </div>
                                                    {/* END card*/}
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-lg-3 col-md-4">
                                                {/* START card*/}
                                                <div className="card bg-light category">
                                                    <div className="card-body text-center">
                                                        <div className="text-lg m-0">
                                                            <img className="thumb128" src="app/img/category/cheff.svg" alt="d" />
                                                        </div>
                                                        <p>Culinary</p>
                                                    </div>
                                                    {/* END card*/}
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-lg-3 col-md-4">
                                                {/* START card*/}
                                                <div className="card bg-light category">
                                                    <div className="card-body text-center">
                                                        <div className="text-lg m-0">
                                                            <img className="thumb128" src="app/img/category/dietician.svg" alt="d" />
                                                        </div>
                                                        <p>Dietician</p>
                                                    </div>
                                                    {/* END card*/}
                                                </div>
                                            </div>
                                            {/*repeat this*/}
                                            {/*                        <div class="col-xl-2 col-lg-3 col-md-4">*/}
                                            {/*                            &lt;!&ndash; START card&ndash;&gt;*/}
                                            {/*                            <div class="card bg-light category">*/}
                                            {/*                                <div class="card-body text-center">*/}
                                            {/*                                    <div class="text-lg m-0">*/}
                                            {/*                                        <img class="thumb128" src="app/img/category/dietician.svg" alt="d">*/}
                                            {/*                                    </div>*/}
                                            {/*                                    <p>Dietician</p>*/}
                                            {/*                                </div>*/}
                                            {/*                                &lt;!&ndash; END card&ndash;&gt;*/}
                                            {/*                            </div>*/}
                                            {/*                        </div>*/}
                                            {/*repeat end*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-10 offset-md-1" id="faqs">
                                <div className="row">
                                    <div className="col-12 text-center mt-5">
                                        <h2 className="font-weight-normal">FAQs</h2>
                                        <hr className="border-warning my-5" />
                                    </div>
                                    <div className="col-12">
                                        <div id="accordion1">
                                            <div className="card b0 mb-2">
                                                <a className="text-inherit collapsed" data-toggle="collapse" data-parent="#accordion1" href="#acc1collapse1" aria-expanded="false">
                                                    <div className="card-header">
                                                        <h4 className="card-title">
                                                            <small>
                                                                <em className="fa fa-plus text-primary mr-2" />
                                                            </small>
                                                            <span> How can I book a consultation on Cerebrozone.com?</span>
                                                        </h4>
                                                    </div>
                                                </a>
                                                <div className="collapse" id="acc1collapse1" style={{}}>
                                                    <div className="card-body">
                                                        <p>Booking a consultation on Cerebrozone.com is simple and convenient.
                                                            Just visit our website, log in, and choose the consultant that fits your needs.
                                                            Book your session from anywhere at any time.</p>
                                                        <div className="text-right">
                                                            <small className="text-muted mr-2">Was this information useful?</small>
                                                            <button className="btn btn-secondary btn-xs" type="button">
                                                                <em className="fa fa-thumbs-up text-muted" />
                                                            </button>
                                                            <button className="btn btn-secondary btn-xs" type="button">
                                                                <em className="fa fa-thumbs-down text-muted" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card b0 mb-2">
                                                <a className="text-inherit collapsed" data-toggle="collapse" data-parent="#accordion1" href="#acc1collapse2" aria-expanded="false">
                                                    <div className="card-header">
                                                        <h4 className="card-title">
                                                            <small>
                                                                <em className="fa fa-plus text-primary mr-2" />
                                                            </small>
                                                            <span>How do I join Cerebrozone.com as a professional consultant?</span>
                                                        </h4>
                                                    </div>
                                                </a>
                                                <div className="collapse" id="acc1collapse2" style={{}}>
                                                    <div className="card-body">
                                                        <p>Joining our team of specialists is easy.
                                                            Simply log in to your account, and you can quickly find your client base.
                                                            Be a part of our futuristic service platform and connect with clients in no time.</p>
                                                        <div className="text-right">
                                                            <small className="text-muted mr-2">Was this information useful?</small>
                                                            <button className="btn btn-secondary btn-xs" type="button">
                                                                <em className="fa fa-thumbs-up text-muted" />
                                                            </button>
                                                            <button className="btn btn-secondary btn-xs" type="button">
                                                                <em className="fa fa-thumbs-down text-muted" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card b0 mb-2">
                                                <a className="text-inherit collapsed" data-toggle="collapse" data-parent="#accordion1" href="#acc1collapse3" aria-expanded="false">
                                                    <div className="card-header">
                                                        <h4 className="card-title">
                                                            <small>
                                                                <em className="fa fa-plus text-primary mr-2" />
                                                            </small>
                                                            <span>Is my information safe with Cerebrozone.com?</span>
                                                        </h4>
                                                    </div>
                                                </a>
                                                <div className="collapse" id="acc1collapse3" style={{}}>
                                                    <div className="card-body">
                                                        <p>Absolutely! We highly value your privacy.
                                                            Your data is securely stored and will never be shared with any third party.
                                                            Your confidentiality is our priority.</p>
                                                        <div className="text-right">
                                                            <small className="text-muted mr-2">Was this information useful?</small>
                                                            <button className="btn btn-secondary btn-xs" type="button">
                                                                <em className="fa fa-thumbs-up text-muted" />
                                                            </button>
                                                            <button className="btn btn-secondary btn-xs" type="button">
                                                                <em className="fa fa-thumbs-down text-muted" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card b0 mb-2">
                                                <a className="text-inherit collapsed" data-toggle="collapse" data-parent="#accordion1" href="#acc1collapse4" aria-expanded="false">
                                                    <div className="card-header">
                                                        <h4 className="card-title">
                                                            <small>
                                                                <em className="fa fa-plus text-primary mr-2" />
                                                            </small>
                                                            <span>How secure are the payment transactions on Cerebrozone.com?</span>
                                                        </h4>
                                                    </div>
                                                </a>
                                                <div className="collapse" id="acc1collapse4" style={{}}>
                                                    <div className="card-body">
                                                        <p>We guarantee 100% payment protection for the services you purchase.
                                                            All transactions are conducted through a secure Payment gateway platform.
                                                            Whether you use a debit/credit card or any other form of payment,
                                                            we handle everything to ensure the security of your payment.</p>
                                                        <div className="text-right">
                                                            <small className="text-muted mr-2">Was this information useful?</small>
                                                            <button className="btn btn-secondary btn-xs" type="button">
                                                                <em className="fa fa-thumbs-up text-muted" />
                                                            </button>
                                                            <button className="btn btn-secondary btn-xs" type="button">
                                                                <em className="fa fa-thumbs-down text-muted" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card b0 mb-2">
                                                <a className="text-inherit collapsed" data-toggle="collapse" data-parent="#accordion1" href="#acc1collapse5" aria-expanded="false">
                                                    <div className="card-header">
                                                        <h4 className="card-title">
                                                            <small>
                                                                <em className="fa fa-plus text-primary mr-2" />
                                                            </small>
                                                            <span>Can I trust the communication on Cerebrozone.com?</span>
                                                        </h4>
                                                    </div>
                                                </a>
                                                <div className="collapse" id="acc1collapse5" style={{}}>
                                                    <div className="card-body">
                                                        <p>Yes, you can trust the communication on Cerebrozone.com.
                                                            We provide a secure messaging system for communication between clients and consultants.
                                                            Your conversations are protected to ensure a safe and confidential interaction.</p>
                                                        <div className="text-right">
                                                            <small className="text-muted mr-2">Was this information useful?</small>
                                                            <button className="btn btn-secondary btn-xs" type="button">
                                                                <em className="fa fa-thumbs-up text-muted" />
                                                            </button>
                                                            <button className="btn btn-secondary btn-xs" type="button">
                                                                <em className="fa fa-thumbs-down text-muted" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card b0 mb-2">
                                                <a className="text-inherit collapsed" data-toggle="collapse" data-parent="#accordion1" href="#acc1collapse6" aria-expanded="false">
                                                    <div className="card-header">
                                                        <h4 className="card-title">
                                                            <small>
                                                                <em className="fa fa-plus text-primary mr-2" />
                                                            </small>
                                                            <span>What measures are in place to ensure the safety of my data during online consultations?</span>
                                                        </h4>
                                                    </div>
                                                </a>
                                                <div className="collapse" id="acc1collapse6" style={{}}>
                                                    <div className="card-body">
                                                        <p>We employ robust security measures to safeguard your data during online consultations.
                                                            Our platform is designed to prioritize the security and privacy of your information,
                                                            Providing you with a safe and reliable experience.
                                                        </p>
                                                        <div className="text-right">
                                                            <small className="text-muted mr-2">Was this information useful?</small>
                                                            <button className="btn btn-secondary btn-xs" type="button">
                                                                <em className="fa fa-thumbs-up text-muted" />
                                                            </button>
                                                            <button className="btn btn-secondary btn-xs" type="button">
                                                                <em className="fa fa-thumbs-down text-muted" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card b0 mb-2">
                                                <a className="text-inherit collapsed" data-toggle="collapse" data-parent="#accordion1" href="#acc1collapse7" aria-expanded="false">
                                                    <div className="card-header">
                                                        <h4 className="card-title">
                                                            <small>
                                                                <em className="fa fa-plus text-primary mr-2" />
                                                            </small>
                                                            <span>How does Cerebrozone.com ensure that consultants are qualified professionals</span>
                                                        </h4>
                                                    </div>
                                                </a>
                                                <div className="collapse" id="acc1collapse7" style={{}}>
                                                    <div className="card-body">
                                                        <p>Cerebrozone.com carefully selects and verifies professionals before they join our team of specialists.
                                                            We ensure that our consultants are qualified and experienced, providing you with reliable and expert advice.</p>
                                                        <div className="text-right">
                                                            <small className="text-muted mr-2">Was this information useful?</small>
                                                            <button className="btn btn-secondary btn-xs" type="button">
                                                                <em className="fa fa-thumbs-up text-muted" />
                                                            </button>
                                                            <button className="btn btn-secondary btn-xs" type="button">
                                                                <em className="fa fa-thumbs-down text-muted" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card b0 mb-2">
                                                <a className="text-inherit collapsed" data-toggle="collapse" data-parent="#accordion1" href="#acc1collapse8" aria-expanded="false">
                                                    <div className="card-header">
                                                        <h4 className="card-title">
                                                            <small>
                                                                <em className="fa fa-plus text-primary mr-2" />
                                                            </small>
                                                            <span>What payment methods are accepted on Cerebrozone.com?</span>
                                                        </h4>
                                                    </div>
                                                </a>
                                                <div className="collapse" id="acc1collapse8" style={{}}>
                                                    <div className="card-body">
                                                        <p>We accept various payment methods, including debit/credit cards and other forms of payment.
                                                            Our platform is designed to provide flexibility in payment options,
                                                            making it convenient for our clients.</p>
                                                        <div className="text-right">
                                                            <small className="text-muted mr-2">Was this information useful?</small>
                                                            <button className="btn btn-secondary btn-xs" type="button">
                                                                <em className="fa fa-thumbs-up text-muted" />
                                                            </button>
                                                            <button className="btn btn-secondary btn-xs" type="button">
                                                                <em className="fa fa-thumbs-down text-muted" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card b0 mb-2">
                                                <a className="text-inherit collapsed" data-toggle="collapse" data-parent="#accordion1" href="#acc1collapse9" aria-expanded="false">
                                                    <div className="card-header">
                                                        <h4 className="card-title">
                                                            <small>
                                                                <em className="fa fa-plus text-primary mr-2" />
                                                            </small>
                                                            <span>Can I cancel or reschedule a consultation on Cerebrozone.com?</span>
                                                        </h4>
                                                    </div>
                                                </a>
                                                <div className="collapse" id="acc1collapse9" style={{}}>
                                                    <div className="card-body">
                                                        <p>Yes, you can cancel or reschedule a consultation on Cerebrozone.com.
                                                            Please refer to our cancellation and rescheduling policy for more details on how to manage your appointments.
                                                        </p>
                                                        <div className="text-right">
                                                            <small className="text-muted mr-2">Was this information useful?</small>
                                                            <button className="btn btn-secondary btn-xs" type="button">
                                                                <em className="fa fa-thumbs-up text-muted" />
                                                            </button>
                                                            <button className="btn btn-secondary btn-xs" type="button">
                                                                <em className="fa fa-thumbs-down text-muted" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card b0 mb-2">
                                                <a className="text-inherit collapsed" data-toggle="collapse" data-parent="#accordion1" href="#acc1collapse10" aria-expanded="false">
                                                    <div className="card-header">
                                                        <h4 className="card-title">
                                                            <small>
                                                                <em className="fa fa-plus text-primary mr-2" />
                                                            </small>
                                                            <span>How do I report any issues or concerns with the services on Cerebrozone.com?</span>
                                                        </h4>
                                                    </div>
                                                </a>
                                                <div className="collapse" id="acc1collapse10" style={{}}>
                                                    <div className="card-body">
                                                        <p>If you encounter any issues or have concerns about our services, please contact our customer support. 
                                                            We are dedicated to addressing any problems promptly and ensuring a positive experience for our users.</p>
                                                        <div className="text-right">
                                                            <small className="text-muted mr-2">Was this information useful?</small>
                                                            <button className="btn btn-secondary btn-xs" type="button">
                                                                <em className="fa fa-thumbs-up text-muted" />
                                                            </button>
                                                            <button className="btn btn-secondary btn-xs" type="button">
                                                                <em className="fa fa-thumbs-down text-muted" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Page footer*/}

            </div>

        );
    }
}

export default Services;