import React from 'react';
import { Button, Col, NavLink, Row } from 'react-bootstrap';
import { getStringForGridView, getStringWithUnitsForGridView } from '../../../utils/GridUtil';
import TDField from '../../common/container/TDField';
import Pagination from '../../common/pagination/Pagination';
import ConsultantResultGridView from './ConsultantResultGridView';
import ConsultantResultTableView from './ConsultantResultTableView';
import { Constants } from '../../Common';

export default function ConsultantResult(props) {

    const { dataList, statusList, pagedData, currentRoleView } = props;
    const { onItemClick, onPageInfoChange } = props;

    const hasData = dataList && dataList.length > 0;


    return (
        <>
            <div>
                <section className="section-container ml-0">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-12">
                                {
                                    hasData &&
                                    <Row>
                                        <Col md={6}></Col>
                                        <Col md={6}>
                                            <Pagination
                                                classNameForWrapper={'float-right'}
                                                currentPageNo={pagedData.currentPageNo}
                                                totalNoOfPages={pagedData.totalNoOfPages}
                                                onPaginationChange={onPageInfoChange}
                                            />
                                        </Col>
                                    </Row>
                                }
                                {
                                    currentRoleView === Constants.ROLE_CONST.ADMIN &&
                                    <Row>
                                        <div className="col-12">
                                            <div className="list-group mb-3">
                                                <div className="list-group-item">
                                                    {!hasData && <div className="alert alert-info">No data found</div>}
                                                    {hasData &&
                                                        <ConsultantResultTableView dataList={dataList} onItemClick={onItemClick} />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                }
                                {
                                    currentRoleView === Constants.ROLE_CONST.CONSULTEE &&
                                    <Row>
                                        {!hasData && <Col md={12}><div className="alert alert-info">No data found</div></Col>}
                                        {
                                            hasData &&
                                            <ConsultantResultGridView dataList={dataList} />
                                        }
                                    </Row>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
