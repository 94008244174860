import React from 'react'
import {
  Accordion,
  Card,
  ListGroup,
  Row,
  Col,
} from "react-bootstrap";
import { formatteredDate } from '../Common';

export default function MyInvoice(props) {
  return (
    <>
      <section className="section-container ml-0">
        <div className="content-wrapper">
          <Row>
            <Col>
              <Accordion>
                {props.invoices.success.map(invoice => {
                  return (
                    <Card key={invoice.cref}>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey={invoice.cref}
                      >
                        <Row>
                          <Col xs={6}>{invoice.cref}</Col>
                          <Col>{formatteredDate(invoice.date)}</Col>
                          <Col>
                            <div className="px-2">
                              <span className={`badge badge-success`}>
                                SUCCESS
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={invoice.cref}>
                        <Card.Body>
                          <Accordion>
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="0">
                                {" "}
                                Slots{" "}
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                  {invoice.cerebroslots.map(slot => {
                                    return (
                                      <div
                                        className="content-wrapper"
                                        key={slot.id}
                                      >
                                        <ListGroup variant="flush">
                                          <ListGroup.Item>{`Start Date - ${slot.startDate}`}</ListGroup.Item>
                                          <ListGroup.Item>{`Consultant Name - ${slot.consultantFirstName} ${slot.consultantLastName}`}</ListGroup.Item>
                                          <ListGroup.Item>{`Start Time - ${slot.startTime}`}</ListGroup.Item>
                                          <ListGroup.Item>{`End Time - ${slot.endTime}`}</ListGroup.Item>
                                          <ListGroup.Item>{`Slot Price - ${slot.slotPrice.toLocaleString()}LKR`}</ListGroup.Item>
                                          <ListGroup.Item>{`Slot Size - ${slot.slotSize}min`}</ListGroup.Item>
                                        </ListGroup>
                                      </div>
                                    );
                                  })}
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="1">
                                {" "}
                                Payment Information{" "}
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                  <div className="content-wrapper">
                                    <ListGroup variant="flush">
                                      <ListGroup.Item>{`Status - ${invoice.status}`}</ListGroup.Item>
                                      {/* <ListGroup.Item>{`Card Holder - ${invoice.confirmation.cardHolderName}`}</ListGroup.Item>
                                      <ListGroup.Item>{`Card type - ${invoice.confirmation.cardType}`}</ListGroup.Item>
                                      <ListGroup.Item>{`Paid amount - ${invoice.confirmation.paidAmount}`}</ListGroup.Item> */}
                                    </ListGroup>
                                  </div>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          </Accordion>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  );
                })}
              </Accordion>
            </Col>
            <Col>
              <Accordion>
                {props.invoices.pending.map(invoice => {
                  return (
                    <Card key={invoice.cref}>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey={invoice.cref}
                      >
                        <Row>
                          <Col>{invoice.cref}</Col>
                          <Col>{formatteredDate(invoice.date)}</Col>
                          <Col>
                            <div className="px-2">
                              {invoice.status === "PENDING_PAYMENT" ? (
                                  <span className={`badge badge-warning`}>
                                      PENDING
                                  </span>
                              ) : invoice.status === "PENDING_APPROVAL" ? (
                                  <span className={`badge badge-info`}>
                                      PENDING APPROVAL
                                  </span>
                              ) : (
                                  <span className={`badge badge-danger`}>
                                      FAILED
                                  </span>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={invoice.cref}>
                        <Card.Body>
                          <Accordion>
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="0">
                                {" "}
                                Slots{" "}
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                  {invoice.cerebroslots.map(slot => {
                                    return (
                                      <div
                                        className="content-wrapper"
                                        key={slot.id}
                                      >
                                        <ListGroup variant="flush">
                                          <ListGroup.Item>{`Start Date - ${slot.startDate}`}</ListGroup.Item>
                                          <ListGroup.Item>{`Consultant Name - ${slot.consultantFirstName} ${slot.consultantLastName}`}</ListGroup.Item>
                                          <ListGroup.Item>{`Start Time - ${slot.startTime}`}</ListGroup.Item>
                                          <ListGroup.Item>{`End Time - ${slot.endTime}`}</ListGroup.Item>
                                          <ListGroup.Item>{`Slot Price - ${slot.slotPrice.toLocaleString()}`}</ListGroup.Item>
                                          <ListGroup.Item>{`Slot Size - ${slot.slotSize}min`}</ListGroup.Item>
                                        </ListGroup>
                                      </div>
                                    );
                                  })}
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          </Accordion>
                          {invoice.status === "PENDING_PAYMENT" ? (
                            <div className="row">
                              <div className="col-4">
                                <button
                                  onClick={() => props.pay(invoice.id)}
                                  type="button"
                                  className="btn btn-default btn-md text-bold fa-2x"
                                >
                                  {" "}
                                  Pay for Booking{" "}
                                  <em className="fas fa-credit-card fa-md ml-2" />{" "}
                                </button>
                              </div>
                              <div className="col-4">
                                <button
                                  onClick={() => props.cancel(invoice.id)}
                                  type="button"
                                  className="btn btn-danger btn-md text-bold fa-2x"
                                >
                                  {" "}
                                  Cancel Booking{" "}
                                  <em className="fas fa-trash-alt fa-md ml-2" />
                                </button>
                              </div>
                            </div>
                          ) : invoice.status === "PENDING_APPROVAL" ? (
                            <div className="row">
                                {/* <div className="col-4">
                                    <button
                                        onClick={() => props.approve(invoice.id)}
                                        type="button"
                                        className="btn btn-success btn-md text-bold fa-2x"
                                    >
                                        {" "}
                                        Approve Booking{" "}
                                        <em className="fas fa-check fa-md ml-2" />{" "}
                                    </button>
                                </div>
                                <div className="col-4">
                                    <button
                                        onClick={() => props.reject(invoice.id)}
                                        type="button"
                                        className="btn btn-danger btn-md text-bold fa-2x"
                                    >
                                        {" "}
                                        Reject Booking{" "}
                                        <em className="fas fa-times fa-md ml-2" />
                                    </button>
                                </div> */}
                            </div>
                        ) : null}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  );
                })}
              </Accordion>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}