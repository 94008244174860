import axios from "axios";
import { API_URL } from "../Constants.js";

class ConsultantCategoryDataService {
  retrieveAllConsultantCategory() {
    return axios.get(`${API_URL}/consultantCategory`);
  }

  retrieveConsultantCategory(id) {
    return axios.get(`${API_URL}/consultantCategory/${id}`);
  }

  deleteConsultantCategory(id) {
    return axios.delete(`${API_URL}/consultantCategory/${id}`);
  }

  updateConsultantCategory(id, ConsultantCategory) {
    return axios.put(
      `${API_URL}/consultantCategory/${id}`,
      ConsultantCategory
    );
  }

  createConsultantCategory(ConsultantCategory) {
    return axios.post(`${API_URL}/consultantCategory/`, ConsultantCategory);
  }
}

export default new ConsultantCategoryDataService();
