import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class Footer extends Component {
  render() {
    const currentYear = new Date().getFullYear();
    return (
      <div>
        <footer className="card-body ml-0 bg-dark">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12 text-left">
                  <h2 className="text-white">Quick Links</h2>
                  <hr className="border-warning my-4" />
                  <ul className="list-unstyled">
                    <li>
                      <NavLink className="text-white" to="/">
                        Home
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="text-white" to="/aboutus">
                        Who We Are
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="text-white" to="/services">
                        Services
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="text-white" to="/services">
                        FAQ
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="text-white" to="/contactus">
                        Contact Us
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="text-white" to="/privacy-policy">
                        Privacy Policy
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="text-white" to="/refund-policy">
                        Refund Policy
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="text-white" to="/terms-conditions">
                        Terms and Conditions
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="text-white" to="/login">
                        Login
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12 text-left">
                  <h2 className="text-white">Contact Info</h2>
                  <hr className="border-warning my-4" />
                  <p className="text-white push-top-30">
                    172/C ,<br /> Pannipitiya Road ,<br /> Thalawathugoda,
                    Colombo, <br />
                    Sri Lanka
                  </p>
                  <p className="">
                    <a className="text-warning text-left  push-top-10 pb-3" href="mailto:info@cerebrozone.com">
                      info@cerebrozone.com
                    </a>
                  </p>

                  <p className="text-white text-left  push-top-10">
                    Hotline : +9477 99 33 288
                  </p>
                  <p className="text-white text-left  push-top-10">
                    <a href="http://wa.me/94779933288" target="_blank" rel="noopener noreferrer" >
                      Whatsapp Us
                    </a>
                  </p>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12 text-left">
                  <h2 className="text-white">Cerebrozone</h2>
                  <hr className="border-warning my-4" />
                  <p className="text-white push-top-30">
                    If you are at need that calls for sound advice, contact us
                    today to arrange a consultation with a consultant who will
                    provide you the solution up front.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-md-12">
              <p className="text-white push-top-30">
                Made with <span className="text-danger">❤</span> by{" "}
                <a href="https://www.autusa.lk" color="inherit"> Autusa </a>{" "} &copy; 2018 - {currentYear}
              </p>
            </div>
          </div>
          {/*<div class="container copy-rights">*/}
          {/*	<p class="copy">Powered By <a href="http://redomni.com" target="_blank">Red<span class="red">Omni</span></a></p>*/}
          {/*</div>*/}
        </footer>
      </div>
    );
  }
}

export default Footer;
