import React from 'react'
import { Button } from 'react-bootstrap';
import { Constants } from '../../Common';
import Pagination from '../../common/pagination/Pagination';
import TDField from '../../common/container/TDField';
import { getNumberWithUnitsForGridView } from '../../../utils/GridUtil';

export default function InvoiceResult(props) {

    const { invoiceList, statusList, pagedInvoiceData } = props;
    const { onItemClick, onPageInfoChange } = props;

    const hasData = invoiceList && invoiceList.length > 0;

    const statusMap = statusList.reduce((map, item) => {
        map[item.value] = item.name;
        return map;
    }, {});

    return (
        <>
            <div>
                <section className="section-container ml-0">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-12">
                                <div className=" card-default">
                                    {
                                        hasData &&
                                        <div className="row">
                                            <div className="col-md-6"></div>
                                            <div className="col-md-6">
                                                <Pagination
                                                    classNameForWrapper={'float-right'}
                                                    currentPageNo={pagedInvoiceData.currentPageNo}
                                                    totalNoOfPages={pagedInvoiceData.totalNoOfPages}
                                                    onPaginationChange={onPageInfoChange}
                                                />
                                            </div>
                                        </div>
                                    }
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="list-group mb-3">
                                                <div className="list-group-item">
                                                    {!hasData && <div className="alert alert-info">No data found</div>}
                                                    {hasData &&
                                                        <table className="wd-wide table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Invoice Date</th>
                                                                    <th>Consultant Name</th>
                                                                    <th>Consultee Name</th>
                                                                    <th>Total Amount</th>
                                                                    <th>Cerebrozone Amount</th>
                                                                    <th>Invoice Status</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    invoiceList.map((invoice, index) => {
                                                                        return (
                                                                            <tr style={{ height: "30px" }} key={index} className='clickable-row'>
                                                                                <TDField value={index + 1} />
                                                                                <TDField value={invoice.invoiceDate} />
                                                                                <TDField value={invoice.consultantName} />
                                                                                <TDField value={invoice.consulteeName} />
                                                                                <TDField value={getNumberWithUnitsForGridView(invoice.totalAmount, Constants.CURRENCY_CONST.LKR)} />
                                                                                <TDField value={getNumberWithUnitsForGridView(invoice.cerebrozoneAmount, Constants.CURRENCY_CONST.LKR)} />
                                                                                <TDField value={Constants.INVOICE_STATUS[statusMap[invoice.invoiceStatus]]} />
                                                                                <td>
                                                                                    <div className="px-2">
                                                                                        <Button
                                                                                            variant="info"
                                                                                            size="lg"
                                                                                            className="m-0 float-left"
                                                                                            onClick={() => onItemClick(invoice)}
                                                                                        >
                                                                                            View
                                                                                        </Button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
