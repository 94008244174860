import axios from "axios";
import { API_URL } from "../Constants.js";
import authHeader from "./AuthHeaders.js";

class CerebroSlotDataService {
  retrieveAllCerebroSlot() {
    return axios.get(`${API_URL}/cerebroslot`, { headers: authHeader() });
  }

  retrieveAllCerebroSlotByConsultant(consultantId) {
    return axios.get(`${API_URL}/cerebroslot/consultant/${consultantId}`, { headers: authHeader() });
  }

  retrieveAlPagedCerebroSlotByConsultant(consultantId, page) {
    return axios.get(`${API_URL}/consultant/paged/${consultantId}/${page}`, { headers: authHeader() });
  }

  retrieveAllCerebroSlotByConsultantAndDate(consultantId, date) {
    return axios.get(`${API_URL}/cerebroslot/consultantAndDate/${consultantId}/${date}`, { headers: authHeader() });
  }

  retrieveCerebroSlot(id) {
    return axios.get(`${API_URL}/cerebroslot/${id}`, { headers: authHeader() });
  }

  deleteCerebroSlot(id) {
    return axios.delete(`${API_URL}/cerebroslot/${id}`, { headers: authHeader() });
  }

  updateCerebroSlot(id, ConsultantCategory) {
    return axios.put(`${API_URL}/cerebroslot/${id}`, ConsultantCategory, { headers: authHeader() });
  }

  createCerebroSlot(slot) {
    return axios.post(`${API_URL}/cerebroslot`, slot, { headers: authHeader() });
  }

  getCelebroSlots(limit, page) {
    return axios.get(`${API_URL}/cerebroslot?limit=${limit}&page=${page}`, { headers: authHeader() });
  }

  getCelebroSlotsBy(consultantId, date = null/** yyyy-MM-dd */) {
    console.log(consultantId, date)
    const params = {
      consultantId: consultantId
    }
    if (date != null) {
      params['date'] = date
    }
    return axios.get(`${API_URL}/cerebroslot`, { params: params, headers: authHeader() })
  }
}

export default new CerebroSlotDataService();
