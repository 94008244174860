import { jwtDecode } from 'jwt-decode';
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { SET_LOGGED_IN_USER_DATA } from './redux/actionTypes';

export const AuthContext = ({ children }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    setLoggedInUserData();
  }, []);


  function setLoggedInUserData() {
    let user = localStorage.getItem("user");
    if (user !== undefined && user !== null) {
      user = JSON.parse(user);
      if (user.token !== undefined && user.token !== null) {
        const decode = jwtDecode(user.token);
        dispatch({ type: SET_LOGGED_IN_USER_DATA, payload: { roles: decode.roles[0] } });
      } else {
        dispatch({ type: SET_LOGGED_IN_USER_DATA, payload: { roles: null } });
      }
    }
  }

  return (
    <>{children}</>
  )
}
