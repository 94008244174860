import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import { getNumberForGridView, getNumberWithUnitsForGridView } from '../../../utils/GridUtil';
import { Constants } from '../../Common';
import { InputField } from '../../common/input/InputField';

export default function ConsultantSearch(props) {


    const { searchData, statusList, categoryList, currentRoleView } = props;
    const { setSearchData, resetSearchData, onSearch } = props;


    const onChangeInput = (name, value) => {
        setSearchData(name, value)
    }

    return (
        <div>
            <section className="section-container ml-0">
                <div className="content-wrapper">
                    <div className="row">
                        <div className="col-12">
                            <div className=" card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <Row>
                                                <Col md={3}>
                                                    <label className="col-md-12 col-form-label"> {" "} First Name {" "} </label>
                                                    <InputField
                                                        value={searchData.firstName}
                                                        pattern={Constants.INPUT_PATTERN.LETTERS_AND_SPACES_ONLY}
                                                        onChange={e => onChangeInput("firstName", e.target.value)}
                                                        placeholder={"Enter First Name"}
                                                        className={"form-control form-control-rounded"}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <label className="col-md-12 col-form-label"> {" "} Last Name {" "} </label>
                                                    <InputField
                                                        value={searchData.lastName}
                                                        pattern={Constants.INPUT_PATTERN.LETTERS_AND_SPACES_ONLY}
                                                        onChange={e => onChangeInput("lastName", e.target.value)}
                                                        placeholder={"Enter Last Name"}
                                                        className={"form-control form-control-rounded"}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <label className="col-md-12 col-form-label"> {" "} Email {" "} </label>
                                                    <InputField
                                                        value={searchData.email}
                                                        onChange={e => onChangeInput("email", e.target.value)}
                                                        placeholder={"Enter Email"}
                                                        className={"form-control form-control-rounded"}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <label className=" col-form-label"> {" "} Speciality {" "} </label>
                                                    <select className="form-control form-control-rounded"
                                                        value={searchData.categoryId}
                                                        onChange={(e) => {
                                                            onChangeInput("categoryId", e.target.value)
                                                        }}>
                                                        <option key={"default"} value={""}>All</option>
                                                        {
                                                            categoryList.map((item, index) =>
                                                                <option key={index} value={item.id}>{item.name}</option>
                                                            )
                                                        }
                                                    </select>
                                                </Col>
                                                <Col md={3}>
                                                    <label className=" col-form-label"> {" "} Slot Size <b>{getNumberWithUnitsForGridView(searchData.slotSize, "Mins")}</b> </label>
                                                    <RangeSlider
                                                        value={getNumberForGridView(searchData.slotSize)}
                                                        onChange={e =>
                                                            onChangeInput("slotSize", e.target.value)
                                                        }
                                                        min={0}
                                                        max={120}
                                                        step={15}
                                                        variant="danger"
                                                        tooltipPlacement="top"
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <label className=" col-form-label"> {" "} Slot Rate <b>{getNumberWithUnitsForGridView(searchData.slotRate, "LKR")}</b> </label>
                                                    <RangeSlider
                                                        value={getNumberForGridView(searchData.slotRate)}
                                                        onChange={e =>
                                                            onChangeInput("slotRate", e.target.value)
                                                        }
                                                        min={0}
                                                        max={5000}
                                                        step={500}
                                                        variant="danger"
                                                        tooltipPlacement="top"
                                                    />
                                                </Col>
                                                {
                                                    currentRoleView === Constants.ROLE_CONST.ADMIN &&
                                                    <Col md={3}>
                                                        <label className=" col-form-label"> {" "} Status {" "} </label>
                                                        <select className="form-control form-control-rounded"
                                                            value={searchData.status}
                                                            onChange={(e) => {
                                                                onChangeInput("status", e.target.value)
                                                            }}>
                                                            {
                                                                statusList.map((item, index) =>
                                                                    <option key={index} value={item.value}>{item.name}</option>
                                                                )
                                                            }
                                                        </select>
                                                    </Col>
                                                }
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Button
                                                variant="secondary"
                                                size="lg"
                                                onClick={() => resetSearchData()}
                                                className="m-2 float-right"
                                            >
                                                Reset
                                            </Button>
                                            <Button
                                                variant="success"
                                                size="lg"
                                                onClick={() => onSearch()}
                                                className="m-2 float-right"
                                            >
                                                Search
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
