import React from 'react'

function DatePicker(props) {

    const onChange = (e) => {
        props.onChange(e);
    }

    return (
        <input
            type='date'
            value={props.value ? props.value : ''}
            onChange={e =>
                onChange(e)
            }
            className={props.className ? props.className : ''}
            autoComplete={props.autoComplete ? props.autoComplete : 'off'}
            required={props.required}
        />
    )
}

export default DatePicker