import React from 'react'
import { Constants } from '../../Common';

export const InputField = (props) => {

    const onChange = (e) => {

        if (props.pattern) {
            let inputValue = e.target.value;
            switch (props.pattern) {
                case Constants.INPUT_PATTERN.LETTERS_AND_SPACES_ONLY: {
                    let lettersAndSpaceOnly = inputValue.replace(/[^a-zA-Z ]/g, '');
                    e.target.value = lettersAndSpaceOnly;
                    break;
                }
                case Constants.INPUT_PATTERN.LETTERS_ONLY: {
                    let lettersOnly = inputValue.replace(/[^a-zA-Z]/g, '');
                    e.target.value = lettersOnly;
                    break;
                }
                case Constants.INPUT_PATTERN.NUMBERS_ONLY: {
                    let numberOnly = inputValue.replace(/[^0-9]/g, '');
                    e.target.value = numberOnly;
                    break;
                }
            }
        }
        props.onChange(e)
    }


    return (
        <input
            value={props.value ? props.value : ''}
            onChange={e =>
                onChange(e)
            }
            className={props.className ? props.className : ''}
            autoComplete={props.autoComplete ? props.autoComplete : 'off'}
            required={props.required}
            type={props.type ? props.type : 'text'}
            maxLength={props.maxLength ? props.maxLength : undefined}
            placeholder={props.placeholder ? props.placeholder : ''}
        />
    )
}
