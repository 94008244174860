export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOG_OUT = "LOG_OUT";
export const SET_CONSULTANT = "SET_CONSULTANT";
export const SET_CONSULTEE = "SET_CONSULTEE";
export const SET_ADMIN = "SET_ADMIN";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SET_LOGGED_IN_USER_DATA = "SET_LOGGED_IN_USER_DATA";
export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";
