import axios from "axios";
import React, { Component } from "react";
import { withAlert } from "react-alert";
import RangeSlider from "react-bootstrap-range-slider";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import { connect } from "react-redux";
import { API_URL } from "../Constants";
import ConsultantCategoryDataService from "../api/ConsultantCategoryDataService";
import ConsultantDataService from "../api/ConsultantDataService";
import UserDataService from "../api/UserDataService";
import {
  HIDE_LOADING,
  SET_CONSULTANT,
  SHOW_LOADING
} from "../redux/actionTypes";
import { Constants, timezones } from "./Common";
import DatePicker from "./common/datepicker/DatePicker";
import { InputField } from "./common/input/InputField";
import PhoneNoInput from "./common/input/PhoneNoInput";

class ConsultantForm extends Component {
  constructor() {
    super();
    this.state = {
      firstName: "",
      lastName: "",
      displayName: "",
      birthday: "",
      gender: "",
      country: "Sri Lanka",
      timeZone: "5.5",
      currency: "LKR",
      category: "default",
      experience: "",
      description: "",
      email: "",
      dialCode: "",
      phone: "",
      accNumber: "",
      bank: "",
      bankBranch: "",
      slotSize: "0",
      slotRate: "0",
      succuss: false,
      errors: {},
      show: false,
      consultantCategories: [],
      selectedFile: null,
      imageName: "",
      status: Constants.CONSULTANT_STATUS_CONST.PENDING_APPROVAL
    };
    this.save = this.save.bind(this);
    this.validate = this.validate.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }
  componentDidMount() {
    ConsultantCategoryDataService.retrieveAllConsultantCategory().then(res => {
      if (res && res.status === 200) {
        this.setState({ consultantCategories: res.data });
      }
    });
    if (
      this.props.consultantId !== null &&
      this.props.consultantId !== undefined
    ) {
      ConsultantDataService.getConsultant(this.props.consultantId).then(res => {
        if (res && res.status === 200 && res.data) {
          const data = res.data;
          this.props.dispatch({ type: SET_CONSULTANT, payload: res.data });
          this.setState({
            firstName: data.firstName,
            lastName: data.lastName,
            displayName: data.displayName,
            birthday: data.birthday ? data.birthday : "",
            gender: data.gender,
            country: data.country,
            timeZone: data.timeZone,
            currency: data.currency,
            category: data.categoryId,
            experience: data.experience,
            description: data.about,
            // email: data.email,
            phone: data.phone,
            dialCode: data.dialCode ? data.dialCode : Constants.DEFAULT_COUNTRY_FOR_PHONE_NO.dialCode,
            accNumber: data.accNumber,
            bank: data.bank,
            bankBranch: data.bankBranch,
            slotSize: data.slotSize,
            slotRate: data.slotRate,
            imageName: data.imageName,
            status: data.status ? data.status : Constants.CONSULTANT_STATUS_CONST.PENDING_APPROVAL
          });
        }
      });
    }
  }

  onFileChange = event => {
    this.setState({
      selectedFile: event.target.files[0]
    });
  }

  uploadFile(callback, context) {
    if (this.state.selectedFile) {
      console.log(this.state.selectedFile)
      let formData = new FormData();
      formData.append('image', this.state.selectedFile, this.state.selectedFile.name);

      axios.post(
        `${API_URL}/upload/image`,
        formData,
      )
        .then(res => {
          callback(res.data, context)
        })
        .catch(err => {
          console.log(err);
        })
    } else {
      callback(null, context)
    }
  }

  save() {


    this.setState({ errors: {} });
    let consultant = {
      userId: UserDataService.getLoggedInUserId(),
      firstName: String(this.state.firstName),
      lastName: String(this.state.lastName),
      displayName: this.state.displayName ? this.state.displayName : (this.state.firstName + " " + this.state.lastName),
      birthday: String(this.state.birthday),
      gender: String(this.state.gender),
      country: String(this.state.country),
      timeZone: String(this.state.timeZone),
      currency: String(this.state.currency),
      categoryId: String(this.state.category),
      experience: Number(this.state.experience),
      about: String(this.state.description),
      phone: String(this.state.phone),
      dialCode: String(this.state.dialCode),
      bank: String(this.state.bank),
      bankBranch: String(this.state.bankBranch),
      accNumber: String(this.state.accNumber),
      slotSize: Number(this.state.slotSize),
      slotRate: Number(this.state.slotRate),
      email: String(this.state.email),
      status: this.state.status
    };
    if (
      consultant.firstName !== "" &&
      consultant.lastName !== "" &&
      consultant.gender !== "" &&
      consultant.birthday !== "" &&
      consultant.categoryId !== "" &&
      !isNaN(consultant.experience) &&
      consultant.description !== "" &&
      !isNaN(consultant.phone) &&
      !isNaN(consultant.accNumber) &&
      consultant.bank !== "" &&
      consultant.bankBranch !== "" &&
      Number(consultant.slotSize) !== 0 &&
      Number(consultant.slotRate) !== 0
      // &&
      // consultant.email !== ""
    ) {
      this.props.dispatch({ type: SHOW_LOADING });


      this.uploadFile(function (image, context) {
        if (context.props.consultantId !== null) {
          if (image) {
            consultant['imageName'] = image
          } else {
            consultant['imageName'] = context.state.imageName
          }
          ConsultantDataService.updateConsultant(
            context.props.consultantId,
            consultant
          )
            .then(res => {
              context.props.dispatch({ type: HIDE_LOADING });
              if (res && res.status === 200 && res.data !== "") {
                context.props.dispatch({ type: SET_CONSULTANT, payload: res.data });
                UserDataService.registerSuccessfulLogin(context.props.user);
                context.props.alert.success("Update successful.");
              }
            })
            .catch(e => {
              context.props.dispatch({ type: HIDE_LOADING });
            });
        } else {
          if (image) {
            consultant['imageName'] = image
          } else {
            consultant['imageName'] = ''
          }

          ConsultantDataService.createConsultant(consultant)
            .then(res => {
              context.props.dispatch({ type: HIDE_LOADING });
              if (res && res.status === 200 && res.data !== "") {
                context.props.dispatch({ type: SET_CONSULTANT, payload: res.data });
                UserDataService.registerSuccessfulLogin(context.props.user);
                context.props.history.push("/consultantdashboard");
              }
            })
            .catch(e => {
              context.props.dispatch({ type: HIDE_LOADING });
            });
        }
      }, this)

    } else {
      this.validate();
    }
  }
  validate() {
    let oldErrors = {};
    this.setState({ errors: oldErrors });
    let errors = {};
    if (!this.state.firstName) {
      errors.firstName = "Cannot leave First Name empty ";
    }
    if (!this.state.lastName) {
      errors.lastName = "Cannot leave Last Name empty";
    }
    if (!this.state.birthday) {
      errors.birthday = "Cannot leave birthday empty";
    }
    if (!this.state.gender) {
      errors.gender = "Please select your gender";
    }
    if (!this.state.experience) {
      errors.experience = "Cannot leave experience empty";
    }

    if (!this.state.category || this.state.category === "default") {
      errors.category = "Cannot leave category empty";
    }

    if (!this.state.description) {
      errors.description = "Cannot leave Description empty";
    }
    // if (!this.state.email) {
    //   errors.email = "Cannot leave E-mail empty";
    // }
    if (!this.state.phone) {
      errors.phone = "Cannot leave Phone empty";
    }
    if (!this.state.accNumber) {
      errors.accNumber = "Cannot leave Account Number empty";
    }
    if (isNaN(this.state.accNumber)) {
      errors.accNumber = "Account number contains string values";
    }
    if (!this.state.bank) {
      errors.bank = "Cannot leave Bank empty";
    }
    if (!this.state.bankBranch) {
      errors.bankBranch = "Cannot leave Bank Branch empty";
    }
    if (Number(this.state.slotSize) === 0) {
      errors.slotSize = "Please Enter a Slot Size more than 0";
    }
    if (Number(this.state.slotRate) === 0) {
      errors.slotRate = "Please Enter a Slot Rate more than 0";
    }
    this.setState({ errors: errors });
  }
  render() {
    return (
      <div>
        <section className="section-container ml-0">
          {/* Page content*/}
          <div className="content-wrapper">
            <div className="content-heading">
              <div>
                Consultant Profile
                <small data-localize="dashboard.WELCOME" />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className=" card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-10 offset-md-1 align-self-center">
                        <h3 className="text-center mb-3">Consultant Profile</h3>
                        <p className="text-center mb-3">
                          {" "}
                          Enter Your Details to Sign Up{" "}
                        </p>
                        {/* <Alert
                          show={this.state.show}
                          variant="success"
                          onClick={() =>
                            this.props.history.push(`/consultantdashboard`)
                          }
                          dismissible>
                          <p style={{ aligntText: "center", width: "100%" }}>
                            {" "}
                            Your Profile Updated Successfully!!!{" "}
                          </p>
                        </Alert> */}
                        <form className="mb-3" id="loginForm" noValidate>
                          <div className="row">
                            <div className="col-md-4 col-lg-3">
                              <div className="form-group">
                                <label className="ml-3">First Name</label>
                                <div className="input-group with-focus">
                                  <InputField
                                    value={this.state.firstName}
                                    pattern={Constants.INPUT_PATTERN.LETTERS_AND_SPACES_ONLY}
                                    onChange={e =>
                                      this.setState({
                                        ...this.state,
                                        firstName: e.target.value,
                                      })
                                    }
                                    placeholder={"Enter First Name"}
                                    className={"form-control form-control-rounded"}
                                    autoComplete={"off"}
                                    required={true}
                                  />
                                </div>
                                <p style={{ color: "#FF0000" }}>
                                  {" "}
                                  {this.state.errors.firstName}{" "}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                              <div className="form-group">
                                <label className="ml-3">Last Name</label>
                                <div className="input-group with-focus">
                                  <InputField
                                    value={this.state.lastName}
                                    pattern={Constants.INPUT_PATTERN.LETTERS_AND_SPACES_ONLY}
                                    onChange={e =>
                                      this.setState({
                                        ...this.state,
                                        lastName: e.target.value
                                      })
                                    }
                                    placeholder={"Enter Last Name"}
                                    className={"form-control form-control-rounded"}
                                    autoComplete={"off"}
                                    required={true}
                                  />
                                </div>
                                <p style={{ color: "#FF0000" }}>
                                  {" "}
                                  {this.state.errors.lastName}{" "}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                              <div className="form-group">
                                <label className="ml-3">Display Name</label>
                                <div className="input-group with-focus">
                                  <InputField
                                    value={this.state.displayName}
                                    pattern={Constants.INPUT_PATTERN.LETTERS_AND_SPACES_ONLY}
                                    onChange={e =>
                                      this.setState({
                                        ...this.state,
                                        displayName: e.target.value
                                      })
                                    }
                                    className={"form-control form-control-rounded"}
                                    autoComplete={"off"}
                                    required={true}
                                    placeholder={this.state.firstName + " " + this.state.lastName}
                                  />
                                </div>
                                <p style={{ color: "#FF0000" }}>
                                  {this.state.errors.displayName}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                              <div className="form-group">
                                <label className="ml-3">Birthday</label>
                                <div className="input-group with-focus">
                                  <DatePicker
                                    value={this.state.birthday}
                                    onChange={e =>
                                      this.setState({
                                        ...this.state,
                                        birthday: e.target.value
                                      })
                                    }
                                    className={"form-control form-control-rounded"}
                                    autoComplete={"off"}
                                    required={true}
                                  />
                                </div>
                                <p style={{ color: "#FF0000" }}>
                                  {" "}
                                  {this.state.errors.birthday}{" "}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                              <div className="form-group">
                                <label className="ml-3">Gender</label>
                                <div className="input-group with-focus">
                                  <div className="input-group with-focus">
                                    <div className="form-check form-check-inline ml-3">
                                      <input
                                        className="form-check-input"
                                        id="inlineRadio1"
                                        type="radio"
                                        name="inlineRadioOptions"
                                        defaultValue="option1"
                                        checked={this.state.gender === "Male"}
                                        onChange={() =>
                                          this.setState({ gender: "Male" })
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="inlineRadio1"
                                      >
                                        {" "}
                                        Male{" "}
                                      </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        id="inlineRadio2"
                                        type="radio"
                                        name="inlineRadioOptions"
                                        defaultValue="option1"
                                        checked={this.state.gender === "Female"}
                                        onChange={() =>
                                          this.setState({ gender: "Female" })
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="inlineRadio1"
                                      >
                                        Female
                                      </label>
                                    </div>
                                  </div>
                                  <p style={{ color: "#FF0000" }}>
                                    {this.state.errors.gender}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                              <div className="form-group">
                                <label className="ml-3">Country</label>
                                <div className="input-group with-focus">
                                  <select
                                    className="form-control form-control-rounded"
                                    onChange={e =>
                                      this.setState({ country: e.target.value })
                                    }
                                    defaultValue={this.state.country}
                                  >
                                    <option value={"Sri Lanka"}>
                                      Sri Lanka
                                    </option>
                                    {/* <option value={"India"}>India</option>
                                    <option value={"United States"}>
                                      United States
                                    </option>
                                    <option value={"United Kingdom"}>
                                      United Kingdom
                                    </option> */}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                              <div className="form-group">
                                <label className="ml-3">Time Zone</label>
                                <div className="input-group with-focus">
                                  <select
                                    onChange={e =>
                                      this.setState({
                                        timeZone: e.target.value
                                      })
                                    }
                                    defaultValue={this.state.timeZone}
                                    className="form-control form-control-rounded"
                                  >
                                    {timezones.reverse().map(item => {
                                      return (
                                        <option
                                          key={item.text}
                                          value={item.offset}
                                        >
                                          {item.text}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                              <div className="form-group">
                                <label className="ml-3">Currency</label>
                                <div className="input-group with-focus">
                                  <select
                                    className="form-control form-control-rounded"
                                    onChange={e =>
                                      this.setState({
                                        currency: e.target.value
                                      })
                                    }
                                    defaultValue={this.state.currency}
                                  >
                                    <option value={"LKR"}>LKR</option>
                                    {/* <option value={"USD"}>USD</option>
                                    <option value={"EUR"}>EUR</option> */}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                              <div className="form-group">
                                <label className="ml-3">Category</label>
                                <div className="input-group with-focus">
                                  <select
                                    // defaultValue={this.state.category}
                                    value={this.state.category}
                                    className="form-control form-control-rounded"
                                    onChange={e =>
                                      this.setState({
                                        category: e.target.value
                                      })
                                    }
                                  >
                                    <option value={"default"} disabled>
                                      Select A Consultant Category
                                    </option>
                                    {this.state.consultantCategories.map(
                                      consultantCategory => (
                                        <option
                                          key={consultantCategory.id}
                                          value={consultantCategory.id}
                                        >
                                          {consultantCategory.name}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                                <p style={{ color: "#FF0000" }}>
                                  {" "}
                                  {this.state.errors.category}{" "}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                              <div className="form-group">
                                <label className="ml-3">
                                  Years of Experience
                                </label>
                                <div className="input-group with-focus">
                                  <InputField
                                    value={this.state.experience}
                                    pattern={Constants.INPUT_PATTERN.NUMBERS_ONLY}
                                    onChange={e =>
                                      this.setState({
                                        ...this.state,
                                        experience: e.target.value
                                      })
                                    }
                                    className={"form-control form-control-rounded"}
                                    autoComplete={"off"}
                                    required={true}
                                    placeholder={"Enter Your Experience"}
                                  />
                                </div>
                                <p style={{ color: "#FF0000" }}>
                                  {this.state.errors.experience}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                              <div className="form-group">
                                <label className="ml-3">Description</label>
                                <div className="input-group with-focus">
                                  <input
                                    className="form-control form-control-rounded"
                                    placeholder={"Enter a Description"}
                                    value={this.state.description}
                                    onChange={e =>
                                      this.setState({
                                        description: e.target.value
                                      })
                                    }
                                    // type="email"
                                    autoComplete="off"
                                    required
                                  />
                                </div>
                                <p style={{ color: "#FF0000" }}>
                                  {this.state.errors.description}
                                </p>
                              </div>
                            </div>
                            {/* <div className="col-md-4 col-lg-3">
                              <div className="form-group">
                                <label className="ml-3">Profile Picture</label>
                                <div className="input-group with-focus">
                                  <input
                                    className="form-control form-control-rounded"
                                    type="file"
                                    autoComplete="off"
                                    required
                                  />
                                </div>
                              </div>
                            </div> */}
                            <div className="col-md-4 col-lg-3">
                              <div className="form-group">
                                <label className="ml-3">Email</label>
                                <div className="input-group with-focus">
                                  <input
                                    className="form-control form-control-rounded"
                                    placeholder={"Enter Your E-mail"}
                                    value={this.props.email}
                                    type="email"
                                    autoComplete="off"
                                    // required
                                    disabled
                                  />
                                </div>
                                <p style={{ color: "#FF0000" }}>
                                  {this.state.errors.email}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                              <div className="form-group">
                                <label className="ml-3">Phone</label>
                                <div className="input-group with-focus">
                                  <PhoneNoInput
                                    dialCode={this.state.dialCode}
                                    phoneNo={this.state.phone}
                                    onChange={(value, data) => {
                                      this.setState({
                                        ...this.state,
                                        dialCode: data.dialCode,
                                        phone: value ? value.slice(data.dialCode.length) : ''
                                      });
                                    }}
                                  />
                                </div>
                                <p style={{ color: "#FF0000" }}>
                                  {this.state.errors.phone}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                              <div className="form-group">
                                <label className="ml-3">Bank</label>
                                <div className="input-group with-focus">
                                  <input
                                    className="form-control form-control-rounded"
                                    placeholder={"Enter Your Bank "}
                                    value={this.state.bank}
                                    onChange={e =>
                                      this.setState({ bank: e.target.value })
                                    }
                                    // type="email"
                                    autoComplete="off"
                                    required
                                  />
                                </div>
                                <p style={{ color: "#FF0000" }}>
                                  {this.state.errors.bank}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                              <div className="form-group">
                                <label className="ml-3">Bank Branch</label>
                                <div className="input-group with-focus">
                                  <input
                                    className="form-control form-control-rounded"
                                    placeholder={"Enter Your Bank Branch "}
                                    value={this.state.bankBranch}
                                    onChange={e =>
                                      this.setState({
                                        bankBranch: e.target.value
                                      })
                                    }
                                    // type="email"
                                    autoComplete="off"
                                    required
                                  />
                                </div>
                                <p style={{ color: "#FF0000" }}>
                                  {this.state.errors.bankBranch}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                              <div className="form-group">
                                <label className="ml-3">ACC Number</label>
                                <div className="input-group with-focus">
                                  <input
                                    className="form-control form-control-rounded"
                                    placeholder={"Enter Your Account Number "}
                                    value={this.state.accNumber}
                                    onChange={e =>
                                      this.setState({
                                        accNumber: e.target.value
                                      })
                                    }
                                    // type="email"
                                    autoComplete="off"
                                    required
                                  />
                                </div>
                                <p style={{ color: "#FF0000" }}>
                                  {this.state.errors.accNumber}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                              <div className="form-group row">
                                <label className="col-md-12 col-form-label">
                                  Slot Size(Min's)
                                </label>
                                <div className="col-md-12">
                                  <RangeSlider
                                    value={Number(this.state.slotSize)}
                                    onChange={e =>
                                      this.setState({
                                        slotSize: e.target.value
                                      })
                                    }
                                    min={0}
                                    max={120}
                                    step={15}
                                    variant="danger"
                                    tooltipPlacement="top"
                                  />
                                  <h6 className="font-weight-bold text-center mt-2 text-danger">
                                    <span id="slotTime">
                                      {this.state.slotSize}
                                    </span>
                                    min
                                  </h6>
                                </div>
                                <p style={{ color: "#FF0000" }}>
                                  {this.state.errors.slotSize}
                                </p>
                              </div>
                            </div>
                            <div className=" col-md-4 col-lg-3">
                              <div className="form-group row">
                                <label className="col-md-12 col-form-label">
                                  Slot Rate(LKR)
                                </label>
                                <div className="col-md-12">
                                  <RangeSlider
                                    value={Number(this.state.slotRate)}
                                    onChange={e =>
                                      this.setState({
                                        slotRate: e.target.value
                                      })
                                    }
                                    min={0}
                                    max={5000}
                                    step={500}
                                    variant="danger"
                                    tooltipPlacement="top"
                                  />
                                  <h6 className="font-weight-bold text-center mt-2 text-danger">
                                    <span id="slotRate">
                                      0 LKR - {this.state.slotRate}
                                    </span>{" "}
                                    LKR
                                  </h6>
                                </div>
                                <p style={{ color: "#FF0000" }}>
                                  {this.state.errors.slotRate}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                              <div className="form-group">
                                <label className="ml-3">Image</label>
                                <div className="input-group with-focus">
                                  <input
                                    type="file"
                                    accept="image/png, image/jpeg"
                                    onChange={this.onFileChange}
                                    className="form-control form-control-rounded file-select-button"
                                  />
                                </div>
                                <p style={{ color: "#FF0000" }}>
                                  {this.state.errors.phone}
                                </p>
                              </div>
                            </div>
                            <div className=" col-md-12">
                              <button
                                className="btn float-right btn-success mr-2 btn-oval"
                                type="button"
                                onClick={() => this.save()}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.auth.user,
    consultantId: state.auth.consultantId,
    email: state.auth.user !== null ? state.auth.user.email : ""
  };
};

export default connect(mapStateToProps)(withAlert()(ConsultantForm));
