import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import UserDataService from "../api/UserDataService";
import { connect } from "react-redux";
import {
  HIDE_LOADING,
  LOGIN_SUCCESS,
  SHOW_LOADING
} from "../redux/actionTypes";
import { withAlert } from "react-alert";
import { Alert } from 'react-bootstrap';

class Login extends Component {
  state = {
    credentials: {
      username: "",
      password: ""
    },
    error: null
  };

  fieldOnChange(key, value) {
    this.setState({
      credentials: {
        ...this.state.credentials,
        [key]: value
      }
    });
  }

  login = () => {
    const credentials = this.state.credentials;
    if (credentials.username !== "" && credentials.password !== "") {
      this.props.dispatch({ type: SHOW_LOADING });
      UserDataService.login(credentials)
        .then(res => {
          this.props.dispatch({ type: HIDE_LOADING });
          if (res && res.status === 200 && res.data !== "") {
            UserDataService.registerSuccessfulLogin(res.data);
            this.props.dispatch({ type: LOGIN_SUCCESS, payload: res.data });
          }
          if (res && res.status === 400 && res.data !== null) {
            this.props.alert.error(res.response.data.message);
          }
        })
        .catch(e => {
          this.props.dispatch({ type: HIDE_LOADING });
          if (
            e.response &&
            (e.response.status === 400 || e.response.status === 403) &&
            e.response.data !== ""
          ) {
            this.setState({
              error: {
                message: e.response.data.message
              }
            }, () => {
              setTimeout(function () {
                this.setState({
                  error: null
                })
              }.bind(this), 4000);
            })

          }
        });
    }
  };

  render() {
    return (
      <div className="wrapper">
        {/* Main section*/}
        <section className="section-container ml-0">
          {/* Page content*/}
          <div className="content-wrapper">
            <div className="content-heading">
              <div>
                Login
                <small data-localize="dashboard.WELCOME" />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className=" card-default">
                  <div className="card-body">
                    <div className="row">
                      <div
                        className="col-md-8 text-center border-md"
                        style={{ height: "70vh" }}
                      >
                        <div className="parallax">
                          <div className="water login-banner4" />
                          <div className="water login-banner3" />
                          <div className="water login-banner2" />
                          <div className="water login-banner1" />
                        </div>
                        <div className="clearfix" />
                      </div>
                      <div className="col-md-4 align-self-center">
                        <h3 className="text-center mb-3">
                          Access Your Account
                        </h3>
                        <p className="text-center mb-3">
                          Enter Your Details to Login
                        </p>
                        <form className="mb-3" id="loginForm" noValidate>
                          <div className="form-group">
                            <label className="ml-3">Email</label>
                            <div className="input-group with-focus">
                              <input
                                className="form-control form-control-rounded border-right-0"
                                id="name"
                                type="text"
                                placeholder="Enter email"
                                autoComplete="off"
                                required
                                value={this.state.credentials.username}
                                onChange={e =>
                                  this.fieldOnChange("username", e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="ml-3">Password</label>
                            <div className="input-group with-focus">
                              <input
                                className="form-control form-control-rounded border-right-0"
                                id="password"
                                type="password"
                                placeholder="Password"
                                required
                                value={this.state.credentials.password}
                                onChange={e =>
                                  this.fieldOnChange("password", e.target.value)
                                }
                              />
                            </div>
                          </div>
                          {
                            this.state.error !== null ? <Alert key={'danger'} variant={'danger'}>{this.state.error.message}</Alert> : null
                          }

                          <div className="clearfix">
                            <div className="float-right">
                              <a className="text-muted" href="/#/resetpassword">
                                Forgot your password?
                              </a>
                            </div>
                          </div>
                          <button
                            className="btn btn-block btn-primary mt-3 btn-oval"
                            type="button"
                            onClick={this.login}
                          >
                            Login
                          </button>
                          <div
                            className="float-left"
                            style={{ marginTop: "20px" }}
                          >
                            <NavLink to="/">
                              Not Registered? Register Here
                            </NavLink>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Page footer*/}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loggedIn: state.auth.loggedIn
  };
};

export default connect(mapStateToProps)(withAlert()(Login));
