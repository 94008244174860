import { data } from 'jquery'
import React from 'react'
import { NavLink } from 'react-router-dom/cjs/react-router-dom'

export default function ConsultantResultGridView(props) {

    const { dataList } = props

    console.log(dataList)

    return (
        <>
            {dataList.map((consultant) => (
                <div className="col-md-3" key={consultant.id}>
                    <div className="card scale">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-6 text-center">
                                    {" "}
                                    <img style={{ objectFit: 'cover' }} className="img-thumbnail circle img-fluid thumb128" src={consultant.imageUrl ? consultant.imageUrl : "app/img/user/avatar.svg"} alt="d" />
                                </div>
                                <div className="col-6">
                                    <h3 className="m-0">{consultant.firstName}&nbsp;{consultant.lastName}</h3>
                                    <p className="text-muted">{consultant.categoryName}</p>
                                    <ul className="list-unstyled mb-0">
                                        <li className="mb-1">
                                            {" "}
                                            <em className="fa fa-money-bill fa-fw" />{" "}
                                            <span className="text-warning"> {" "} {consultant.slotRate.toLocaleString()}.00{" "} </span>
                                            /{consultant.slotSize}min{" "}
                                        </li>
                                        <li className="mb-1"> {" "} <em className="fas fa-map-marker-alt fa-fw" />{" "} {consultant.country}{" "} </li>
                                    </ul>
                                </div>
                                <div className="col-12"> <hr className="border-warning my-2" /> </div>
                                <div className="col-12">
                                    <span className="badge badge-dark">{consultant.categoryName}</span>
                                </div>
                            </div>
                        </div>
                        <NavLink className="text-white" to={`/profile/${consultant.id}`} >
                            <div className="card-body text-center bg-warning" >
                                <div className="row"> {" "} <div className="col-12"> {" "} <b> View Profile </b>{" "} </div>{" "} </div>
                            </div>
                        </NavLink>
                    </div>
                </div>
            ))}
        </>
    )
}
