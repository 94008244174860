import React from 'react'
import Countdown from "react-countdown";

export default function UpcomingAppointments(props) {
  return (
    <div>
      <section className="section-container ml-0">
        {/* Page content*/}
        <div className="content-wrapper">
          <div className="row">
            <div className="col-12">
              <div className=" card-default">
                <div className="row">
                  <div className="col-12">
                    {props.upcoming.map(item => {
                      return (
                        <div className="list-group mb-3" key={item.id}>
                          <div className="list-group-item">
                            <table className="wd-wide">
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="px-2">
                                      <h4 className="mb-2">
                                        {item.cerebroslotDate}
                                      </h4>
                                      <small className="text-muted"></small>
                                    </div>
                                  </td>
                                  <td className="wd-sm d-lg-table-cell">
                                    <div className="px-2">
                                      <p className="m-0">{item.startTime}</p>
                                      <small className="text-muted">From</small>
                                    </div>
                                  </td>
                                  <td className="wd-sm d-lg-table-cell">
                                    <div className="px-2">
                                      <p className="m-0">{item.endTime}</p>
                                      <small className="text-muted">To</small>
                                    </div>
                                  </td>
                                  <td className="wd-sm d-lg-table-cell">
                                    <div className="px-2">
                                      <p className="m-0">
                                        {item.cerebroslotSize / 60} hour(s)
                                      </p>
                                      <small className="text-muted">
                                        Duration
                                      </small>
                                    </div>
                                  </td>
                                  <td className="wd-lg">
                                    <div className="px-2">
                                      {/* <img
                                        className="img-fluid rounded thumb64 float-left mr-2"
                                        src="app/img/dummy.png" alt="" /> */}
                                      <p className="font-weight-bold pt-3 mb-0">{`${item.consulteeFirstName} ${item.consulteeLastName}`}</p>
                                      <p className="text-muted mb-0"></p>
                                    </div>
                                  </td>
                                  <td className="wd-md">
                                    <div className="px-2">
                                      <Countdown
                                        date={new Date(item.startCountDownTime)}
                                        renderer={renderer => {
                                          // console.log(renderer)
                                          return (
                                            <p className="font-weight-bold mb-0">
                                              <span className="text-warning font-weight-bold text-md">
                                                {renderer.days}{" "}
                                              </span>
                                              Days
                                              <span className="text-warning font-weight-bold text-md">
                                                {`${renderer.hours}`.padStart(2, "0")}:
                                                {`${renderer.minutes}`.padStart(2, "0")}:
                                                {`${renderer.seconds}`.padStart(2, "0")}
                                              </span>
                                              Hrs
                                            </p>
                                          );
                                        }}
                                      />

                                      <small className="text-muted mb-0">
                                        Cerebro Time Starts Within
                                      </small>
                                    </div>
                                  </td>
                                  <td className="wd-md">
                                    <div className="px-2">
                                      <Countdown
                                        date={new Date(item.endCountDownTime)}
                                        renderer={renderer => {
                                          if (renderer.completed) {
                                            props.onComplete()
                                          }
                                          return (
                                            <p className="font-weight-bold mb-0">
                                              <span className="text-warning font-weight-bold text-md">
                                                {renderer.days}{" "}
                                              </span>
                                              Days
                                              <span className="text-warning font-weight-bold text-md">
                                                {`${renderer.hours}`.padStart(2, "0")}:
                                                {`${renderer.minutes}`.padStart(2, "0")}:
                                                {`${renderer.seconds}`.padStart(2, "0")}
                                              </span>
                                              Hrs
                                            </p>
                                          );
                                        }}
                                      />

                                      <small className="text-muted mb-0">
                                        Cerebro Time Ends Within
                                      </small>
                                    </div>
                                  </td>
                                  <td className="wd-xs text-center d-lg-table-cell">
                                    <div className="float-right">
                                      <button
                                        onClick={() => props.call(item.id)}
                                        type="button"
                                        className={`btn btn-lg text-bold fa-2x ${item.showButton ? 'btn-success' : 'btn-dark'}`}
                                        disabled={!item.showButton}
                                      >
                                        {" "}
                                        <em
                                          className="fas fa-video fa-lg ml-1"
                                          style={{ marginRight: 10 }}
                                        />{" "}
                                        Join Call{" "}
                                      </button>
                                    </div>
                                  </td>
                                  {/* <td className="wd-xs text-center d-lg-table-cell">
                                    <div className="px-2">
                                      <button
                                        onClick={() => props.call(item.id)}
                                        className="btn btn-success btn-lg btn-circle animated pulse infinite">
                                        <em className="fa fa-phone fa-lg" />
                                      </button>
                                    </div>
                                  </td> */}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}