import React, { Component } from "react";

export default class PaymentGateInitiate extends Component {

  componentDidMount() {
    if (this.props.location.paymentGateRequest) {
      document.open();
      document.write(this.props.location.paymentGateRequest);
      document.close();
    }
  }



  render() {
    return (
      <div className="wrapper">
        <div className="float-right" style={{ marginRight: 20, marginTop: 20, marginBottom: 20 }}>
          <button
            onClick={() => {
              this.props.history.push('/consulteedashboard')
            }}
            type="button"
            className="btn btn-warning btn-lg text-bold fa-2x"> Back to My Dashboard </button>
        </div>
        <div className="clearfix"></div>
      </div>
    )
  }
}
