import React from 'react'
import { Card, Table, Row, Col } from "react-bootstrap";
import { formatteredDate } from '../Common';

export default function MyEarnings(props) {
  return (
    <>
      <section className="section-container ml-0">
        <div className="content-wrapper">
          <div>
            <div className="input-group col-4 float-right">
              <input
                className="form-control"
                value={props.start}
                type="date"
                onChange={e => props.startChanged(e.target.value)}
              />
              <input
                className="form-control"
                value={props.end}
                type="date"
                onChange={e => props.endChanged(e.target.value)}
              />
              <button
                onClick={props.searchEarnigs}
                type="button"
                className="btn btn-warning btn-md text-bold fa-2x"
                style={{ marginLeft: "15px" }}
              >
                {" "}
                Search{" "}
              </button>
            </div>
          </div>
          <br />
          <br />
          <br />
          {props.response !== null ? (
            <Row>
              <Col>
                <Card style={{ backgroundColor: "#ff902b" }}>
                  <Card.Body>
                    <Card.Title
                      style={{
                        fontSize: 30,
                        fontWeight: "bolder",
                        color: "white"
                      }}
                    >
                      {props.response.bookings.toLocaleString()}
                    </Card.Title>
                    <Card.Text style={{ fontSize: 20, color: "white" }}>
                      Bookings
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card style={{ backgroundColor: "#ff902b" }}>
                  <Card.Body>
                    <Card.Title
                      style={{
                        fontSize: 30,
                        fontWeight: "bolder",
                        color: "white"
                      }}
                    >
                      {props.response.earned.toLocaleString(undefined, {
                        minimumFractionDigits: 2
                      })}{" "}
                      LKR
                    </Card.Title>
                    <Card.Text style={{ fontSize: 20, color: "white" }}>
                      Earned
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card style={{ backgroundColor: "#ff902b" }}>
                  <Card.Body>
                    <Card.Title
                      style={{
                        fontSize: 30,
                        fontWeight: "bolder",
                        color: "white"
                      }}
                    >
                      {props.response.consultantShare.toLocaleString(
                        undefined,
                        { minimumFractionDigits: 2 }
                      )}{" "}
                      LKR
                    </Card.Title>
                    <Card.Text style={{ fontSize: 20, color: "white" }}>
                      My Share
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card style={{ backgroundColor: "#ff902b" }}>
                  <Card.Body>
                    <Card.Title
                      style={{
                        fontSize: 30,
                        fontWeight: "bolder",
                        color: "white"
                      }}
                    >
                      {props.response.cerebrozoneShare.toLocaleString(
                        undefined,
                        { minimumFractionDigits: 2 }
                      )}{" "}
                      LKR
                    </Card.Title>
                    <Card.Text style={{ fontSize: 20, color: "white" }}>
                      Cerebrozone Share
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ) : null}
          {props.response !== null ? (
            <>
              <br />
              <br />
              <br />
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Booking ID(s)</th>
                    <th>Consultee Name</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>My Share</th>
                    <th>Cerebrozone Share</th>
                  </tr>
                </thead>
                <tbody>
                  {props.response.earnings.map(earning => {
                    return (
                      <tr key={earning.date}>
                        <td>
                          <ul>
                            {earning.bookingIds.map(id => {
                              return (
                                <li key={id}>
                                  <p>{id}</p>
                                </li>
                              );
                            })}
                          </ul>
                        </td>
                        <td className="text-left">{earning.consulteeName}</td>
                        <td className="text-right">
                          {formatteredDate(earning.date)}
                        </td>
                        <td className="text-right">
                          {earning.earnedAmount.toLocaleString(undefined, {
                            minimumFractionDigits: 2
                          })}{" "}
                          LKR
                        </td>
                        <td className="text-right">
                          {earning.consultantAmount.toLocaleString(undefined, {
                            minimumFractionDigits: 2
                          })}{" "}
                          LKR
                        </td>
                        <td className="text-right">
                          {earning.cerebrozoneAmount.toLocaleString(undefined, {
                            minimumFractionDigits: 2
                          })}{" "}
                          LKR
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          ) : null}
        </div>
      </section>
    </>
  );
}