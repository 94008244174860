import React from 'react'

function PrivacyPolicy() {
    return (
        <>
            {/* top navbar*/}
            {/* Main section*/}
            <section className="section-container ml-0">
                {/* Page content*/}
                <div className="content-wrapper">
                    <div className="row">
                        <div className="col-12">
                            <div className=" card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-1 offset-md-1 text-left align-self-left"></div>
                                        <div className="col-md-12 align-self-left">
                                            <h1 className="text-center mb-3" >Privacy Policy </h1>
                                            <hr className="border-warning my-5" />
                                            <p className="text-left mb-3 lead  float-left">
                                                1. Introduction<br />
                                                Cerebrozone.com is committed to protecting the privacy of its users. This Privacy Policy outlines how we collect, use, and safeguard the personal information provided by users of our website.<br /><br />
                                                2. Information We Collect<br />
                                                - <strong>Personal Information</strong>: When users register for an account, book a consultation, or interact with the Site, we may collect personal information such as name, email address, contact information, and payment details.<br />
                                                - <strong>Communications</strong>: We may record and store communications between users and consultants for quality assurance and record-keeping purposes.<br />
                                                - <strong>Usage Information</strong>: We may collect information about how users interact with the Site, such as IP addresses, device information, and browsing activity.<br /><br />
                                                3. Use of Information<br />
                                                - <strong>Consultation Services</strong>: Personal information is used to facilitate the booking of consultations, processing of payments, and the provision of consultation services using the Site's platform.<br />
                                                - <strong>Improvement of Services</strong>: We may use collected information to improve our services, customize user experience, and analyze trends and usage patterns.<br />
                                                - <strong>Communications</strong>: We may use contact information to communicate with users about their accounts, bookings, and updates to our services.<br /><br />
                                                4. Protection of Information<br />
                                                - We employ industry-standard security measures to protect the personal information submitted to us, both during transmission and once we receive it.<br />
                                                - We do not sell, trade, or otherwise transfer personal information to third parties except as outlined in this Privacy Policy or with explicit user consent.<br /><br />
                                                5. Cookies and Tracking Technologies<br />
                                                - The Site may use cookies and similar tracking technologies to enhance user experience and track usage patterns. Users may choose to disable cookies through their web browser settings.<br /><br />
                                                6. Third-Party Services<br />
                                                - The Site may utilize third-party services for payment processing, analytics, and communication. These third parties are subject to their own privacy policies and terms of use.<br /><br />
                                                7. Legal Compliance<br />
                                                - We may disclose personal information as required by law, or in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.<br /><br />
                                                8. Children's Online Privacy Protection<br />
                                                - The Site is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children. If we become aware that personal information has been collected from a child without parental consent, we will take steps to remove that information.<br /><br />
                                                9. Changes to this Privacy Policy<br />
                                                - This Privacy Policy may be updated from time to time to reflect changes in our practices and legal requirements. We will notify users of any material changes through the Site or other means.<br /><br />
                                                10. Contact Information<br />
                                                - For any questions or concerns regarding this Privacy Policy, please contact us through the provided contact channels on the Site.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Page footer*/}
        </>
    )
}

export default PrivacyPolicy