import React, { Component } from "react";
import { JitsiMeeting } from "@jitsi/react-sdk";
import Countdown from "react-countdown";
import { CONSULTANT } from "../Constants";
import BookingDataService from "../api/BookingDataService";
import UserDataService from "../api/UserDataService";

const renderer = ({ hours, minutes, seconds, completed }) => {
  return (
    <p className="font-weight-bold mb-0">
      <span className="text-warning font-weight-bold text-md">
        <label
          className="text-center"
          style={{
            color: "white",
            width: "100%",
            fontWeight: "bolder",
            fontSize: 30,
          }}
        >
          {`${hours}`.padStart(2, "0")}:{`${minutes}`.padStart(2, "0")}:
          {`${seconds}`.padStart(2, "0")}
        </label>
      </span>
    </p>
  );
};

class Video extends Component {
  constructor(props) {
    super();
    this.state = {
      videoId: props.match.params.id,
      countDownTime: null,
      bookingId: null,
      consultantId: null,
      consulteeId: null,
      role: null,
    };
  }

  componentDidMount() {
    this.fetchBooking();
  }

  fetchBooking = () => {
    if (this.state.videoId != null || this.state.videoId != undefined) {
      BookingDataService.getBookingById(this.state.videoId).then((res) => {
        if (res.status === 200 && (res.data != null || res.data != undefined)) {
          this.setState({
            countDownTime: res.data.endCountDownTime,
            bookingId: res.data.id,
            consultantId: res.data.consultantId,
            consulteeId: res.data.consulteeId,
            role: res.data.role,
          });
        }
      });
    }
  };

  createRate = () => {
    if (
      this.state.bookingId != null &&
      this.state.bookingId != null &&
      this.state.consultantId != null &&
      this.state.consulteeId != null
    ) {
      BookingDataService.createRate(this.state).then((res) => {});
    }
  };

  handleAPI = (JitsiMeetAPI) => {
    JitsiMeetAPI.executeCommand("toggleVideo");
    JitsiMeetAPI.on("readyToClose", () => {
      if (UserDataService.getLoggedUser().roles[0] === CONSULTANT) {
        this.props.history.push("/consultantdashboard");
      } else {
        this.props.history.push("/consulteedashboard");
      }
    });
    JitsiMeetAPI.on("videoConferenceJoined", (data) => {
      this.createRate();
    });
  };

  render() {
    return (
      <>
        <div
          style={{
            height: "50px",
            backgroundColor: "red",
            width: "100%",
            verticalAlign: "center",
          }}
        >
          {this.state.countDownTime != null ? (
            <Countdown
              date={new Date(this.state.countDownTime)}
              renderer={(props) => renderer(props)}
            />
          ) : null}
        </div>
        <JitsiMeeting
          // displayName={UserDataService.getLoggedUser().roles[0]}
          displayName={""}
          domain={`video-bridge.cerebrozone.com`}
          roomName={`${this.state.videoId}`}
          configOverwrite={{
            startWithAudioMuted: true,
            disableModeratorIndicator: true,
            startScreenSharing: true,
            enableEmailInStats: false,
          }}
          interfaceConfigOverwrite={{
            DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
            INVITE_ENABLED : false,
          }}
          onApiReady={(externalApi) => {
            // here you can attach custom event listeners to the Jitsi Meet External API
            // you can also store it locally to execute commands
          }}
          getIFrameRef={(iframeRef) => {
            iframeRef.style.height = "600px";
            iframeRef.style.width = "100%";
          }}
          config={{ disableInviteFunctions: true }}
          onAPILoad={this.handleAPI}
          // interfaceConfig={{
          //   HIDE_INVITE_MORE_HEADER: true,
          //   TOOLBAR_BUTTONS: [
          //     "microphone",
          //     "camera",
          //     "hangup",
          //     "chat",
          //     "fullscreen",
          //     "tileview",
          //     "videoquality",
          //     "settings",
          //     "stats",
          //     "desktop",
          //   ],
          // }}
        />
      </>
      // <iframe title="video" allow="camera; microphone; fullscreen; display-capture"
      //     src={`https://video-bridge.cerebrozone.com:8443/${this.state.videoId}`}
      //     style={{ height: "850px", width: "100%", border: "0px" }} > </iframe>
    );
  }
}

export default Video;
