import React, { Component } from "react";
import UserDataService from "../api/UserDataService";
import { HIDE_LOADING, SHOW_LOADING, SIGNUP_SUCCESS } from "../redux/actionTypes";
import { connect } from 'react-redux'
import { withAlert } from 'react-alert';

class SignupConsultee extends Component {

  state = {
    user: {
      name: '',
      email: '',
      password1: '',
      password2: '',
      type: ['CONSULTEE']
    }
  };

  componentDidMount() {
    localStorage.clear();
  }

  signUpConsultee() {
    const user = this.state.user;
    if (user.password1 === user.password2) {
      if (user.email !== '' && user.name !== '') {
        this.props.dispatch({ type: SHOW_LOADING })
        UserDataService.createUser({
          userName: user.name,
          password: user.password1,
          roles: user.type,
          email: user.email
        })
          .then(res => {
            this.props.dispatch({ type: HIDE_LOADING })
            if ((res) && (res.status === 200) && res.data !== "") {
              UserDataService.registerSuccessfulLogin(res.data)
              this.props.dispatch({ type: SIGNUP_SUCCESS, payload: res.data })
            }
          })
          .catch(e => {
            this.props.dispatch({ type: HIDE_LOADING })
            if ((e.response) && (e.response.status === 400 || e.response.status === 403) && e.response.data !== "") {
              this.props.alert.error(e.response.data.message)
            }
          })
      }

    } else {

    }
  }

  fieldOnChange(key, value) {
    this.setState({
      user: {
        ...this.state.user,
        [key]: value
      }
    })
  }


  render() {
    return (
      <div>
        <section className="section-container ml-0">
          {/* Page content*/}
          <div className="content-wrapper">
            <div className="content-heading">
              <div>
                Sign Up
                <small data-localize="dashboard.WELCOME" />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className=" card-default">
                  <div className="card-body">
                    <div className="row">
                      <div
                        className="col-md-8 text-center border-right"
                        style={{ height: "70vh" }}
                      >
                        <div className="parallax">
                          <div className="water consultee-banner2" />
                          <div className="water consultee-banner1" />
                          <div className="water consultee-banner3" />
                        </div>
                        <div className="clearfix" />
                      </div>
                      <div className="col-md-4 align-self-center">
                        <h3 className="text-center mb-3">
                          Sign Up as a Consultee
                        </h3>
                        <p className="text-center mb-3">
                          Enter Your Details to Sign Up
                        </p>
                        <form className="mb-3" id="loginForm" noValidate>
                          <div className="form-group">
                            <label className="ml-3">Name</label>
                            <div className="input-group with-focus">
                              <input
                                className="form-control form-control-rounded border-right-0"
                                id="name"
                                value={this.state.user.name}
                                placeholder="Enter name"
                                autoComplete="off"
                                required
                                onChange={(e) => this.fieldOnChange('name', e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="ml-3">Email</label>
                            <div className="input-group with-focus">
                              <input
                                className="form-control form-control-rounded border-right-0"
                                id="email"
                                type="email"
                                value={this.state.user.email}
                                placeholder="Enter email"
                                autoComplete="off"
                                required
                                onChange={(e) => this.fieldOnChange('email', e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="ml-3">Password</label>
                            <div className="input-group with-focus">
                              <input
                                className="form-control form-control-rounded border-right-0"
                                id="password"
                                type="password"
                                value={this.state.user.password1}
                                placeholder="Password"
                                required
                                onChange={(e) => this.fieldOnChange('password1', e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="ml-3">Re-enter Password</label>
                            <div className="input-group with-focus">
                              <input
                                className="form-control form-control-rounded border-right-0"
                                id="reenter-password"
                                type="password"
                                placeholder="Password"
                                required
                                value={this.state.user.password2}
                                onChange={(e) => this.fieldOnChange('password2', e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="clearfix" />
                          <div className="text-center w-100">
                            <button
                              onClick={() => this.signUpConsultee()}
                              className="btn  btn-primary mt-3 btn-oval"
                              type="button"
                            >
                              Sign Up
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default connect()(withAlert()(SignupConsultee));
