import React, { Component } from 'react'
import ConsulteeDataService from '../api/ConsulteeDataService';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withAlert } from "react-alert";

import {
    HIDE_LOADING,
    SET_CONSULTEE,
    SHOW_LOADING
  } from "../redux/actionTypes";

class SlipUpload extends Component {
    state = {
        file: null,
        banks: [

            { id: 0, name: ' - Select Bank - ' },
            { id: 1, name: 'Cerebrozone (PVT) Ltd 117814016889 - Sampath Bank - Thalawathugoda' },
            // Add more banks as needed...
        ],
        selectedBankId: null, // 
        refNumber: '',
        remarks: '',
        errorMessage: null,
        invoiceId: null,
        bookingDetails: {} // Assuming this is an object, adjust as needed
    }

    componentDidMount() {
        //log bookingdetils
        if(this.props.location.bookingDetails){
            this.setState({ bookingDetails: this.props.location.bookingDetails });
        }else if(this.props.location.state.invoiceId){
            this.setState({ invoiceId: this.props.location.invoiceId });
        }else{
            this.props.history.push({
                pathname: '/consulteedashboard'
              })
        }
        // if (this.props.location.paymentRequest) {
        //     console.log(this.props.location.paymentRequest)
        //     // window['loadPaycorpPayment'](this.props.location.paymentRequest, 'paycorp-payment')
        // }
        // console.log("Testing")?
    }

    handleBankChange = (event) => {
        this.setState({ selectedBankId: event.target.value });
    }

    handleFileChange = (event) => {
        this.setState({ file: event.target.files[0] });
    }

    handleRemarksChange = (event) => {
        this.setState({ remarks: event.target.value });
    }

    onFileChange = event => {
        this.setState({
            ...this.state,
            file: event.target.files[0]
        });
    }

    submitData = () => {

        if (!this.state.bookingDetails) {
            this.setState({ errorMessage: 'Booking details are required' });
            return;
        }

        if (this.state.selectedBankId === 0) {
            this.setState({ errorMessage: 'Please select a bank' });
            return;
        }
        //getting the consultee details from local storage
        let user = JSON.parse(localStorage.getItem('user'));
        let consulteeId = user.consultee.id;
        let { file, selectedBankId, refNumber, remarks, bookingDetails} = this.state;
        let bookingIds = bookingDetails && bookingDetails.bookingId ? bookingDetails.bookingId.join(", ") : "No Booking IDs";
        let invoiceId = bookingDetails && bookingDetails.invoiceId ? bookingDetails.invoiceId : "No Invoice ID";
        
        if (!file) {
            this.setState({ errorMessage: 'Slip is required' });
            return;
        }
    
        let formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("bookingId", bookingIds);
        formData.append("invoiceId", invoiceId);
        formData.append("bankId", selectedBankId);
        formData.append("refNo", refNumber); 
        formData.append("remarks", remarks);
        formData.append("consulteeId", consulteeId); // Replace with actual consulteeId
        //paymentDate
        formData.append("paymentDate", new Date().toISOString());
        console.log(formData);
    
        // Call the API to upload the payment slip
        this.props.dispatch({ type: SHOW_LOADING });
        ConsulteeDataService.uploadPaymentData(formData)
            .then((res) => {
                this.props.dispatch({ type: HIDE_LOADING });
                this.props.alert.success("Bank Slip Upladed Successfully.");
                this.props.history.push({
                    pathname: '/consulteedashboard'
                  })
            })
            .catch((err) => {
                this.props.alert.error("Bank Slip Upladed Failed.");
                this.props.dispatch({ type: HIDE_LOADING });
                console.log(err)
            })
    }

    render() {
        const { bookingDetails, selectedBankId, file,refNumber, remarks } = this.state;
        const bookingIdList = bookingDetails && bookingDetails.bookingId ? bookingDetails.bookingId.join(", ") : "No Booking IDs";
        const invoiceId = bookingDetails && bookingDetails.invoiceId ? bookingDetails.invoiceId : "No Invoice ID";
        console.log("invoice id >>",invoiceId);
        return (

            <div>
                <section className="section-container ml-0">
                    {/* Page content*/}
                    <div className="content-wrapper">
                        <div className="content-heading">
                            <div>
                                Payment Slip Upload
                                <small data-localize="dashboard.WELCOME" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className=" card" style={{ overflow: "unset" }}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-10 offset-md-1 align-self-center">
                                                <form className="mb-3" id="loginForm" noValidate>
                                                    
                                                
                                                    <div className="row">
                                                        <div className="row">
                                                        <div className="col-md-12 col-lg-12">
                                                                <div className="form-group">
                                                                    <label className="ml-3">Invoice ID</label>
                                                                    <div className="input-group with-focus">
                                                                        <input
                                                                            className="form-control form-control-rounded"
                                                                            placeholder={"Invoice Id"}
                                                                            value={invoiceId}
                                                                            required
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 col-lg-12">
                                                                <div className="form-group">
                                                                    <label className="ml-3">Booking ID / IDs</label>
                                                                    <div className="input-group with-focus">
                                                                        <input
                                                                            className="form-control form-control-rounded"
                                                                            placeholder={"Booking Id List"}
                                                                            value={bookingIdList}
                                                                            required
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 col-lg-12">
                                                                <div className="form-group">
                                                                    <label className="ml-3">Payment Slip</label>
                                                                    <div className="input-group with-focus">
                                                                        <input
                                                                            type="file"
                                                                            accept="image/png, image/jpeg"
                                                                            onChange={this.onFileChange}
                                                                            className="form-control form-control-rounded file-select-button"
                                                                        />
                                                                    </div>

                                                                    { <p style={{ color: "#FF0000" }}>
                                                                    {this.state.errorMessage && <div>{this.state.errorMessage}</div>}
                                                                </p> }
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 col-lg-12">
                                                                <div className="form-group">
                                                                    <label className="ml-3">Bank</label>
                                                                    <div className="input-group with-focus">
                                                                    <select className="form-control form-control-rounded" value={this.state.selectedBankId} onChange={(e) => this.setState({ selectedBankId: e.target.value })}>
                                                                        {this.state.banks.map((bank) => (
                                                                            <option key={bank.id} value={bank.id}>{bank.name}</option>
                                                                        ))}
                                                                    </select>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 col-lg-12">
                                                                <div className="form-group">
                                                                    <label className="ml-3">Ref Number</label>
                                                                    <div className="input-group with-focus">
                                                                        <input
                                                                            className="form-control form-control-rounded"
                                                                            placeholder={"Ref No"}
                                                                            value={this.state.refNumber}
                                                                            onChange={e =>
                                                                                this.setState({
                                                                                    ...this.state,
                                                                                    refNumber: e.target.value
                                                                                })
                                                                            }
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 col-lg-12">
                                                                <div className="form-group">
                                                                    <label className="ml-3">Remarks</label>
                                                                    <div className="input-group with-focus">
                                                                        <input
                                                                            className="form-control form-control-rounded"
                                                                            placeholder={"Remarks"}
                                                                            value={this.state.remarks}
                                                                            onChange={e =>
                                                                                this.setState({
                                                                                    ...this.state,
                                                                                    remarks: e.target.value
                                                                                })
                                                                            }
                                                                            required
                                                                        />
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className=" col-md-12"></div>
                                                        <div className=" col-md-12">
                                                            <button
                                                                className="btn float-right btn-success mr-2 btn-oval"
                                                                type="button"
                                                                onClick={() => this.submitData()}>
                                                                Upload Payment Slip
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

// const mapStateToProps = state => {
//     return {
//       consulteeId: state.auth.consulteeId,
//       user: state.auth.user,
//     };
//   };

export default connect()(withRouter(withAlert()(SlipUpload)));
