import React from "react";

export default function ConsulteeList(props) {
  let rowNumber = 1; // Initialize the row number

  return (
    <div>
      <section className="section-container ml-0">
        {/* Page content*/}
        <div className="content-wrapper">
          <div className="row">
            <div className="col-12">
              <div className=" card-default">
                <div className="row">
                  <div className="col-12">
                    <div className="list-group mb-3">
                      <div className="list-group-item">
                        <table className="wd-wide">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Full Name</th>
                              <th>Age</th>
                              <th>Gender</th>
                              <th>Country</th>
                              <th>Phone</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.consulteeList.map((item) => {
                              return (
                                <tr style={{ height: "50px" }} key={item.id}>
                                  <td>
                                    <div className="px-2">
                                      <p className="m-0">{rowNumber++}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="px-2">
                                      <p className="m-0">
                                        {item.firstName} {item.lastName}
                                      </p>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="px-2">
                                      <p className="m-0">{item.age}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="px-2">
                                      <p className="m-0">{item.gender}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="px-2">
                                      <p className="m-0">{item.country}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="px-2">
                                      <p className="m-0">{item.phone}</p>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
