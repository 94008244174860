import axios from '../../src/Axios';
import { API_URL } from "../Constants.js";

export const USER = "user";

class UserDataService {
  retrieveAllUser() {
    return axios.get(`${API_URL}`);
  }

  retrieveUser(id) {
    return axios.get(`${API_URL}/${id}`);
  }

  deleteUser(id) {
    return axios.delete(`${API_URL}/${id}`);
  }

  updateUser(id, user) {
    return axios.put(`${API_URL}/${id}`, user);
  }

  createUser(user) {
    return axios.post(`${API_URL}/auth/signup`, user)
  }

  registerSuccessfulLogin(user) {
    localStorage.setItem(USER, JSON.stringify(user));
  }

  getLoggedInUserId() {
    let user = localStorage.getItem(USER);
    if (user === null) return "";
    return JSON.parse(user).id;
  }

  getLoggedUser() {
    let user = localStorage.getItem(USER);
    if (user === null) return "{}";
    return JSON.parse(user);
  }

  login(credentials) {
    return axios.post(`${API_URL}/auth/signin`, credentials)
  }

  removeUser() {
    localStorage.removeItem(USER)
  }

  resetPassword(email) {
    return axios.get(`${API_URL}/auth/resetPassword`, {
      params: {
        email: email
      }
    })
  }

  updatePassword(obj) {
    return axios.post(`${API_URL}/auth/updatePassword`, obj)
  }
}

export default new UserDataService();
